import type { ChatTypesENUM } from 'src/types';
import socket from './socket';
import { SocketEventsENUM } from './socketEvents';
import { CrossWorspaceActionTypeENUM } from './crossWorkspaceActions';
import store from 'src/store/store';

export interface ICrossWorskpaceDesktopNotification {
  type: string;
  title: string;
  body: string;
  companyName: string;
  channelId: string;
  messageId: string;
  parentMessageId: string;
  messageAuthor: string;
  messageAuthorId: string;
  channelType: ChatTypesENUM;
  rootUrl: string;
  companyUrl: string;
  companyId: number;
}

export interface ICrossWorskpaceNewMessageMetaData {
  companyId: number;
  messageType?: string; // for the future behavior like mention / direct
  messageId: number;
  parentMessageId?: number;
}
export interface ICrossWorskpaceMessageDeletedMetaData {
  companyId: number;
  messageId: number;
  parentMessageId?: number;
}

interface ICommonCrossWorskpaceMetaData {
  actionType: CrossWorspaceActionTypeENUM;
  companyId: number;
}

type ICrossWorskpacePayload<T, D = ICommonCrossWorskpaceMetaData, O = T & D> = {
  [K in keyof O]: O[K];
};

export class CrossWorkspaceWs {
  // eslint-disable-next-line no-useless-constructor
  constructor(private getWorspaces: () => number[]) {}
  private isInSelectedWorkspaces(companyId: number) {
    const workspaces = this.getWorspaces();
    return workspaces.includes(companyId);
  }

  private allowToEmmit(actionTypes: [CrossWorspaceActionTypeENUM, CrossWorspaceActionTypeENUM], companyId: number) {
    return actionTypes[0] === actionTypes[1] && this.isInSelectedWorkspaces(companyId);
  }

  private subscriber<P>(cb: (data: P) => void, action: CrossWorspaceActionTypeENUM) {
    return socket.addEventHandler<ICrossWorskpacePayload<P>>(
      SocketEventsENUM.action_crossWorkspaceNotifier, (response) => {
        const { actionType, ...rest } = response.payload;
        if (!this.allowToEmmit([actionType, action], rest.companyId)) {
          return;
        }
        cb(rest as P);
      },
    );
  }

  public subscribeOnDesktopNotification<T = ICrossWorskpaceDesktopNotification>(cb: (data: T) => void) {
    return this.subscriber<T>(cb, CrossWorspaceActionTypeENUM.desktopNotification);
  }

  public subscribeOnNewMessage<T = ICrossWorskpaceNewMessageMetaData>(cb: (data: T) => void) {
    return this.subscriber<T>(cb, CrossWorspaceActionTypeENUM.newMessage);
  }

  public subscribeOnDeleteMessage<T = ICrossWorskpaceMessageDeletedMetaData>(cb: (data:T) => void) {
    return this.subscriber<T>(cb, CrossWorspaceActionTypeENUM.deleteMessage);
  }
}

export const crossWorkspaceWs = new CrossWorkspaceWs(() => store.getState().main.selectedWorkspaces);
