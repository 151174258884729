import React from 'react';

import StyledResizableContainer, { SelectDisabler } from './ResizableContainer.styles';

import type { VariantType, SizeDataPropsType } from './ResizableContainer.hooks';
import { useTogglersVisibility, useSizeData } from './ResizableContainer.hooks';

const ResizableContainer: React.FC<React.PropsWithChildren & SizeDataPropsType & {
  className?: string;
  /** Default value - `"right"` */
  variant?: VariantType;
  /** Default value - `10` */
  togglerSize?: number;
}> = (props) => {
  const { isLeftToglerVisible, isRightToglerVisible } = useTogglersVisibility(props);

  const {
    size,
    handleDownEvent,
    isDragging,
    containerRef,
  } = useSizeData(props);

  return (
    <StyledResizableContainer
      className={props.className}
      togglerSize={props.togglerSize || 10}
      minSize={props.minSize}
      maxSize={props.maxSize}
      size={size}
      disabled={props.disabled ?? false}
      ref={containerRef}
    >
      {isDragging && <SelectDisabler />}

      {isLeftToglerVisible && (
        <div
          className="resize-toggler resize-toggler--left"
          onMouseDown={(ev) => handleDownEvent(ev, 'left')}
          onTouchStart={(ev) => handleDownEvent(ev, 'left')}
        />
      )}

      {props.children}

      {isRightToglerVisible && (
        <div
          className="resize-toggler resize-toggler--right"
          onMouseDown={(ev) => handleDownEvent(ev, 'right')}
          onTouchStart={(ev) => handleDownEvent(ev, 'right')}
        />
      )}
    </StyledResizableContainer>
  );
};

export default ResizableContainer;
