import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';

import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import RightBarContainer from './RightBarContainer';
import StatusBadge from 'src/legacy/ui/components/StatusBadge/StatusBadge';
import Avatar from 'src/ui/components/Avatar';
import UserAdditionalInfo from 'src/ui/pages/Chat/components/UserProfile/UserAdditionalInfo';
import { ReactComponent as UserIcon } from 'src/ui/pages/Chat/assets/icons/workers.svg';

import useTheme from 'src/utils/hooks/useTheme';
import useElementHeight from 'src/utils/hooks/useElementHeight';
import UserLinkButton from 'src/ui/components/UserLinkButton/UserLinkButton';
import { useUser, useUserOnlineStatus } from 'src/utils/hooks/general';
import { ROUTES } from 'src/utils/constants';
import { t, language } from 'src/utils/getTranslation';
import { useOpenDm } from 'src/ui/pages/Chat/Chat.hooks';
import { useTabletCheck } from 'src/utils/hooks/useMediaQueryCheck';
import { useGetUsersFromCollection } from 'src/utils/hooks/usersCollection/useGetUsersFromCollection';

type UserProfilePropsType = {
  userId: number;
  onClose?: () => void;
};

const UserProfile: React.FC<UserProfilePropsType> = (props) => {
  const theme = useTheme();

  const isTablet = useTabletCheck();
  const { getById, status } = useGetUsersFromCollection();
  const isLoadingUser = status === 'loading';
  const userProfile = getById(props.userId);

  const profileRef = React.useRef<HTMLDivElement | null>(null);
  const profileHeight = useElementHeight(profileRef);
  const showDatesInfo = Boolean(
    userProfile?.dateOfBirth || userProfile?.workingFrom || isLoadingUser,
  );
  const showDateOfBirth = userProfile?.dateOfBirth || isLoadingUser;
  const showWorkingFrom = userProfile?.workingFrom || isLoadingUser;

  const isOnline = useUserOnlineStatus(props.userId);

  const fullNameWithPatronymic = React.useMemo(() => {
    if (!userProfile) {
      return '';
    }

    return [
      userProfile.lastName,
      userProfile.firstName,
      userProfile.patronymic || '',
    ].join(' ').trim();
  }, [userProfile]);

  const formatDate = (date: string | number | Date | dayjs.Dayjs | undefined) => {
    return date ? dayjs(date).locale(language).format('DD MMMM YYYY') : ' ';
  };

  const dm = useOpenDm(props.onClose);

  const currentUser = useUser();
  const isMe = currentUser.userId === props.userId;

  return (
    <RightBarContainer onClose={props.onClose}>
      {dm.loaderNode}

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        paddingBottom="24px"
        ref={profileRef}
        overflow="auto"
      >
        <Box padding="0px 5px">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="20px"
            padding="20px 0px 16px 0px"
          >
            <UserLinkButton isAbsolute userId={props.userId} />
            <StatusBadge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              isOnline={isOnline}
              customColor={{
                success: theme.palette.badge.success,
                secondary: theme.palette.badge.secondary,
              }}
              size="sm"
              withStroke
            >
              <Avatar
                user={userProfile!}
                size="xlg"
                sourceSize="full"
              />
            </StatusBadge>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="6px"
            >
              <Typography variant="h2" className="user-profile__fullname">
                {fullNameWithPatronymic}
              </Typography>
              <Typography variant="h5" className="user-profile__job-position">
                {userProfile?.jobPosition?.name}
              </Typography>
            </Box>
          </Box>

          <Divider />

          <Box display="flex" flexDirection="column" gap="24px" padding="20px">
            <UserAdditionalInfo
              isLoaded={Boolean(userProfile)}
              title={t('chat:userProfile.email')}
              info={userProfile?.email}
              isEmail
            />

            <UserAdditionalInfo
              isLoaded={Boolean(userProfile)}
              title={t('chat:userProfile.phone')}
              info={userProfile?.phone}
              isPhone
            />

            {showDatesInfo && (
              <Box display="flex" justifyContent="space-between">
                {showDateOfBirth && (
                  <UserAdditionalInfo
                    isLoaded={!isLoadingUser}
                    title={t('chat:userProfile.birthday')}
                    info={formatDate(userProfile?.dateOfBirth)}
                  />
                )}
                {showWorkingFrom && (
                  <UserAdditionalInfo
                    isLoaded={!isLoadingUser}
                    title={t('chat:userProfile.workingForm')}
                    info={formatDate(userProfile?.workingFrom)}
                  />
                )}
              </Box>
            )}

            {Boolean(userProfile?.description || isLoadingUser) && (
              <UserAdditionalInfo
                isLoaded={!isLoadingUser}
                title={t('chat:userProfile.aboutMe')}
                info={userProfile?.description}
                profileHeight={profileHeight}
              />
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" padding="0px 20px" gap="10px">
          {!isMe && (
            <Button variant="contained" onClick={() => dm.openDm(props.userId, { clearLoaderOnEnd: true })}>
              {t('chat:userProfile.write')}
            </Button>
          )}

          {!isTablet && (
            <Link to={ROUTES.profile.createPath(props.userId)}>
              <Button variant="outlined" fullWidth>
                <UserIcon className="user-profile__user-icon" />
                {t('chat:userProfile.goToProfile')}
              </Button>
            </Link>
          )}
        </Box>
      </Box>
    </RightBarContainer >
  );
};

export default UserProfile;
