import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import WorkspaceItem from './WorkspaceItem';
import UserMenuAreaSwitcherStyle from './UserMenuAreaSwitcher.styles';

import addWorkspace from '../assets/addWorkspace.svg';
import { ROUTES } from 'src/utils/constants';
import { useWorkspacesList } from 'src/utils/hooks/general';
import { useCrossWorkspacesUnreadsCount } from 'src/utils/hooks/useCrossWorkspacesUnreadsCount';

const UserMenuAreaSwitcher: React.FC<{
  setAnchor: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
}> = (props) => {
  const location = useLocation();
  const { availableWorkspaces, checkedWorkspacesIds } = useWorkspacesList();
  const { unreadsByCompanyId } = useCrossWorkspacesUnreadsCount();

  return (
    <UserMenuAreaSwitcherStyle>
      <div className="workspace-container">
        {checkedWorkspacesIds?.map((workspaceItem) => (
          <Box
            key={workspaceItem}
            marginBottom="12px"
          >
            <WorkspaceItem
              setAnchor={props.setAnchor}
              workspaceItem={availableWorkspaces[workspaceItem]}
              unreadsCount={unreadsByCompanyId.get(String(workspaceItem))}
            />
          </Box>
        ))}
      </div>
      <Link
        onClick={() => props.setAnchor(null)}
        to={ROUTES.choiceWorkspace.createPath('select')}
        state={location.pathname}
        className="add-workspace"
      >
        <img className="add-workspace-icon" src={addWorkspace} alt="" />
      </Link>
    </UserMenuAreaSwitcherStyle >
  );
};

export default UserMenuAreaSwitcher;
