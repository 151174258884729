import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { UserUpdateNotifier } from 'src/api/ws/userUpdates';
import { usersCollectionActions } from 'src/store/usersCollection/slice';

export const useGetUsersCollection = () => {
  const dispatch = useAppDispatch();
  const isSocketConnected = useAppSelector((state) => state.main.socketData.isConnected);

  React.useEffect(() => {
    if (!isSocketConnected) {
      return;
    }
    const subscribers = [
      UserUpdateNotifier.onEntityUpdated((user) => {
        dispatch(usersCollectionActions.updateUserInCollection(user));
      }),

      UserUpdateNotifier.onNewAdded((user) => {
        dispatch(usersCollectionActions.addUserToCollection(user));
      }),

      UserUpdateNotifier.onRemoved((userId) => {
        dispatch(usersCollectionActions.updateUserInCollection({
          userId,
          deletedAt: new Date().toISOString(),
        }));
      }),

      UserUpdateNotifier.onRestored((userId) => {
        dispatch(usersCollectionActions.updateUserInCollection({
          userId,
          deletedAt: null,
        }));
      }),
    ];

    return () => {
      subscribers.forEach((unsub) => {
        unsub();
      });
    };
  }, [isSocketConnected]);
};
