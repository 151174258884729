import React from 'react';

import { useMatch, Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import type { RouteKeyType } from 'src/utils/constants';

const ModuleLink: React.FC<{
  to: string;
  routeToMatch: string;
  routeKey: RouteKeyType;
  icon: string;
  iconIsActive: string;
  toolTip: string;
}> = (props) => {
  const isActive = useMatch(props.routeToMatch);

  return (
    <Tooltip title={props.toolTip} placement="right" arrow>
      <Link
        to={props.to}
      >
        <div>
          <img
            src={isActive ? props.iconIsActive : props.icon}
            alt="icon"
          />
        </div>
      </Link>
    </Tooltip>
  );
};

export default ModuleLink;
