import type { AxiosResponse } from 'axios';
import { CanceledError } from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { t } from 'src/utils/getTranslation';
import useSignal from 'src/utils/hooks/useSignal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CbType = (...args: any[]) => Promise<AxiosResponse<unknown>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CbResponseType<Cb> = Cb extends (...args: any[]) => Promise<AxiosResponse<infer R>> ? R : unknown;

export const useSearch = <C extends CbType>(cb: C, params: {
  searchTerm: string;
  doSearch?: boolean;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [results, setResults] = React.useState<CbResponseType<C> | undefined>();

  const { handleRequestWithSignal } = useSignal();

  React.useEffect(() => {
    if (!params.doSearch || !params.searchTerm) {
      return;
    }
    (async () => {
      try {
        setIsLoading(true);
        const data = await handleRequestWithSignal((signal) => cb(params.searchTerm, { signal }));

        setResults(data.data as CbResponseType<C>);
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }
        toast.error(t('errors:searchData.failed'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [params.doSearch, params.searchTerm]);

  return [
    results,
    isLoading,
  ] as const;
};
