import styled from 'styled-components';

type StyledPropsType = {
  fullWidth?: boolean;
  fullHeight?: boolean;
};

const StyledCustomInput = styled.div<StyledPropsType>`
  display: flex;
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  height: ${({ fullHeight }) => (fullHeight && '100%')};

  .custom-input__label {
    left: 5px;
    top: -5px;
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.palette.input.text.light};
  }

  .custom-input__input {
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.xs};
  }

  .custom-input__label_lg {
    top: -7px;
    font-size: ${({ theme }) => theme.font.size.md};
  }

  .custom-input__input {
    flex: 1 1 auto;
    width: fit-content;
    padding: 10.5px 20px;
  }

  .custom-input__input_lg {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 40px;
    font-size: ${({ theme }) => theme.font.size.md};
  }

  .custom-input__label-top {
    left: 0px;
    top: -6px;
    padding: 0px;
    transform: translate(10px, -10px) scale(0.8);
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .custom-input__label-top_lg {
    transform: translate(10px, -12px) scale(0.7);
  }

  .custom-input__label-top--error {
    color: ${({ theme }) => theme.palette.error.main};
  }

  .custom-input__container {
    min-width: min(341px, 100%);
    display: flex;
    flex-wrap: wrap;
    border: none;
    /* overflow: auto; */
  }

  .custom-input__wrapper {
    border: none;
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
    background: ${({ theme }) => theme.palette.input.background};
    overflow: auto;
    max-height: 126px;
    &:hover {
      .custom-input__outline {
        border: 1.5px solid ${({ theme }) => theme.palette.input.border.focused};
      }
    }
  }

  .custom-input__outline {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    top: 0px;

    & > legend {
      display: none;
    }
  }

  .custom-input__outline--error {
    border: 1px solid ${({ theme }) => theme.palette.error.main} !important;
  }

  .custom-input__outline--select {
    border: none !important;
    top: 0px;

    & > legend {
      display: none;
    }
  }

  .custom-input__focused {
    border: none;

    .custom-input__outline {
      border: 1.5px solid ${({ theme }) => theme.palette.input.border.focused};
  }
    .custom-input__outline--error {
      border: 1.5px solid ${({ theme }) => theme.palette.error.main} !important;
    }
  }

  .custom-input__adornment, .custom-input__adornment {
    font-size: ${({ theme }) => theme.font.size.xs};
    display: flex;
  }

  .custom-input__helper-text {
    font-size: ${({ theme }) => theme.font.size.xs};
    margin: 0px;
    margin-top: 4px;
    font-weight: ${({ theme }) => theme.font.weight.md};
  }

  .custom-input__counter {
    color: ${({ theme }) => theme.palette.primary[400]};
  }

  .domain-adornment {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 11px;
    padding-right: 11px;
    font-weight: ${({ theme }) => theme.font.weight.sm};
    font-size: ${({ theme }) => theme.font.size.sm};
    border-left: 1px solid ${({ theme }) => theme.palette.divider};
    height: 40px;
    color: ${({ theme }) => theme.palette.grey[500]};
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  .custom-input__textarea {
    padding: 0px 20px 0px 4px;
  }
`;

export default StyledCustomInput;
