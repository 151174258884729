import type {
  IInventoryTag,
  IGetInventoryTagsListParams,
} from 'src/types';

import http from './http';

const path = '/inventory/tags';

const create = (data: IInventoryTag) => {
  return http.post(path, data);
};

const update = (data: IInventoryTag, inventoryTagId: number) => {
  return http.patch(`${path}/${inventoryTagId}`, data);
};

const getList = (params: IGetInventoryTagsListParams) => {
  return http.get(path, { params });
};

const deleteOne = (inventoryTagId: number) => {
  return http.delete(`${path}/${inventoryTagId}`);
};

export default {
  create,
  update,
  getList,
  deleteOne,
};
