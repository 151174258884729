import React from 'react';
import cn from 'classnames';
import Portal from '@mui/material/Portal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import StyledLoader from './Loader.styles';

const Loader: React.FC<{
  isFixed?: boolean;
  withBackdrop?: boolean;
  blockPoinerEvents?: boolean;
  titleText?: string;
  className?: string;
  loaderClassName?: string;
}> = (props) => {
  return (
    <Portal disablePortal={!props.isFixed}>
      <StyledLoader
        isFixed={props.isFixed || false}
        blockPoinerEvents={props.blockPoinerEvents || false}
        className={props.className}
      >
        {props.withBackdrop && <Backdrop open />}
        {props.titleText && (
          <p className="loader__title">
            {props.titleText}
          </p>
        )}
        <CircularProgress className={cn('loader__icon', props.loaderClassName)} />
      </StyledLoader>
    </Portal>
  );
};

export default Loader;
