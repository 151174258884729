import http, { type ResponseType } from 'src/api/http';
import type { IMessage } from 'src/types';
import type { SpecialMentionTypeENUM } from '../types/quillTypes';

const edit = (messageId: number, data: {
  messageText: string;
  addUserMentionsIds: (number | SpecialMentionTypeENUM)[];
  removeUserMentionsIds: (number | SpecialMentionTypeENUM)[];
  previewLinks?: string[];
  channelId?: number;
}) => {
  return http.patch<ResponseType<IMessage>>(`/messages/${messageId}`, data);
};

export default {
  edit,
};
