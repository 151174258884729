import React from 'react';

const useSignal = () => {
  const ref = React.useRef<AbortController>();

  const handleRequestWithSignal = React.useCallback(<R>(callback: (signal: AbortSignal) => Promise<R>) => {
    if (ref.current) {
      ref.current.abort();
    }
    ref.current = new AbortController();

    return callback(ref.current.signal);
  }, []);

  return {
    handleRequestWithSignal,
  };
};

export default useSignal;
