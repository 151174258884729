import { ElectronEventsENUM, type IElectronAPI } from './types';

export class ElectronEvents {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private electronAPI: IElectronAPI | undefined,
  ) { }

  private get isElectronApp() {
    return this.electronAPI !== undefined;
  }

  public onNotificationClick<D>(callback: (data: D) => void): (() => void) | undefined {
    return this.onMessageFromElectron(callback, ElectronEventsENUM.notificationClick);
  }

  public onAppResume<D>(callback: (data: D) => void): (() => void) | undefined {
    return this.onMessageFromElectron(callback, ElectronEventsENUM.appResume);
  }

  public onAppSuspend<D>(callback: (data: D) => void): (() => void) | undefined {
    return this.onMessageFromElectron(callback, ElectronEventsENUM.appSuspend);
  }

  public onAppUpdatesReady<D>(callback: (data: D) => void): (() => void) | undefined {
    return this.onMessageFromElectron(callback, ElectronEventsENUM.updatesReady);
  }

  private onMessageFromElectron<D>(callback: (data: D) => void, eventType: ElectronEventsENUM) {
    if (!this.isElectronApp) {
      return;
    }

    const innerCallback = (ev: MessageEvent) => {
      try {
        if (typeof ev.data !== 'string') {
          return;
        }

        const payload = JSON.parse(ev.data);
        if (payload.eventType !== eventType) {
          return;
        }
        callback(payload.data);
      } catch (err) {
        console.error('Parse event data error', err);
      }
    };

    window.addEventListener('message', innerCallback);

    return () => {
      window.removeEventListener('message', innerCallback);
    };
  }
}
