import React from 'react';
import styled from 'styled-components';
import TitleBar from './TitleBar';

type PropsType = {
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const WithTitleBar: React.FC<PropsType> = (props) => {
  if (props.disabled) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {props.children}
      </>
    );
  }

  return (
    <StyledWrapper className={props.className}>
      <TitleBar />
      <div className="main-content">
        {props.children}
      </div>
    </StyledWrapper>

  );
};

const StyledWrapper = styled.main`
  width: 100%;
  height: 100%;

  .main-content {
    width: 100%;
    display: flex;
    height: calc(100% - 40px);
  }
`;

export default WithTitleBar;
