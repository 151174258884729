import React from 'react';
import storage from '../storage';
import CustomEventCreator from '../CustomEventCreator';

export const soundControllerEvent = new CustomEventCreator<{ soundSettingStatus: 'on' | 'off' }>({ eventName: 'SOUND_SETTINGS_CHANGE' });

export const useSoundController = () => {
  const [
    soundSettingStatus,
    setSoundSettingStatus,
  ] = React.useState<'on' | 'off'>(storage.soundController.get() ?? 'on');

  React.useEffect(() => {
    storage.soundController.set(soundSettingStatus);
    soundControllerEvent.dispatch({ soundSettingStatus });
  }, [soundSettingStatus]);

  const toggleSoundSetting = () => {
    setSoundSettingStatus(soundSettingStatus === 'on' ? 'off' : 'on');
  };

  return {
    toggleSoundSetting,
    isSoundSettingOn: soundSettingStatus === 'on',
  };
};
