import axios from 'axios';
import storage from 'src/utils/storage';
import globalConfig from 'src/config';
import type { ResponseType } from './http.types';
import getDeviceId from 'src/utils/storage/getDeviceId';
import generateSinglePromiseFunction from 'src/utils/generateSinglePromiseFunction';
import helpers from 'src/utils/helpers';

type RefreshTokenResponseType =
  { isRefreshed: true; authToken: string } |
  { isRefreshed: false; authToken: null };

const refreshToken = async (): Promise<RefreshTokenResponseType> => {
  const MISSING_STORAGE_VALUE_ERROR_MESSAGE = 'Cannot access local storage value';

  try {
    const refreshToken = storage.refreshToken.get();

    if (refreshToken === null) {
      throw new Error(MISSING_STORAGE_VALUE_ERROR_MESSAGE);
    }

    const config = {
      headers: {
        'device-id': getDeviceId(),
      },
    };

    const response = await axios.post<ResponseType<null, { authToken: string; refreshToken: string }>>(`${globalConfig.apiUrl}/auth/refresh-token`, { refreshToken }, config);

    storage.authToken.set(response.data.meta.authToken);
    storage.refreshToken.set(response.data.meta.refreshToken);

    return { isRefreshed: true, authToken: response.data.meta.authToken };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response?.status === 401) {
      helpers.logOut();
    }

    return { isRefreshed: false, authToken: null };
  }
};

export default generateSinglePromiseFunction(refreshToken);
