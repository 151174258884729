import React from 'react';
import styled from 'styled-components';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ChatSidebarHeader from './ChatSidebarHeader';
import ChannelsBlock from './ChannelsBlock';
import { ChannelsWrapper } from './ChatSidebar.styles';
import { MainSidebarDrawerContent } from 'src/ui/containers/MainSidebar/MainSidebar';

import CustomEventCreator from 'src/utils/CustomEventCreator';
import ArchivalChannelList from '../../components/Navigation/components/ArchivalChannels/ArchivalChannelList';

const toggleChatSidebarEvent = new CustomEventCreator<{ isOpen: boolean }>({ eventName: 'TOGGLE_CHAT_SIDEBAR' });
export const openSwipableChatSidebarEvent = () => toggleChatSidebarEvent.dispatch({ isOpen: true });
export const closeSwipableChatSidebarEvent = () => toggleChatSidebarEvent.dispatch({ isOpen: false });

const SidebarDrawer: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const closeDrawer = () => setIsOpen(false);

  React.useEffect(() => {
    return toggleChatSidebarEvent.subscribe((data) => {
      setIsOpen(data.isOpen);
    });
  }, []);

  return (
    <StyledSwipeableDrawer
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <ChatSidebarHeader />

      <ChannelsWrapper>
        <ChannelsBlock onLinkClick={closeDrawer} />
      </ChannelsWrapper>

      <ArchivalChannelList />

      <MainSidebarDrawerContent />
    </StyledSwipeableDrawer>
  );
};

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    width: 95vw;
    max-width: 450px;
  }
`;

export default SidebarDrawer;
