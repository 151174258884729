import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';

const StyledCustomDialog = styled(Dialog)`
  backdrop-filter: blur(2px);

  .dialog__paper {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 12px;
    padding: 24px;
    max-width: 490px;
    box-sizing: border-box;
    overflow: visible;
  }

  .dialog__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px;
    overflow: visible;
  }

  .dialog__close-icon {
    position: absolute;
    top: 19px;
    right: 19px;
  }

  .title__head {
    color: ${({ theme }) => theme.palette.text.primary};
    margin-bottom: 8px;
    margin-right: 20px;
    max-width: 100%;
    word-wrap: break-word; 
  }

  .title__head-without-prompt {
    color: ${({ theme }) => theme.palette.text.primary};
    margin-right: 20px;
    margin-bottom: 20px;
    max-width: 100%;
    word-wrap: break-word;
  }

  .title__prompt {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-bottom: 20px;
    max-width: 100%;
    word-wrap: break-word; 
  }
  
  .title__description {
    color: ${({ theme }) => theme.palette.text.secondary};
    max-width: 100%;
    word-wrap: break-word; 
  }
  
  .title__prompt--with-pre {
    white-space: pre-wrap;
  }

  .title__prompt_next-line {
    white-space: pre-line;
  }

  .sign-up__prompt {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-top: 8px;
  }

  .sign-up__phone {
    font-weight: ${({ theme }) => theme.font.weight.xlg};
  }
`;

export default StyledCustomDialog;
