import authApi from './authApi';
import chatApi from './chatApi';
import companiesApi from './companiesApi';
import inventoryItemsApi from './inventoryItemsApi';
import inventoryTagsApi from './inventoryTagsApi';
import mediaApi from './mediaApi';
import mentionsApi from './mentionsApi';
import requestsApi from './requestsApi';
import userApi from './userApi';

export default {
  authApi,
  chatApi,
  companiesApi,
  inventoryItemsApi,
  inventoryTagsApi,
  mediaApi,
  mentionsApi,
  requestsApi,
  userApi,
};
