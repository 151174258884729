import React from 'react';

import Collapse from '@mui/material/Collapse';
import ChannelLink from './ChannelLink';

import { useChannelsListData } from './ChannelsList.hooks';
import type { IChannel } from 'src/types';
import { LinkListSkeleton, LinkSingleSkeleon } from './LinkSkeleton';

const ChannelsList: React.FC<{
  isLoading?: boolean;
  isInitiallyOpened?: boolean;
  channelsList: IChannel[];
  handleOpenStateChange?: (isOpen: boolean) => void;
  onChannelClick?: (ev: React.MouseEvent, channel: IChannel) => void;
  renderHeader?: (data: { isOpen: boolean; toggleCollapse: () => void }) => React.ReactNode;
}> = (props) => {
  const {
    isOpen,
    toggleCollapse,
  } = useChannelsListData({
    isInitiallyOpened: props.isInitiallyOpened,
    handleOpenStateChange: props.handleOpenStateChange,
  });

  return (
    <div>
      {props.isLoading
        ? (
          <LinkSingleSkeleon />
        ) : (
          props.renderHeader?.({ isOpen, toggleCollapse })
        )}

      <Collapse in={isOpen}>
        {props.isLoading && <LinkListSkeleton numberOfLinks={3} />}

        {!props.isLoading && props.channelsList.map((channel) => (
          <ChannelLink
            key={channel.channelId}
            channel={channel}
            onChannelClick={props.onChannelClick}
          />
        ))}
      </Collapse>
    </div>
  );
};

export default ChannelsList;
