import type { IMedia } from 'src/types';
import http from './http';
import type { ResponseType } from './http';

const path = 'media-item';

type SaveMediaItemType = {
  name: string;
  isPrivate: boolean;
  mimeType: string;
  key: string;
};

type UploadImageDataType = {
  name: string;
  file: string;
  isPrivate: boolean;
};

type UploadConfigType = {
  onUploadProgress: (progressEvent: ProgressEvent) => void;
  signal?: AbortSignal;
};

const getPresignedLink = (params: { fileName: string; mimeType: string }) => {
  return http.get<ResponseType<string>>(`${path}/upload-link`, { params });
};

const uploadImage = (data: UploadImageDataType, config?: UploadConfigType) => {
  return http.post<ResponseType<IMedia>>(`${path}/image`, data, config);
};

const saveMediaItem = (data: SaveMediaItemType, config?: { signal?: AbortSignal }) => {
  return http.post<ResponseType<IMedia>>(`${path}`, data, config);
};

const deleteFile = (mediaItemId: number) => {
  return http.delete(`${path}/${mediaItemId}`);
};

const getDownloadLink = (mediaItemId: number, params?: { size: 'thumb' | 'tinyThumb' }) => {
  return http.get<ResponseType<string>>(`${path}/download-link/${mediaItemId}`, { params });
};

export default {
  getPresignedLink,
  uploadImage,
  saveMediaItem,
  deleteFile,
  getDownloadLink,
};
