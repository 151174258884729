import type ReactQuill from 'react-quill';
import type { Range } from 'react-quill';

type BindingsType = {
  key: number;
  shortKey?: boolean;
  handler: (range?: Range, context?: unknown) => void;
};

export type ReactQuillExtendedType = ReactQuill & {
  editor: {
    keyboard: {
      bindings: {
        [key: string]: Array<BindingsType>;
      };
    };
  };
};

type UserMentionType = {
  type: 'user';
  id: number;
  value: string;
  isOnline: boolean;
  jobTitle: string;
  avatarLink: string | null;
};
export enum SpecialMentionTypeENUM {
  channel = 'channel',
}
type SpecialMentionType = {
  type: 'special';
  id: SpecialMentionTypeENUM;
  value: string;
};
export type MentionUserItemType = UserMentionType | SpecialMentionType;
