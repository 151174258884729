import type React from 'react';
import useUsersOnlineUpdate from './utils/hooks/useUsersOnlineUpdate';
import useCompanyUpdating from './utils/hooks/useCompanyUpdating';
import useSocketConnect from './utils/hooks/useSocketConnect';
import useChannelNotificationStatus from './utils/hooks/useChannelNotificationStatus';
import { useListenUnreadMessages } from './utils/hooks/useListenUnreadMessages';
import { useCrossWorkspaceWsEvents } from './utils/hooks/useCrossWorkspaceWsEvents';
import { useGetUsersCollection } from './utils/hooks/usersCollection/useGetUsersCollection';

const InitAppData:React.FC = () => {
  useSocketConnect();
  useUsersOnlineUpdate();
  useCompanyUpdating();
  useListenUnreadMessages();
  useCrossWorkspaceWsEvents();
  useChannelNotificationStatus({ withDataFetching: true });
  useGetUsersCollection();

  return null;
};

export default InitAppData;
