import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SearchResultsDropdown from './SearchResultsDropdown';
import { ReactComponent as CloseIcon } from 'src/ui/pages/Chat/assets/icons/close.svg';
import cn from 'classnames';

import useDebouncedState from 'src/utils/hooks/useDebouncedState';
import { useAppDispatch } from 'src/store';
import { chatSliceActions } from 'src/ui/pages/Chat/store';
import useSearchParams from 'src/utils/hooks/useSearchParams';
import LottieAnimation from 'src/ui/components/LottieAnimation';
import { t } from 'src/utils/getTranslation';
import type { QuerySearchParamsType } from '../../types/searchTypes';

import { closeSwipableChatSidebarEvent } from '../ChatSidebar/SidebarDrawer';
import { useSearch } from './useSearch';
import chatApi from 'src/api/chatApi';

type PropsType = {
  className?: string;
  noneAutoFocus?: boolean;
  inputClassName?: string;
  inputContainerClassName?: string;
  isFocused?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;

  searchResults?: {
    className?: string;
    resultsWrapperClassName?: string;
  };
};

const Search: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();
  const [params, setParam] = useSearchParams<QuerySearchParamsType>();
  const { chatId } = useParams();
  const search = useDebouncedState(params.search || '', (newValue) => setParam('search', newValue?.trim()), 500);
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = React.useState(false);
  const inputId = 'search-input';

  const searchString = params.search
    ? params.search.replace(/[<]/g, '&lt;').replace(/[>]/g, '&gt;')?.trim()
    : '';

  const [usersResults, usersIsLoading] = useSearch(
    (
      searchTerm: string,
      params: { signal?: AbortSignal },
    ) => chatApi.getUserSearchResults({ search: searchTerm }, params),
    {
      searchTerm: searchString,
      doSearch: isSearchDropdownOpen,
    },
  );
  const [channelsResults, channelsIsLoading] = useSearch(
    (
      searchTerm: string,
      params: { signal?: AbortSignal },
    ) => chatApi.getChannelSearchResults({ search: searchTerm, withPagination: true }, params),
    {
      searchTerm: searchString,
      doSearch: isSearchDropdownOpen,
    },
  );
  const [messagesResults, messagesIsLoading] = useSearch(
    (
      searchTerm: string,
      params: { signal?: AbortSignal },
    ) => chatApi.getMessageSearchResults({ search: searchTerm }, params),
    {
      searchTerm: searchString,
      doSearch: isSearchDropdownOpen,
    },
  );

  const userData = React.useMemo(() => {
    return {
      list: usersResults?.payload?.slice(-3) ?? [],
      isMoreButtonVisible: Boolean(usersResults?.payload?.length && usersResults?.payload.length > 3),
      isLoading: usersIsLoading,
    };
  }, [usersResults?.payload, usersIsLoading]);

  const channelData = React.useMemo(() => {
    return {
      list: channelsResults?.payload.slice(-3) ?? [],
      isMoreButtonVisible: Boolean(channelsResults?.payload.length && channelsResults.payload.length > 3),
      isLoading: channelsIsLoading,
    };
  }, [channelsResults?.payload, channelsIsLoading]);

  const messageData = React.useMemo(() => {
    return {
      list: messagesResults?.payload.slice(-3) ?? [],
      isMoreButtonVisible: Boolean(messagesResults?.payload.length && messagesResults?.payload.length > 3),
      isLoading: messagesIsLoading,
    };
  }, [messagesResults?.payload, messagesIsLoading]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    search.set(ev.target.value);
    if (ev.target.value.length > 0 && chatId !== 'search') {
      setIsSearchDropdownOpen(true);
    } else if (!ev.target.value.length) {
      setIsSearchDropdownOpen(false);
    }
  };

  const handleClickClose = () => {
    search.set('');
    setIsSearchDropdownOpen(false);

    dispatch(chatSliceActions.setIsActiveSearchInput(false));
  };

  const handleCloseResults = () => {
    setIsSearchDropdownOpen(false);
    closeSwipableChatSidebarEvent();
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLElement | null;
    if (target?.id === inputId) {
      return;
    }
    handleCloseResults();
  };

  const isLoading = usersIsLoading || channelsIsLoading || messagesIsLoading;

  return (
    <StyledSearch className={props.className}>
      <TextField
        value={search.value}
        onChange={handleChange}
        fullWidth
        margin="dense"
        autoFocus={!props.noneAutoFocus}
        id={inputId}
        classes={{
          root: 'search-input__container',
        }}
        InputProps={{
          inputRef: props.inputRef,
          endAdornment: (
            <IconButton
              onClick={handleClickClose}
              className="search-input__close-button"
            >
              {isLoading ? (
                <LottieAnimation animation="circleLoader" className="button__loader-icon" />
              ) : (
                search.value && <CloseIcon />
              )}
            </IconButton>
          ),
          classes: {
            root: cn('search-input__container', props.inputContainerClassName),
            input: cn('search-input__input', props.inputClassName),
            focused: 'search-input__focused',
            notchedOutline: 'search-input__outline',
          },
        }}
        inputProps={{
          autoComplete: 'on',
          placeholder: t('chat:navigation.search.searchPlaceholder'),
        }}
      />
      {isSearchDropdownOpen && (
        <ClickAwayListener onClickAway={handleClickAway}
        >
          <div>
            <SearchResultsDropdown
              channelData={channelData}
              messageData={messageData}
              userData={userData}
              handleClose={handleCloseResults}
              resultsWrapperClassName={props.searchResults?.resultsWrapperClassName}
              className={props.searchResults?.className}
            />
          </div>
        </ClickAwayListener>
      )}
    </StyledSearch >
  );
};

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  .search-input__container, .search-input__adornment, .search-input__adornment {
    font-size: ${({ theme }) => theme.font.size.sm};
  }

  .search-input__container {
    background-color: ${({ theme }) => theme.palette.background.default};
    margin: 0px;
    border-width: 1px;
    border-radius: 6px;
  }
  
  .search-input__input {
    padding: 9px 16px;
  }

  .search-input__close-button {
    padding: 0;
  }
  
  .search-input__focused {
    border: none;

    .search-input__outline {
      border: 1px solid ${({ theme }) => (theme.palette.input.border.focused)} !important;
    }
  }
  
  .search-input__outline {
    border: 1px solid ${({ theme }) => theme.palette.input.border.default} !important;
  }

  .button__loader-icon {
    height: 1em;
    width: 1em;
  }
`;

export default Search;
