import React from 'react';
import styled from 'styled-components';

import Badge from '@mui/material/Badge';

import { useUserOnlineStatus } from 'src/utils/hooks/general';

const AvatarOnlineBadge: React.FC<React.PropsWithChildren & {
  userId: number;
  className?: string;
}> = (props) => {
  const containerRef = React.useRef<HTMLSpanElement | null>(null);
  const [size, setSize] = React.useState(0);
  const isOnline = useUserOnlineStatus(props.userId);

  React.useEffect(() => {
    const element = containerRef.current;
    if (!element) {
      return;
    }

    const observer = new ResizeObserver(() => {
      setSize(element.clientWidth * 0.22);
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  return (
    <StyledBadge
      overlap="circular"
      variant="dot"
      className={props.className}
      isOnline={isOnline}
      size={size}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      ref={containerRef}
    >
      {props.children}
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge) <{ isOnline: boolean; size: number }>`
  .MuiBadge-dot {
    background-color: ${({ isOnline }) => (isOnline ? '#60DF43' : 'rgb(138, 144, 156)')};
    border: 1px solid #FAFAFA;
    border-radius: 100%;
    min-width: 8px;
    min-height: 8px;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  }
`;

export default AvatarOnlineBadge;
