const applyDeclension = (value: number, words: string[], locale: 'ru' | 'en') => {
  if (locale === 'ru') {
    let num = (Math.abs(value) % 100);
    if (num >= 5 && num < 20) {
      return words[2];
    }
    num %= 10;
    if (num >= 2 && num <= 4) {
      return words[1];
    }
    if (num === 1) {
      return words[0];
    }
    return words[2];
  }
  const num = (Math.abs(value) % 100) % 10;
  if (num === 1) {
    return words[0];
  }
  return words[1];
};

export default applyDeclension;
