import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { LanguageENUM, IUser, ThemeENUM, ICompany } from 'src/types';
import type { IMediaItem } from 'src/types/userTypes';
import storage, { LocalStorageItem } from 'src/utils/storage';
import type { RouteKeyType } from 'src/utils/constants';
import thunks from './mainSlice.thunks';
import { getLanguage, getTheme } from './mainSlice.utils';

const chatSidebarStatus = new LocalStorageItem<boolean>({ key: 'chat-sidebar-status' });

let isLoaded = false;
const getInitialStore = () => ({
  user: null as IUser | null,
  isAuthChecked: isLoaded,
  widthHrmSidebar: 229,
  widthRightChatSidebar: 300,
  isCloseHrmSidebar: false,
  isCloseChatSidebar: chatSidebarStatus.get() ?? false,
  theme: getTheme(),
  language: getLanguage(),
  favoritesLinks: storage.favoritesLinks.get() as RouteKeyType[],
  availableWorkspaces: {} as Record<number, ICompany>,
  selectedWorkspaces: storage.selectedWorkspaces.get() || [] as number[],
  socketData: {
    isConnected: false,
    connectionAttemptCount: 0,
  },
  onlineUsers: {} as Record<string, boolean>,
  revalidateAppDataTrigger: false,
});

const mainSlice = createSlice({
  name: 'main',
  initialState: getInitialStore,
  reducers: {
    setUser: (store, { payload }: PayloadAction<IUser | null>) => {
      store.user = payload;
    },
    updateCompany: (store, { payload }: PayloadAction<ICompany>) => {
      store.user!.company = payload;
    },
    updateCompanyImage: (store, { payload }: PayloadAction<{
      companyId: number;
      image: IMediaItem;
    }>) => {
      if (store.availableWorkspaces[payload.companyId]) {
        store.availableWorkspaces[payload.companyId].logoMediaItem = payload.image;
      }

      if (store?.user?.company) {
        store.user.company.logoMediaItem = payload.image;
      }
    },
    setWidthHrmSidebar: (store, { payload }: PayloadAction<number>) => {
      store.widthHrmSidebar = payload;
    },
    setWidthRightChatSidebar: (store, { payload }: PayloadAction<number>) => {
      store.widthRightChatSidebar = payload;
    },
    setIsCloseHrmSidebar: (store, { payload }: PayloadAction<boolean>) => {
      store.isCloseHrmSidebar = payload;
    },
    setIsCloseChatSidebar: (store, { payload }: PayloadAction<boolean>) => {
      store.isCloseChatSidebar = payload;
      chatSidebarStatus.set(payload);
    },
    setTheme: (store, { payload }: PayloadAction<ThemeENUM>) => {
      storage.theme.set(payload);
      store.theme = payload;
    },
    setLanguage: (store, { payload }: PayloadAction<LanguageENUM>) => {
      storage.language.set(payload);
      store.language = payload;
    },
    toggleFavoriteLink: (store, { payload }: PayloadAction<RouteKeyType>) => {
      const favoritesLinks = [...store.favoritesLinks];
      const linkIndex = favoritesLinks.indexOf(payload);

      if (linkIndex === -1) {
        favoritesLinks.push(payload);
      } else {
        favoritesLinks.splice(linkIndex, 1);
      }

      store.favoritesLinks = favoritesLinks;
      storage.favoritesLinks.set(favoritesLinks);
    },
    setOnlineUsers: (store, { payload }: PayloadAction<number[]>) => {
      store.onlineUsers = payload.reduce((acc, userId) => {
        acc[userId] = true;
        return acc;
      }, {} as Record<string, boolean>);
    },
    addOnlineUser: (store, { payload }: PayloadAction<number>) => {
      store.onlineUsers[payload] = true;
    },
    removeOnlineUser: (store, { payload }: PayloadAction<number>) => {
      delete store.onlineUsers[payload];
    },
    updateAvailableWorkspace: (store, { payload }: PayloadAction<ICompany>) => {
      store.availableWorkspaces[payload.companyId] = payload;
    },
    setSelectedWorkspaces: (store, { payload }: PayloadAction<number[]>) => {
      store.selectedWorkspaces = payload;
      storage.selectedWorkspaces.set(payload);
    },
    setSocketConnect: (store, { payload }: PayloadAction<boolean>) => {
      if (!store.socketData.isConnected && payload) {
        store.socketData.connectionAttemptCount += 1;
      }
      store.socketData.isConnected = payload;
    },
    setSocketAttemptCount: (store, { payload }: PayloadAction<number>) => {
      store.socketData.connectionAttemptCount = payload;
    },

    revalidateInitialAppData(store) {
      store.revalidateAppDataTrigger = !store.revalidateAppDataTrigger;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.authorize.fulfilled, (store, { payload }) => {
      store.isAuthChecked = true;
      isLoaded = true;
      store.user = payload;
    });
    builder.addCase(thunks.authorize.rejected, (store) => {
      store.isAuthChecked = true;
      isLoaded = true;
    });
    builder.addCase(thunks.getWorkspaces.fulfilled, (store, { payload }) => {
      store.availableWorkspaces = payload;
    });
    builder.addCase(thunks.changeCompany.fulfilled, (store, { payload }) => {
      store.user = payload.user;
    });
  },
});

export const mainSliceActions = mainSlice.actions;
export { default as mainSliceThunks } from './mainSlice.thunks';

export default mainSlice.reducer;
