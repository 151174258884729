import React from 'react';

export type MenuAnchorDataType<T_Element = Element> = ReturnType<typeof useMenuAnchor<T_Element>>;

const useMenuAnchor = <A = Element>() => {
  const [anchor, setAnchor] = React.useState<A | null>(null);

  const clearAnchor = React.useCallback(() => setAnchor(null), [setAnchor]);
  const setEventTargetAsAnchor: React.MouseEventHandler<A> = React.useCallback(
    (ev) => setAnchor(ev.target as A),
    [setAnchor],
  );

  return {
    anchor,
    isAnchor: Boolean(anchor),
    setAnchor,
    clearAnchor,
    setEventTargetAsAnchor,
  };
};

export default useMenuAnchor;
