import _random from 'lodash/random';

import BaseSocket from './BaseSocket';
import config, { isDev } from 'src/config';
import storage from 'src/utils/storage';
import getDeviceId from 'src/utils/storage/getDeviceId';
import SocketEventsENUM from './socketEvents';
import addActivityHandler from './addActivityHandler';
import type { ErrorResponseType, ResponseType } from '../http/http.types';
import helpers from 'src/utils/helpers';
import { captureException } from 'src/ui/containers/ErrorBoundary';
import getStore from 'src/store/getStore';

type SocketResponseType<T_Payload, T_Meta extends object = Record<never, never>> = {
  error: null | ErrorResponseType;
  data: ResponseType<T_Payload, T_Meta>;
};

class MainSocket extends BaseSocket {
  private removeActivityHandler?: () => void;

  constructor() {
    super(
      {
        uri: config.socketUrl,
        transports: ['websocket'],
        autoConnect: false,
        auth: {},
      },
      {
        getAuth: () => {
          const authToken = storage.authToken.get() as string;

          return {
            // TODO - snake case sholud be replaced with camel case. Should be removed.
            auth_token: authToken,
            token: authToken,
            deviceId: getDeviceId(),
            activeCompanies: JSON.stringify(getStore().main.selectedWorkspaces),
          };
        },
        onConnect: () => {
          this.removeActivityHandler = addActivityHandler(this.updateUserActivity);
          this.handleAllEvents();
        },
        onDisconnect: () => {
          this.removeActivityHandler?.();
          this.removeActivityHandler = undefined;
          this.unsubscribeFromAnyEvent?.();
        },
      },
    );
  }

  updateUserActivity = () => {
    this.emit(SocketEventsENUM.userActivityUpdate, 'The user performed some action')
      .catch((err) => {
        console.error('Failed to update user activity', err);
      });
  };

  emit = async <T_Payload, T_Meta extends object = Record<never, never>>(
    event: string,
    params: unknown,
    requestCount = 1,
  ) => {
    if (isDev) {
      await helpers.sleep(_random(100, 1000));
    }

    return new Promise<ResponseType<T_Payload, T_Meta>>((res, rej) => {
      this.socket.timeout(1000 * 60).emit(event, params, async (
        err: Error,
        response: SocketResponseType<T_Payload, T_Meta>,
      ) => {
        if (err) {
          if (err.message === 'operation has timed out') {
            if (requestCount < config.maxRequestNumberOfAttempts) {
              await helpers.sleep(1000);

              await this.waitForConnection().catch(rej);

              return this.emit<T_Payload, T_Meta>(event, params, requestCount + 1).then(res, rej);
            }

            captureException({
              error: err,
              tags: { location: 'socket', requestCount },
              context: { event, params },
            });
          }

          rej(err);
          return;
        }

        try {
          if (response.error) {
            return rej(response.error);
          }

          res(response.data);
        } catch (err) {
          console.error(`Failed to handle socket response ("${event}"):`, response, '\nError:', err);

          rej(err);
        }
      });
    });
  };

  addListener = <T_Payload, T_Meta extends object = Record<string, never>>(event: SocketEventsENUM, callback: (data: ResponseType<T_Payload, T_Meta>) => void) => { // eslint-disable-line max-len
    const innerCallback = ({ data }: { data: ResponseType<T_Payload, T_Meta> }): void => {
      callback(data);
    };
    this.socket.on(event, innerCallback);
    return () => {
      this.removeListener(event, innerCallback);
    };
  };

  removeListener = <T_Payload, T_Meta extends object = Record<string, never>>(
    event: SocketEventsENUM,
    callback: ((data: ResponseType<T_Payload, T_Meta>) => void) | ((params: { data: ResponseType<T_Payload, T_Meta> }) => void),
  ) => {
    this.socket.off(event, callback);
  };

  addEventHandler = <T_Payload, T_Meta extends object = Record<string, never>>(event: SocketEventsENUM, callback: (data: ResponseType<T_Payload, T_Meta>) => void) => { // eslint-disable-line max-len
    return this.addListener(event, callback);
  };

  private readonly SOCKET_EVENT_NAME = 'NEW_SOCKET_MESSAGE';
  private unsubscribeFromAnyEvent?: () => void;
  private handleAllEvents = () => {
    const handleAnyEvent = (event: SocketEventsENUM, value: unknown) => {
      const socketEvent = new CustomEvent(this.SOCKET_EVENT_NAME, {
        detail: {
          event,
          value,
        },
      });

      window.dispatchEvent(socketEvent);
    };

    this.socket.onAny(handleAnyEvent);
    this.unsubscribeFromAnyEvent = () => {
      this.socket.offAny(handleAnyEvent);
    };
  };

  // eslint-disable-next-line
  addOldEventHandler = <T_Value>(event: SocketEventsENUM, callback: (data: T_Value) => void) => { // eslint-disable-line max-len
    const handleEvent = ((ev: CustomEvent<{
      event: SocketEventsENUM;
      value: T_Value;
    }>) => {
      if (ev.detail.event !== event) {
        return;
      }
      callback(ev.detail.value);
    }) as (ev: Event) => void;

    window.addEventListener(this.SOCKET_EVENT_NAME, handleEvent);

    return () => {
      window.removeEventListener(this.SOCKET_EVENT_NAME, handleEvent);
    };
  };
}

export default MainSocket;
