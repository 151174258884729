import React from 'react';
import ToolbarTooltip from './RichTextBox/CustomToolbar/ToolbarTooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

type JobPositionPropsType = {
  name?: string;
};

const JobPosition: React.FC<JobPositionPropsType> = (props) => {
  const { t } = useTranslation('hrmEmployees');
  const jobPosition = React.useMemo(() => {
    return props.name ||
      t('userTable.actionTools.dataNotFound');
  }, [props.name, t]);

  const [isHidden, setIsHidden] = React.useState(false);
  const jobPositionRef = React.useRef<HTMLSpanElement | null>(null);

  React.useLayoutEffect(() => {
    if (!jobPositionRef.current) {
      return;
    }
    const isHidden = jobPositionRef.current.offsetWidth - jobPositionRef.current.scrollWidth !== 0;
    setIsHidden(isHidden);
  }, []);

  return (
    <ToolbarTooltip
      title={jobPosition}
      className="list-item__job-title"
      isDisabled={!isHidden}
    >
      <Typography
        variant="h6"
        className="list-item__job-title"
        ref={jobPositionRef}
      >
        {jobPosition}
      </Typography>
    </ToolbarTooltip>
  );
};

export default JobPosition;
