import CustomEventCreator from 'src/utils/CustomEventCreator';
import { ElectronAPI } from 'src/utils/electronAPI';

const MESSAGE_ID_TEMPLATE = '{{messageId}}';
const MESSAGE_RENDERED_EVENT_NAME = `CHAT_SCROLL__MESSAGE_${MESSAGE_ID_TEMPLATE}_RENDERED`;

export type ExternalListenerType = (data: { messageId: number; highlightMessage?(element: Element | null): void }) => void;

const checkForMessageId = <D extends object>(data: D): data is D & { messageId: number } => 'messageId' in data;
class ChatScrollController {
  private channelId: number | null = null;
  private dataPropName = 'data-chat-message-id';
  private newMessagesBannerId = 'new-messages-banner';
  private wasScrolledToFirstUnread = false;
  private threadWasScrolledToFirstUnread = false;

  private externalScrollControllers: ExternalListenerType[] = [];

  private resetChannelData() {
    this.wasScrolledToFirstUnread = false;
  }

  private resetThreadData() {
    this.threadWasScrolledToFirstUnread = false;
  }

  addExternalListener(listener: ExternalListenerType) {
    this.externalScrollControllers.push(listener);
  }

  removeExternalListener(listener: ExternalListenerType) {
    this.externalScrollControllers = this.externalScrollControllers.filter((item) => item !== listener);
  }

  setChannelId(channelId: number) {
    if (this.channelId === channelId) {
      return;
    }
    this.channelId = channelId;
    this.resetChannelData();
  }

  private getMessageElement(messageId: number) {
    return document.querySelector(`[${this.dataPropName}="${messageId}"]`);
  }

  getMessageDataProps(messageId: number) {
    return ({ [this.dataPropName]: messageId });
  }

  private getNewMessagesId(isThread?: boolean) {
    return `${isThread ? 'thread_' : ''}${this.newMessagesBannerId}`;
  }

  getNewMessagesBannerProps(isThread?: boolean) {
    return {
      id: this.getNewMessagesId(isThread),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public highlightMessage(element: Element | null) {
    if (!element) {
      return;
    }

    element.classList.add('message-highlighted');

    const removeHighlightedClass = () => {
      element.classList.remove('message-highlighted');
      element.removeEventListener('animationend', removeHighlightedClass);
    };

    element.addEventListener('animationend', removeHighlightedClass);
  }

  private messageRenderedEvent = new CustomEventCreator<{ messageId: number }>({ eventName: 'MESSAGE_RENDERED_EVENT' });
  private newMessagesBannerEvent = new CustomEventCreator({ eventName: 'NEW_MESSAGES_BANNER_RENDER' });
  private newThreadMessagesBannerEvent = new CustomEventCreator({ eventName: 'NEW_THREA_MESSAGES_BANNER_RENDER' });

  // eslint-disable-next-line class-methods-use-this
  private getMessageRenderedEventName(messageId: number) {
    return MESSAGE_RENDERED_EVENT_NAME.replace(MESSAGE_ID_TEMPLATE, messageId.toString());
  }

  // eslint-disable-next-line class-methods-use-this
  private async privateScrollTo<T_Data extends object>(params: {
    getElement: () => Element | null;
    eventController: CustomEventCreator<T_Data>;
    validateEvent?: (arg: T_Data) => boolean;
    getEventName?: () => string;
    smooth?: boolean;
    timeoutValue?: number;
    handleElement?: (element: Element | null) => void;
    errorContext: string;
    isThread?: boolean;
  }) {
    // Support for Virtual LIST scroll. Only available for Feed page.
    // If Virtual list will be everywhere then need to replace this chatScrollController
    if (!params.isThread) {
      const subscriber = {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        unsubscribe: () => { },
      };

      subscriber.unsubscribe = params.eventController.subscribe((arg) => {
        if ((params.validateEvent && !params.validateEvent(arg)) || !checkForMessageId(arg)) {
          return;
        }
        this.externalScrollControllers.forEach((listener) => listener({
          messageId: arg.messageId,
          highlightMessage: params.handleElement,
        }));
        subscriber.unsubscribe();
      }, { eventName: params.getEventName?.() });
      return;
    }

    let element = params.getElement();

    if (!element) {
      element = await new Promise((res, rej) => {
        const unsubscribe = params.eventController.subscribe(
          (arg) => {
            if (params.validateEvent && !params.validateEvent(arg)) {
              return;
            }
            unsubscribe();
            const element = params.getElement();

            res(element);
            clearTimeout(timeoutId);
          },
          { eventName: params.getEventName?.() },
        );

        const timeoutId = setTimeout(() => {
          rej(new Error(`Scroll is failed (timeout) (${params.errorContext})`));
          unsubscribe();
        }, params.timeoutValue || 5_000);
      });
    }

    if (!element) {
      throw new Error(`Element not found (${params.errorContext})`);
    }

    element.scrollIntoView({
      behavior: params.smooth ? 'smooth' : 'auto',
    });

    params.handleElement?.(element);
  }

  async scrollToMessage(data: {
    messageId: number;
    smooth?: boolean;
    isThread?: boolean;
    isHighlightMessage?: boolean;
  }) {
    if (!this.channelId) {
      throw new Error('No channel selected');
    }
    this.privateScrollTo({
      getElement: () => this.getMessageElement(data.messageId),
      eventController: this.messageRenderedEvent,
      validateEvent: (arg) => arg.messageId === data.messageId,
      getEventName: () => this.getMessageRenderedEventName(data.messageId),
      smooth: data.smooth,
      handleElement: data.isHighlightMessage ? (element) => this.highlightMessage(element) : undefined,
      errorContext: `message ${data.isThread ? ' in thread' : ''} - ${data.messageId}`,
      isThread: data.isThread,
    });
  }

  emitRenderedMessage(data: {
    messageId: number;
  }) {
    this.messageRenderedEvent.dispatch(data, {
      eventName: this.getMessageRenderedEventName(data.messageId),
    });
  }

  emitRenderedNewMessagesBanner = (isThread?: boolean, messageId?: number) => {
    if (isThread) {
      this.newThreadMessagesBannerEvent.dispatch({}, {});
      return;
    }
    this.newMessagesBannerEvent.dispatch({ messageId, test: '>>>> emitRenderedNewMessagesBanner' }, {});
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scrollToFirstUnread(isThread?: boolean) {
    if (isThread ? this.threadWasScrolledToFirstUnread : this.wasScrolledToFirstUnread) {
      return;
    }

    if (ElectronAPI.systemStateInSuspendedMode) {
      return;
    }
    // Verify that it's useless
    // this[isThread ? 'threadWasScrolledToFirstUnread' : 'wasScrolledToFirstUnread'] = true;

    this.privateScrollTo({
      getElement: () => document.getElementById(this.getNewMessagesId(isThread)),
      eventController: isThread ? this.newThreadMessagesBannerEvent : this.newMessagesBannerEvent,
      smooth: false,
      errorContext: `unread banner ${isThread ? ' in thread' : ''}`,
      isThread,
    });
  }
}

export default new ChatScrollController();
