import React from 'react';

import { type IChannel } from 'src/types';
import ChannelNavItem from '../SidebarActionItems/ChannelNavItem';

const ChannelLink: React.FC<{
  channel: IChannel;
  onChannelClick?: (ev: React.MouseEvent, channel: IChannel) => void;
}> = (props) => {
  return <ChannelNavItem channel={props.channel} onChannelClick={props.onChannelClick} />;
};

export default ChannelLink;
