import React from 'react';

import { ElectronAPI } from 'src/utils/electronAPI';
import InAppNotifications from './InAppNotifications';
import RemoteServiceNotifications from './RemoteServiceNotifications';

const PushNotifications: React.FC = () => {
  if (ElectronAPI.isElectronApp) {
    return <InAppNotifications />;
  }

  return <RemoteServiceNotifications />;
};

export default PushNotifications;
