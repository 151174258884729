import React from 'react';
import SidebarActionItem from './SidebarActionItem';
import { ROUTES } from 'src/utils/constants';
import styled, { css } from 'styled-components';
import { t } from 'src/utils/getTranslation';
import { useAppSelector } from 'src/store/store';
import selectors from '../../storeV2/chatSelectorsV2';
import { LinkSingleSkeleon } from '../ChatSidebar/LinkSkeleton';
import { DotBadge } from 'src/ui/components/DotBadge';

type PropsType = {
  isLoading?: boolean;
};

const ThreadNavItem: React.FC<PropsType> = (props) => {
  const unreadThreadsCount = useAppSelector(selectors.selectUnreadThreadsCount);

  if (props.isLoading) {
    return <LinkSingleSkeleon />;
  }
  return (
    <SidebarActionItem
      icon="💬"
      navigateTo={ROUTES.chat.createPath('threads')}
      title={(
        <StyledTitle hasToBeMarked={!!unreadThreadsCount}>
          {t('chat:navigation.threadMessages')}
        </StyledTitle>
      )}
      hasBadge={!!unreadThreadsCount}
      actionBadge={!!unreadThreadsCount && <DotBadge />}
    />
  );
};

const StyledTitle = styled.span<{ hasToBeMarked: boolean }>`
  font-weight: inherit;
  color: inherit;
  ${({ hasToBeMarked, theme }) => hasToBeMarked && css`
    font-weight: ${theme.font.weight.lg};
  `}
`;
export default ThreadNavItem;
