import React from 'react';

import { Link } from 'react-router-dom';
import Avatar from 'src/ui/components/Avatar';
import MenuItem from './MenuItem';
import UserMenuAccountStyle from './UserMenuAccount.styles';

import { useAppSelector } from 'src/store';
import type { IUser } from 'src/types';
import { ROUTES } from 'src/utils/constants';
import helpers from 'src/utils/helpers';
import settings from '../assets/settings.svg';
import chat from '../assets/chat.svg';
import logout from '../assets/logout.svg';
import { t } from 'src/utils/getTranslation';
import config from 'src/config';
import ExternalLink from 'src/ui/components/ExternalLink';

const UserMenuAccount: React.FC<{
  setAnchor: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  className?: string;
}> = (props) => {
  const user = useAppSelector(({ main }) => main.user) as IUser;

  return (
    <UserMenuAccountStyle className={props.className}>
      <div className="user-title">
        <Avatar
          user={user!}
          size="sm"
          onClick={() => props.setAnchor(null)}
          withLink
        />
        <p className="user-name">
          {user.fullName}
        </p>
      </div>

      <Link to={ROUTES.profile.createPath(user.userId)} className="menu-item-link">
        <MenuItem
          setAnchor={props.setAnchor}
          menuPunktIcon={settings}
          menuPunktTitle={t('general:userMenu.settingAccount')}
        />
      </Link>

      <ExternalLink href={config.supportChatUrl} target="_blank" rel="noreferrer" className="menu-item-link">
        <MenuItem
          setAnchor={props.setAnchor}
          menuPunktIcon={chat}
          menuPunktTitle={t('general:userMenu.helpDesk')}
        />
      </ExternalLink>

      <MenuItem
        setAnchor={props.setAnchor}
        onClick={helpers.logOut}
        menuPunktIcon={logout}
        menuPunktTitle={t('general:userMenu.logoutAccount')}
      />
    </UserMenuAccountStyle >
  );
};

export default UserMenuAccount;
