import React from 'react';
import { ElectronAPI } from 'src/utils/electronAPI';

const ExternalLink = React.forwardRef<HTMLAnchorElement, React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>>(
  (props, ref) => {
    if (ElectronAPI.isElectronApp) {
      const { href = '', ...rest } = props;
      return <a {...rest} ref={ref} target="_self" onClick={() => ElectronAPI.actions.openExternalLink(href)}>{props.children}</a>;
    }
    return (
      <a {...props} ref={ref} />
    );
  },
);

export default ExternalLink;
