import { SocketEventsENUM } from './socketEvents';
import socket from './socket';
import type { ChannelMetaDataType, IChannel, IMessage, IUser } from 'src/types';
import { type UserEntranceStatusENUM } from 'src/utils/constants';

const getMyChannels = async () => {
  const response = await socket.emit<{
    channels: IChannel[];
    dmChannels: IChannel[];
    archivedChannels: IChannel[];
  }, {
    channelsMeta: Record<string, ChannelMetaDataType>;
  }>(SocketEventsENUM.chat_getMyChannels, null);

  return {
    allChannels: response.payload,
    channelsMeta: response.meta.channelsMeta,
  };
};

const getChannel = async (channelId: number, params: { withMeta: boolean }) => {
  const response = await socket.emit<IChannel, { channelMeta: ChannelMetaDataType }>(
    SocketEventsENUM.chat_getChannel,
    { channelId, ...params },
  );

  return {
    channel: response.payload,
    channelMeta: response.meta.channelMeta,
  };
};

const readMessage = async (data: {
  channelId: number;
  parentMessageId?: number;
  lastViewedMessageTime: string;
}) => {
  const response = await socket.emit<{ newMeta: ChannelMetaDataType }>(
    SocketEventsENUM.chat_readMessage,
    data,
  );

  return response.payload;
};

const markAsUnreadMessage = async (data: {
  channelId: number;
  parentMessageId?: number;
  lastViewedMessageTime: string;
}) => {
  const response = await socket.emit<{
    newMeta: ChannelMetaDataType;
    userThreadId?: number;
    lastViewedMessageTime: string;
  }>(
    SocketEventsENUM.chat_unReadMessage,
    data,
  );
  return response.payload;
};

type MentionEventDataType = {
  channelId: number;
  messageId: number;
};

const subscribeOnNewMentionV2 = (callback: (data: MentionEventDataType) => void) => {
  return socket.addEventHandler<null, MentionEventDataType>(
    SocketEventsENUM.chat_newMention,
    (value) => {
      callback(value.meta);
    },
  );
};

const subscribeOnRemovedMentionV2 = (callback: (data: MentionEventDataType) => void) => {
  return socket.addEventHandler<null, MentionEventDataType>(
    SocketEventsENUM.chat_removedMention,
    (value) => {
      callback(value.meta);
    },
  );
};

const subscribeOnNewMessage = (callback: (message: IMessage) => void) => {
  return socket.addOldEventHandler<{ newMessage: IMessage }>(
    SocketEventsENUM.getNewMessage,
    (value) => {
      callback(value.newMessage);
    },
  );
};

const subscribeOnNewMention = (callback: (value: { channelId: number }) => void) => {
  return socket.addOldEventHandler<{
    channelId: number;
    messageId: number;
    userId: number;
  }>(
    SocketEventsENUM.mentionInChannel,
    (value) => {
      callback({ channelId: value.channelId });
    },
  );
};

type UpdateChannelType = IChannel;

const subscribeOnChannelUpdate = (callback: (data: UpdateChannelType) => void) => {
  return socket.addOldEventHandler<UpdateChannelType>(
    SocketEventsENUM.updatedChannel,
    (value) => {
      callback(value);
    },
  );
};

const subscribeOnChannelArchive = (callback: (data: UpdateChannelType) => void) => {
  return socket.addOldEventHandler<UpdateChannelType>(
    SocketEventsENUM.archivedChannel,
    (value) => {
      callback({ ...value, isArchived: true });
    },
  );
};

const subscribeOnChannelUnarchive = (callback: (data: UpdateChannelType) => void) => {
  return socket.addOldEventHandler<UpdateChannelType>(
    SocketEventsENUM.unarchivedChannel,
    (value) => {
      callback({ ...value, isArchived: false });
    },
  );
};

const subscribeOnUserAddedToChannel = (callback: (data: IChannel) => void) => {
  return socket.addOldEventHandler<IChannel>(
    SocketEventsENUM.addUsersToChannel,
    (value) => {
      callback(value);
    },
  );
};
const subscribeOnUserRemovedFromChannel = (callback: (data: IChannel) => void) => {
  return socket.addOldEventHandler<IChannel>(
    SocketEventsENUM.channel_userLeft,
    (value) => {
      callback(value);
    },
  );
};

const subscribeOnRemovedUser = (callback: (data: IChannel) => void) => {
  return socket.addOldEventHandler<IChannel>(
    SocketEventsENUM.removeUserFromChannel,
    (value) => {
      callback(value);
    },
  );
};

const subscribeOnChannelDelete = (callback: (data: { channelId: number }) => void) => {
  return socket.addOldEventHandler<{ channelId: number }>(
    SocketEventsENUM.deletedChannel,
    (value) => {
      callback(value);
    },
  );
};

const subscribeOnOwnerChange = (callback: (data: { channelId: number }) => void) => {
  return socket.addOldEventHandler<{ channelId: number }>(
    SocketEventsENUM.ownerReassigned,
    (value) => {
      callback(value);
    },
  );
};

const subscribeOnDeletedMessage = (callback: (data: {
  messageId: number;
  channelId: number;
  parentMessageId?: number;
}) => void) => {
  return socket.addOldEventHandler<{
    messageId: number;
    channelId: number;
    deletedAt: string;
  }>(
    SocketEventsENUM.messageDeleted,
    (value) => {
      callback(value);
    },
  );
};

const subscribeOnGetUpdatedMessage = (callback: (data: IMessage) => void) => {
  return socket.addOldEventHandler<IMessage>(
    SocketEventsENUM.getUpdatedMessage,
    (value) => {
      callback(value);
    },
  );
};

export type UserTypingResponseType = {
  channelId: number;
  parentMessageId?: number;
  user: IUser;
};

const subscribeOnUserTyping = (callback: (data: UserTypingResponseType) => void) => {
  return socket.addOldEventHandler<UserTypingResponseType>(
    SocketEventsENUM.userTyping,
    callback,
  );
};

const subscribeOnNewActionMessage = (callback: (data: IMessage) => void) => {
  return socket.addOldEventHandler<IMessage>(
    SocketEventsENUM.newActionMessage,
    (value) => {
      callback(value);
    },
  );
};

const userTyping = async (data: {
  channelId: number;
  parentMessageId?: number;
}) => {
  await socket.emit<{ newMeta: ChannelMetaDataType }>(
    SocketEventsENUM.userTyping,
    data,
  );
};

const joinRoom = (channelId: number) => {
  return socket.emit<{ channelId: number }>(
    SocketEventsENUM.joinRoom,
    { channelId },
  );
};

const updateUserEntranceStatus = (channelId: number, actionType: UserEntranceStatusENUM) => {
  return socket.emit<{ channelId: number; actionType: UserEntranceStatusENUM }>(
    SocketEventsENUM.chat_userEntranceStatus,
    { channelId, actionType },
  );
};

export default {
  getMyChannels,
  getChannel,
  readMessage,
  subscribeOnNewMentionV2,
  subscribeOnRemovedMentionV2,
  subscribeOnNewMessage,
  subscribeOnNewMention,
  subscribeOnChannelUpdate,
  subscribeOnChannelArchive,
  subscribeOnChannelUnarchive,
  subscribeOnUserAddedToChannel,
  subscribeOnRemovedUser,
  subscribeOnChannelDelete,
  subscribeOnOwnerChange,
  subscribeOnDeletedMessage,
  joinRoom,
  updateUserEntranceStatus,
  subscribeOnGetUpdatedMessage,
  subscribeOnUserTyping,
  userTyping,
  subscribeOnNewActionMessage,
  markAsUnreadMessage,
  subscribeOnUserRemovedFromChannel,
};
