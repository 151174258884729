import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReactComponent as MoreIcon } from 'src/ui/pages/Chat/assets/icons/arrow-right.svg';

import { ROUTES } from 'src/utils/constants';
import type { SearchResultsCategoryENUM } from 'src/types/chatTypes';

const MoreButton: React.FC<{
  category: SearchResultsCategoryENUM;
  value: string;
  text: string;
  handleClose: () => void;
}> = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      className="results__more-button"
      onClick={() => {
        navigate({
          pathname: ROUTES.chat.createPath('search'),
          search: `?${createSearchParams({ category: props.category, search: props.value, page: '1' })}`,
        });
        props.handleClose();
      }}
    >
      <Typography variant="h4" className="more-button__text">
        {props.text}
      </Typography>
      <MoreIcon />
    </Button>
  );
};

export default MoreButton;
