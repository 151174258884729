import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'src/ui/components/Button';
import { ReactComponent as LockIcon } from 'src/ui/pages/Chat/assets/icons/lock.svg';
import { ReactComponent as LogOutIcon } from 'src/ui/pages/Chat/assets/icons/log-out.svg';
import ConfirmationModal from 'src/ui/components/ConfirmationModal';

import { DEFAULT_ICON_TEXT } from 'src/ui/pages/Chat/components/ChannelsManagement/constants';
import type { ExtendedChannelType } from './ChannelsOverview.hooks';
import { useChannelLink } from './ChannelsOverview.hooks';
import { t } from 'src/utils/getTranslation';

const ChannelLink: React.FC<{
  channel: ExtendedChannelType;
  channelListTotalCount: number;
  handleSubmitLeaveChannel: (channelId: number) => Promise<void>;
  handleJoinClick: (event: React.MouseEvent, channel: ExtendedChannelType) => Promise<void>;
  navigateToChannel: (event: React.MouseEvent, channelId: number) => void;
  getSuffixesByNumber: (count: number) => string;
  isMain?: boolean;
}> = (props) => {
  const {
    isLeaveChannelConfirmationOpen,
    isLeaveChannelLoading,
    isJoinChannelLoading,
    handleCancelLeaveChannel,
    handleLeaveChannelClick,
    handleSubmitLeaveChannel,
    handleJoinClick,
  } = useChannelLink({
    handleJoinClick: props.handleJoinClick,
    handleSubmitLeaveChannel: props.handleSubmitLeaveChannel,
  });

  return (
    <>
      <StyledChannelLink
        className="channel-link"
        isOwner
        onClick={(event) => props.navigateToChannel(event, props.channel.channelId)}
      >
        <Box className="channel-link__title">
          <Box className="channel__icon">{props.channel.icon || DEFAULT_ICON_TEXT}</Box>

          <div>
            <div className="title_container">
              <Typography
                variant="h4"
                className="channel-link__text"
              >
                {props.channel.name}
              </Typography>

              {props.channel.isPrivate && <LockIcon className="channel-lock__icon" />}
            </div>

            <Typography
              variant="h4"
              className="channel-link__text-description"
            >
              {props.channel.membersCount} {t('chat:channels.channelOverview.member')}{props.getSuffixesByNumber(props.channel?.membersCount)}
              {props.channel.isMember && <p className="member__container">,<span className="is-member__text">{t('chat:channels.channelOverview.withMe')}</span></p>}
              {props.channel.isMember && (
                <span className="is-member__text is-member__text-mobile">
                  {t('chat:channels.channelOverview.withMe')}
                </span>
              )}
            </Typography>
          </div>
        </Box>

        {!props.channel.isMember
          ? (
            <Button
              size="small"
              variant="contained"
              disabled={isJoinChannelLoading}
              onClick={(event) => handleJoinClick(event, props.channel)}
              isLoading={isJoinChannelLoading}
              className="input__button"
            >
              {t('chat:channels.channelOverview.join')}
            </Button>
          ) : (
            <Button
              size="small"
              className={classnames('input__button', {
                'input__button--disabled': props.channel.isOwner || props.isMain,
              })}
              variant="text"
              onClick={(event) => {
                if (props.isMain) {
                  return;
                }
                handleLeaveChannelClick(event, props.channel);
              }}
            >
              <div className="back-label__container">
                <LogOutIcon className={classnames({ 'back-label__icon--disabled': props.channel.isOwner || props.isMain })} /> {t('chat:channels.editingChannel.buttons.leave')}
              </div>
            </Button>
          )}
      </StyledChannelLink>

      <ConfirmationModal
        isOpen={isLeaveChannelConfirmationOpen}
        title={`${t('chat:channels.leaveChannel.title')} ${props.channel?.name}`}
        cancelButtonTitle={t('chat:channels.actionButtons.cancel')}
        submitButtonTitle={t('chat:channels.editingChannel.buttons.leaveChannel')}
        onSubmit={() => handleSubmitLeaveChannel(props.channel.channelId)}
        submitButtonColor="error"
        onCancel={handleCancelLeaveChannel}
        onClose={handleCancelLeaveChannel}
        submitButtonDisabled={isLeaveChannelLoading}
        cancelButtonDisabled={isLeaveChannelLoading}
      >
        <Typography
          variant="h5"
          className="title__prompt title__prompt_next-line"
        >
          {props.channel.isPrivate
            ? `${t('chat:channels.leaveChannel.descriptionPrivate')}\n\n${t('chat:channels.leaveChannel.descriptionComebackPrivate')}`
            : `${t('chat:channels.leaveChannel.description')}\n\n${t('chat:channels.leaveChannel.descriptionComeback')}`}
        </Typography>
      </ConfirmationModal>
    </>
  );
};

const StyledChannelLink = styled.div<{ isOwner?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: ${({ theme }) => theme.palette.background.paper};

  .channel__icon {
    padding-top: 1px;
    font-size: ${({ theme }) => theme.font.size.sm};
    display: flex;
    line-height: 16px;
  }

  .channel-link__title {
    padding-left: 16px;
    display: flex;
    flex-direction: flex-start;
    gap: 6px;
  }

  .channel-lock__icon {
    width: 14px;
    height: 14px;
    align-self: center;
    padding-left: 6px;
  }

  .title_container {
    display: flex;
  }

  .channel-link__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: ${({ theme }) => theme.font.weight.lg};
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.palette.text.tertiary};
    max-width: 250px;
  }

  .channel-link__text-description {
    display: flex;
    font-weight: ${({ theme }) => theme.font.weight.md};
    font-size: ${({ theme }) => theme.font.size.xs};
    line-height: 16px;
    color: ${({ theme }) => theme.palette.text.quaternary};
  }

  .member__container {
    display: flex;
  }

  .is-member__text {
    padding-left: 5px;
    color: ${({ theme }) => theme.palette.grey[200]};
  }

  .input__button {
    display: flex;
    color: ${({ theme, isOwner }) => (!isOwner ? theme.palette.error.main : undefined)};
    cursor: pointer;
  }

  .back-label__container {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .back-label__icon--disabled {
    fill: ${({ theme }) => theme.palette.text.disabled};
  }

  .input__button--disabled {
    color: ${({ theme }) => theme.palette.text.disabled};
    cursor: default;
  }

  @media (min-width: ${({ theme }) => theme.screen.sm}px) {
    .is-member__text-mobile {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    .channel-link__text {
      max-width: 100px;
    }

    .channel-link__text-description {
      max-width: calc(100vw - 260px);
    }

    .channel-link__title {
      padding-left: 0;
    }

    .input__button {
      padding: 5px 8px;
      font-size: 10px;
      max-width: calc(100vw - 280px);
    }
    
    .channel-link__text-description {
      flex-direction: column;
    }

    .member__container {
      display: none;
    }

    .is-member__text {
      padding-left: 0;
    }
  }
`;

export default ChannelLink;
