import type { Normalize } from 'react-i18next';

import LinkIcon from '@mui/icons-material/Link';

import type { ResourcesType } from 'src/i18n';
import { SortDirectionENUM, UserPermissionsENUM } from 'src/types';
import type { AuthPropsType } from 'src/ui/components/Protector';
import { DEFAULT_PER_PAGE_VALUE } from 'src/ui/containers/Table/Table';

export type RouteTextKeyType = keyof ResourcesType['general']['pages'];
type GeneralNamespaceKeyType = Normalize<ResourcesType['general']>;

type LinkType = 'internal' | 'external';

type IconType = typeof LinkIcon;

const createRoute = <A extends string, B extends AuthPropsType | null, C>(params: {
  path: A;
  auth: B;
  createPath?: C;
  textKey?: RouteTextKeyType;
  type?: LinkType;
  Icon?: IconType;
}): {
  path: A;
  auth: B;
  createPath: C extends undefined ? null : C;
  type: LinkType;
  Icon: IconType;
  linkTitleKey: GeneralNamespaceKeyType;
  pageTitleKey: GeneralNamespaceKeyType;
} => {
  return {
    path: params.path,
    auth: params.auth,
    createPath: (params.createPath || null) as C extends undefined ? null : C,
    type: params.type || 'internal',
    Icon: params.Icon || LinkIcon,
    pageTitleKey: `pages.${params.textKey}.pageTitle` as GeneralNamespaceKeyType,
    linkTitleKey: `pages.${params.textKey}.linkTitle` as GeneralNamespaceKeyType,
  };
};

export const ROUTES = {
  root: createRoute({
    path: '/',
    auth: { isAuthRequired: false },
  }),
  hrm: createRoute({
    path: '/hrm/*',
    auth: { isAuthRequired: true },
  }),
  resetPassword: createRoute({
    path: '/auth/reset-password',
    auth: { isAuthRequired: false },
  }),
  forgotPassword: createRoute({
    path: '/auth/forgot-password',
    auth: { isAuthRequired: false },
  }),
  signIn: createRoute({
    path: '/auth/sign-in',
    auth: { isAuthRequired: false },
  }),
  signUp: createRoute({
    path: '/auth/sign-up',
    auth: { isAuthRequired: false },
  }),
  terms: createRoute({
    path: '/auth/terms',
    auth: { isAuthRequired: false },
  }),
  confirmInvitedUser: createRoute({
    path: '/auth/confirm-invite',
    auth: { isAuthRequired: false },
  }),
  home: createRoute({
    path: '/hrm/home',
    textKey: 'home',
    auth: { isAuthRequired: true },
  }),
  employees: createRoute({
    path: '/hrm/employees',
    textKey: 'employees',
    auth: {
      isAuthRequired: true,
      permissions: [UserPermissionsENUM.hrm__available],
    },
  }),
  createUser: createRoute({
    path: '/hrm/create-user',
    textKey: 'createUser',
    auth: {
      isAuthRequired: true,
      permissions: [UserPermissionsENUM.hrm__user_invite],
    },
  }),
  requestsList: createRoute({
    path: '/hrm/requests',
    textKey: 'requestsList',
    auth: { isAuthRequired: true },
  }),
  createRequest: createRoute({
    path: '/hrm/create/request',
    textKey: 'createRequest',
    auth: { isAuthRequired: true },
  }),
  chatGeneral: createRoute({
    path: '/chat/*',
    auth: { isAuthRequired: false },
  }),
  chat: createRoute({
    path: '/chat/:chatId?',
    textKey: 'chat',
    auth: { isAuthRequired: true },
    createPath(chatId?: number | 'search' | 'new-dm' | '*' | 'threads', profileId?: number) {
      const preparedChatPath = this.path.replace('/:chatId?', chatId ? `/${chatId}` : '');
      return `${preparedChatPath}${profileId ? `?profileId=${profileId}` : ''}`;
    },
  }),
  extraHours: createRoute({
    path: '/hrm/extra-hours',
    textKey: 'extraHours',
    auth: { isAuthRequired: true },
  }),
  inventory: createRoute({
    path: '/hrm/inventory',
    textKey: 'inventory',
    auth: { isAuthRequired: true },
  }),
  eventsCalendar: createRoute({
    path: '/hrm/calendar',
    textKey: 'eventsCalendar',
    auth: { isAuthRequired: true },
  }),
  events: createRoute({
    path: '/hrm/events',
    textKey: 'events',
    auth: { isAuthRequired: true },
  }),
  profile: createRoute({
    path: '/hrm/profile/:userId',
    textKey: 'profile',
    auth: { isAuthRequired: true },
    createPath(userId: number | string) {
      return this.path.replace(':userId', userId as string);
    },
  }),
  createWorkspace: createRoute({
    path: '/workspace/create',
    textKey: 'createWorkspace',
    auth: { isAuthRequired: true },
  }),
  choiceWorkspace: createRoute({
    path: '/workspace/choice',
    textKey: 'choiceWorkspace',
    auth: {
      isAuthRequired: true,
      alwaysAvailable: true,
    },
    createPath(type?: 'signin' | 'select' | 'invite') {
      return `${this.path}${type ? `?type=${type}` : ''}`;
    },
  }),
  workspaceSettings: createRoute({
    path: '/company/settings',
    textKey: 'workspaceSettings',
    auth: {
      isAuthRequired: true,
      permissions: [UserPermissionsENUM.company__settings_write],
    },
  }),
} as const;

export const defaultParamsValue = {
  page: 1,
  perPage: DEFAULT_PER_PAGE_VALUE,
  sortBy: 'name',
  sortDirection: SortDirectionENUM.asc,
};

export enum ViewsENUM {
  cards = 'cards',
  table = 'table',
}

export enum TabsHrmENUM {
  work = 'work',
  fired = 'fired',
}

export enum TabsProfileENUM {
  profile = 'profile',
  hrInfo = 'hrInfo',
  requests = 'requests',
  extraHours = 'extraHours',
  inventory = 'inventory',
  roles = 'roles',
}

export enum DirectionSortingENUM {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type RouteKeyType = keyof typeof ROUTES;

export const SECOND = 1_000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const DATE_FORMAT_IN_DB = 'YYYY-MM-DD';
export const DATE_FORMAT_IN_UI = 'DD/MM/YYYY';

export const USERS_PER_PAGE = 12;

export const TIME_TO_STORE_TEMP_WORKSPACE_DATA_IN_MIN = 5 * MINUTE;

export enum UserEntranceStatusENUM {
  open = 'open',
  close = 'close',
}

export const SUPPORTED_VIDEO_FORMATS = ['webm', 'mp4', 'mov', 'ogg'];
