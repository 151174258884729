import React from 'react';
import { useAppSelector } from 'src/store/store';
import type { IUser } from 'src/types';

export const useUserList = () => {
  const { users, status } = useAppSelector((state) => state.usersCollection);
  const currentActiveUserWorkspace = useAppSelector((state) => state.main.user?.company?.companyId);

  const listOfUsersByWorkspace = React.useMemo(() => {
    return Object.values(users.entities).filter((user): user is IUser => user?.companyId === currentActiveUserWorkspace);
  }, [currentActiveUserWorkspace, users]);

  return {
    listOfUsersByWorkspace,
    status,
  };
};
