import React from 'react';
import StyledMessageText from './styles';

type MessageTextPropsType = {
  ellipsis?: boolean;
  className?: string;
} & React.PropsWithChildren;

const MessageText: React.FC<MessageTextPropsType> = (props) => {
  return (
    <StyledMessageText ellipsis={props.ellipsis} className={props.className}>
      {props.children}
    </StyledMessageText>
  );
};

export default MessageText;
