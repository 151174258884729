import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from 'src/utils/hooks/general';
import type { IUser } from 'src/types';
import { LocalStorageItem } from 'src/utils/storage';
import { ROUTES } from 'src/utils/constants';
import { NotificationController } from './NotificationController';
import { ElectronAPI } from 'src/utils/electronAPI';

const getUseNotificationHook = () => {
  /** No need to create firebase notification handlers for the Electron application,
   *  since notifications works via native controlleres
   * */
  let useData: () => {
    handleAcceptNotifications: () => Promise<void>;
    handleRejectNotifications: () => void;
    isRequestModalOpen: boolean;
    isActivating: boolean;
  } = () => {
    return {
      handleAcceptNotifications: () => Promise.resolve(),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      handleRejectNotifications: () => { },
      isRequestModalOpen: false,
      isActivating: false,
    };
  };

  if (!ElectronAPI.isElectronApp) {
    const notificationSettingChoice = new LocalStorageItem<NotificationPermission>({
      key: 'push-notifications-choice',
      defaultValue: 'default',
    });

    const firebase = new NotificationController('firebase');

    const APP_ICON_PATH = '/favicon.ico';

    useData = (): ReturnType<typeof useData> => {
      const [isRequestModalOpen, setIsRequestModalOpen] = React.useState(false);
      const [isActivating, setIsActivating] = React.useState(false);
      const user = useUser() as IUser | null;
      const navigate = useNavigate();

      React.useEffect(() => {
        if (!firebase.isNotificationEnabled) {
          return;
        }

        if (user?.userId && Notification.permission === 'granted') {
          firebase.setUserNotificationsToken();
          return;
        }

        if (
          !user?.userId ||
          notificationSettingChoice.get() === 'denied'
        ) {
          return;
        }

        const timeoutId = setTimeout(() => {
          setIsRequestModalOpen(true);
        }, 15_000);

        return () => {
          clearTimeout(timeoutId);
        };
      }, [user?.userId]);

      React.useEffect(() => {
        let unsubscribe: (() => void) | undefined;

        (async () => {
          unsubscribe = await firebase.subscribe((payload) => {
            let handleNotificationClick: (ev: Event) => void = () => null;

            switch (payload.data?.type) {
              case 'chatMessage': {
                if (window.location.pathname === ROUTES.chat.createPath(+payload.data.channelId)) {
                  return;
                }

                handleNotificationClick = (ev: Event) => {
                  ev.preventDefault();
                  navigate(ROUTES.chat.createPath(+payload.data!.channelId));
                };

                break;
              }

              case 'serviceMessage': {
                firebase.sendMessageToSW('SERVICE_MESSAGE_CLEAR_NOTIFICATION', payload.data);
                return;
              }

              default:
                break;
            }

            const notification = new Notification(
              payload.data!.title,
              {
                body: payload.data!.body,
                icon: APP_ICON_PATH,
                silent: false,
              },
            );

            notification.addEventListener('click', handleNotificationClick);

            return notification;
          });
        })();

        return () => {
          unsubscribe?.();
        };
      }, [navigate]);

      const handleAcceptNotifications = React.useCallback(async () => {
        try {
          if (!firebase.isNotificationEnabled) {
            return;
          }
          setIsActivating(true);
          const response = await Notification.requestPermission();

          notificationSettingChoice.set(response);
          if (response !== 'granted') {
            return;
          }

          firebase.setUserNotificationsToken();
        } catch (err) {
          console.error('Failed to enable notifications:', err);
        } finally {
          setIsRequestModalOpen(false);
          setIsActivating(false);
        }
      }, []);

      const handleRejectNotifications = React.useCallback(() => {
        setIsRequestModalOpen(false);
        notificationSettingChoice.set('denied');
      }, []);

      return {
        handleAcceptNotifications,
        handleRejectNotifications,
        isRequestModalOpen,
        isActivating,
      };
    };
  }
  return useData;
};

export const usePushNotificationsData = getUseNotificationHook();
