/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { createTransform } from 'redux-persist';
import type { IChatStore } from 'src/ui/pages/Chat/storeV2/types';
import * as chatStoreHelpers from 'src/ui/pages/Chat/storeV2/helpers';

export const MAX_AMOUNT_OF_MESSAGES = 60;

const removeEntitiesByIds = (ids: number[], channelId: number, state: IChatStore) => {
  ids.forEach((id) => {
    const entity = state.channelDetailsObject[channelId].channelMessagesObject[id];
    if (!entity) { return; }

    const childMessagesIds = entity.childMessages;
    delete state.channelDetailsObject[channelId].channelMessagesObject[id];
    if (childMessagesIds?.length) {
      removeEntitiesByIds(childMessagesIds.map((i) => i.messageId), channelId, state);
    }
  });

  const channelMessages = state.channelDetailsObject[channelId].channelMessagesObject;

  if (channelMessages && state.channelDetailsObject[channelId]) {
    state.channelDetailsObject[channelId].messages = Object.keys(channelMessages).map(Number);
  }
};

const sliceMessagesHistory = (toTransform: IChatStore) => {
  Object.values(toTransform.channelDetailsObject).forEach((channelMeessages) => {
    const removedIds: number[] = [];
    if (channelMeessages.messages.length > MAX_AMOUNT_OF_MESSAGES) {
      const listbyDates = channelMeessages.messages
        .map((id) => ({
          date: channelMeessages.channelMessagesObject[id]?.createdAt,
          id,
        }))
        .sort((a, b) => (new Date(a?.date).getTime() - new Date(b?.date).getTime()))
        .map(({ id }) => id);

      const newMessagesList = listbyDates.slice(-MAX_AMOUNT_OF_MESSAGES);
      const removedMessages = listbyDates.slice(0, -MAX_AMOUNT_OF_MESSAGES);
      removedIds.push(...removedMessages);
      channelMeessages.messages = newMessagesList;
    }
    if (channelMeessages.messages.length) {
      channelMeessages.status = 'pending_init';
    } else {
      channelMeessages.status = 'initialized';
    }
    removeEntitiesByIds(removedIds, channelMeessages.channelId, toTransform);
  });
};

export const channelTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    return inboundState;
  },
  // transform state being rehydrated
  (outboundState, key) => {
    if (key === 'chatPageV2') {
      const initialValue = chatStoreHelpers.getInitialState();

      const state = outboundState as IChatStore;
      const channelsEntity = state.channelDetailsObject;

      if (channelsEntity) {
        for (const channelId in channelsEntity) {
          channelsEntity[channelId].markedAsDirty = true;
          channelsEntity[channelId].pendingMessages = {
            errored: [],
            sending: [],
          };
        }
      }
      sliceMessagesHistory(outboundState as IChatStore);

      return {
        ...initialValue,
        channelsObject: state.channelsObject,
        channelDetailsObject: channelsEntity,
      } as IChatStore;
    }
    return outboundState;
  },
  // define which reducers this transform gets called for.
  { whitelist: ['chatPageV2'] },
);
export const savedPathsTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    return inboundState;
  },
  // transform state being rehydrated
  (outboundState) => {
    return outboundState;
  },
  // define which reducers this transform gets called for.
  { whitelist: ['savedPaths'] },
);
