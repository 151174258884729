import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  IUser,
  IPagesInfo,
  IProfile,
} from 'src/types';
import thunks from './employees.thunks';

const getInitialStore = () => ({
  users: [] as IUser[],
  pagesInfo: null as IPagesInfo | null,
  queryString: '',
  profile: {} as IProfile,
  isUsersLoading: false,
  isPagesInfoLoading: false,
  onlineUsers: [] as number[],
});

const employeesSlice = createSlice({
  name: 'employeesPage',
  initialState: getInitialStore,
  reducers: {
    queryString: (store, { payload }: PayloadAction<string>) => {
      store.queryString = payload;
    },
    setProfile: (store, { payload }: PayloadAction<IProfile>) => {
      store.profile = payload;
    },
    setOnlineUsers: (store, { payload }: PayloadAction<number[]>) => {
      store.onlineUsers = payload;
    },
    updateUser: (store, { payload }: PayloadAction<{ userId: number; data: Partial<IUser> }>) => {
      if (store.profile?.userId === payload.userId) {
        store.profile = {
          ...store.profile,
          ...payload.data,
        };
      }

      const userIndex = store.users.findIndex((user) => user.userId === payload.userId);

      if (userIndex === -1) {
        return;
      }

      store.users[userIndex] = {
        ...store.users[userIndex],
        ...payload.data,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getUsersList.pending, (store) => {
      store.isUsersLoading = true;
    });
    builder.addCase(thunks.getUsersList.fulfilled, (store, { payload }) => {
      store.isUsersLoading = false;
      store.users = payload.users;
      store.pagesInfo = payload.pagesInfo;
    });
    builder.addCase(thunks.getUsersList.rejected, (store) => {
      store.isUsersLoading = false;
    });
  },
});

export const employeesSliceActions = employeesSlice.actions;
export { default as employeesSliceThunks } from './employees.thunks';

export default employeesSlice.reducer;
