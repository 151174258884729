import React from 'react';

import CustomDialog from 'src/ui/components/CustomDialog/CustomDialog';
import ActionButtons from 'src/ui/components/ActionButtons';

type ChannelConfirmPropsType = {
  isOpen: boolean;
  submitButton: {
    handleSubmit: () => void;
    submitButtonTitle?: string | undefined;
    variant?: 'primary' | 'secondary' | undefined;
    color?: 'primary' | 'error';
  };
  cancelButton: {
    handleCancel: () => void;
    cancelButtonTitle?: string | undefined;
  };
  title?: React.ReactNode;
} & React.PropsWithChildren;

const ChannelConfirm: React.FC<ChannelConfirmPropsType> = (props) => {
  return (
    <CustomDialog
      isOpen={props.isOpen}
      handleCancel={props.cancelButton.handleCancel}
      title={props.title}
    >
      {props.children}
      <ActionButtons
        submitButton={props.submitButton}
        cancelButton={props.cancelButton}
      />
    </CustomDialog>
  );
};

export default ChannelConfirm;
