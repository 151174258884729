const noEventNameError = new Error('You should specify "eventName" in constructor or method\'s params');
const noElementError = new Error('You should specify "element" in constructor or method\'s params');

type TargetElementType = HTMLElement | Document | Window;

type ActionParamsType = {
  eventName?: string;
  element?: TargetElementType;
};

class CustomEventCreator<T_Data extends object = EmptyObjectType> {
  eventName?: string;
  element?: TargetElementType;

  private lastValue: CustomEvent<{
    value: string;
  }> | undefined;

  constructor(params?: {
    eventName?: string;
    /** Default value - `window` */
    element?: TargetElementType;
  }) {
    this.element = params?.element || window;
    this.eventName = params?.eventName;
  }

  private getEventParams(params?: ActionParamsType) {
    const eventName = params?.eventName || this.eventName;
    if (!eventName) {
      throw noEventNameError;
    }

    const element = params?.element || this.element;
    if (!element) {
      throw noElementError;
    }

    return {
      eventName,
      element,
    };
  }

  dispatch = (data: T_Data, params?: ActionParamsType) => {
    try {
      const { eventName, element } = this.getEventParams(params);

      const event = new CustomEvent(eventName, {
        detail: {
          value: JSON.stringify(data),
        },
      });
      this.lastValue = event;
      element.dispatchEvent(event);
    } catch (error) {
      console.error('Dispatch custom event error:', error);
    }
  };

  subscribe = (callback: (data: T_Data) => void, params?: ActionParamsType) => {
    try {
      const { eventName, element } = this.getEventParams(params);

      const handler = ((ev: CustomEvent<{
        event: string;
        value: string;
      }>) => {
        callback(JSON.parse(ev.detail.value) as T_Data);
      }) as (ev: Event) => void;
      element.addEventListener(eventName, handler);
      if (this.lastValue) {
        element.dispatchEvent(this.lastValue);
      }

      const unsubscribe = () => {
        element.removeEventListener(eventName, handler);
      };

      return unsubscribe;
    } catch (error) {
      console.error('Subscribe custom event error:', error);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => { };
    }
  };
}

export default CustomEventCreator;
