export { ThemeENUM, LanguageENUM } from './generalTypes';
export type {
  SizeOptionType,
  SortingOptionType,
  OptionType,
  ICompany,
} from './generalTypes';

export type {
  IUserRole,
  IUserPermission,
  IUser,
  IProfile,
  UserContactsType,
  IWorkspace,
  IPagesInfo,
  ConfirmationUserDataType,
  IDepartment,
  IJobPosition,
} from './userTypes';
export type {
  IRequest,
  IStatusRequest,
  IGetUserRequestsListParams,
  IGetAllRequestsListParams,
} from './requestTypes';
export { RequestStatusENUM, RequestTypeENUM } from './requestTypes';
export { UserStatusENUM, UserPermissionsENUM, SortingENUM } from './userTypes';
export * from './chatTypes';
export type {
  IMessage,
  IChannel,
  IMedia,
  IMessageText,
  IUserChannel,
  IEmoji,
  IReaction,
  IDiscussion,
  ChannelType,
  MessageType,
  UserToChannelsType,
  DraftDataType,
  ChannelMetaDataType,
  MediaItemType,
  ReactQuillExtendedType,
} from './chatTypes';
export {
  MessageActionModeENUM,
} from './chatTypes';
export type {
  IExtraHours,
  IGetUserExtraHoursListParams,
  IGetAllExtraHoursListParams,
} from './extraHoursTypes';
export type {
  IInventoryTag,
  IGetInventoryTagsListParams,
} from './inventoryTagTypes';
export type {
  IInventoryItem,
  IGetInventoryItemsListParams,
} from './inventoryItemTypes';

export type { AuthPropsType } from 'src/ui/components/Protector';

export enum SortDirectionENUM {
  asc = 'ASC',
  desc = 'DESC',
}

export type FindAndCountMetaType = {
  page: number;
  perPage: number;
  totalPages: number;
  totalRecords: number;
};
