// import React from 'react';

import { useAppSelector } from 'src/store';
import { setAppBadge } from '../messagingInterface';
import { ElectronAPI } from '../electronAPI';
import { useDidUpdate } from './useDidUpdate';
import { unreadMessagesCountSelector } from 'src/store/workspacesMeta/workspacesMeta.selectors';
import React from 'react';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Navigator {
    setAppBadge?: (n: number) => void;
  }
}

const useAppBadge = () => {
  const companyId = useAppSelector((state) => state.main.user?.company?.companyId);
  const unreadMessageCounts = useAppSelector(unreadMessagesCountSelector);

  const count = React.useMemo(() => {
    return Object.values(unreadMessageCounts).reduce((acc, count) => {
      return acc + count;
    }, 0);
  }, [unreadMessageCounts]);

  useDidUpdate(() => {
    if (!companyId) {
      return;
    }
    if (ElectronAPI.isElectronApp) {
      ElectronAPI.actions.setAppBadge(count);
      return;
    }

    if (typeof navigator.setAppBadge !== 'function') {
      return;
    }

    const badgeValue = count;

    navigator.setAppBadge(badgeValue);
    setAppBadge(badgeValue);
  }, [count, unreadMessageCounts]);
};

export default useAppBadge;
