import React from 'react';

const useDebounce = <A extends unknown[], R>(callback: (...args: A) => R, debounceTime = 300) => {
  const timeoutIdRef = React.useRef<number | null>(null);
  const debounceTimeRef = React.useRef(debounceTime);
  const callbackRef = React.useRef(callback);

  const debounceCallback = React.useCallback((...args: A) => {
    clearTimeout(timeoutIdRef.current as number);

    timeoutIdRef.current = setTimeout(() => {
      callbackRef.current(...args);
    }, debounceTimeRef.current) as unknown as number;
  }, []);

  return debounceCallback;
};

export default useDebounce;
