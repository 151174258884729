import { MESSAGES_LIST_LIMIT } from '../../constants';
import chatApi from 'src/api/chatApi';

export default async (data: {
  channelId: number;
  unreadMessagesCount?: number;
  toBottom?: boolean;
  scrollTargetMessageId?: number;
}) => {
  const response = await chatApi.getChannelMessages({
    channelId: data.channelId,
    limit: MESSAGES_LIST_LIMIT,
    unreadMessagesCount: data.toBottom ? undefined : data.unreadMessagesCount,
    messageId: data.scrollTargetMessageId,
  });

  return {
    channelMessages: response.data.payload,
    hasMoreUp: response.data.meta.hasMoreUp ?? false,
    hasMoreDown: response.data.meta.hasMoreDown ?? false,
    firstUnreadMessageId: response.data.meta.firstUnreadMessageId ?? null,
    meta: response.data.meta,
  };
};
