import React from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MoreButton from './MoreButton';
import StyledResultsCategory from './ResultsCategory.styles';
import { ReactComponent as LockIcon } from 'src/ui/pages/Chat/assets/icons/lock.svg';

import type { IChannel } from 'src/types';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/utils/constants';
import { SearchResultsCategoryENUM } from 'src/types/chatTypes';
import getHighlightedChatText from 'src/ui/pages/Chat/utils/getHighlightedChatText';
import { t } from 'src/utils/getTranslation';

import LottieAnimation from 'src/ui/components/LottieAnimation';

const ChannelResults: React.FC<{
  channelsResults: IChannel[];
  value: string;
  isVisibleMoreButton: boolean;
  handleClose: () => void;
  isLoading?: boolean;
}> = (props) => {
  const navigate = useNavigate();

  const handleClickChannel = (channel: IChannel) => {
    navigate(ROUTES.chat.createPath(channel.channelId));
    props.handleClose();
  };

  return (
    <StyledResultsCategory>
      <Typography
        variant="h4"
        className="results__title"
      >
        {t('chat:navigation.search.channels.title')}
      </Typography>
      {props.isLoading && (
        <LottieAnimation animation="circleLoader" className="button__loader-icon" />
      )}

      {!!props.channelsResults.length && !props.isLoading && (
        <>
          {props.channelsResults.map((channel) => {
            const highlightedChannelName = getHighlightedChatText({
              highlightString: props.value,
              messageText: channel.name || '',
            });

            return (
              <Button
                key={channel.channelId}
                className="results__channels"
                onClick={() => handleClickChannel(channel)}
              >
                <div className="channel-icon">
                  {channel?.icon || '⚪️'}
                </div>

                <Typography
                  variant="h6"
                  className="results__text"
                >
                  {highlightedChannelName}
                </Typography>

                {channel.isPrivate && <LockIcon width="14px" height="14px" />}
              </Button>
            );
          })}
        </>
      )
      }
      {!props.isLoading && !props.channelsResults.length && (
        <Typography className="results__empty-state">
          {t('chat:searchResults.notFound.noResults')}
        </Typography>
      )}
      {props.isVisibleMoreButton && (
        <MoreButton
          text={t('chat:navigation.search.channels.showAll')}
          value={props.value}
          category={SearchResultsCategoryENUM.channels}
          handleClose={props.handleClose}
        />
      )}
    </StyledResultsCategory>
  );
};

export default ChannelResults;
