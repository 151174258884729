import constants from '../constants';

const checkIsSupportedImage = (fileType?: string) => {
  const supportedImageTypes = constants.SUPPORTED_IMAGE_TYPE.join('|');
  const imageTypes = new RegExp(`image/${supportedImageTypes}`, 'i');

  if (imageTypes.test(fileType || '') && !fileType?.includes('svg')) {
    return true;
  }

  return false;
};

export default checkIsSupportedImage;
