import React from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import AboutMe from './AboutMe';

type UserAdditionalInfoPropsType = {
  title: string;
  info?: string;
  isLoaded?: boolean;
  isPhone?: boolean;
  isEmail?: boolean;
  profileHeight?: number;
};

const UserAdditionalInfo: React.FC<UserAdditionalInfoPropsType> = (props) => {
  const isAboutMe = Boolean(props.profileHeight);

  const getSkeletonWidth = () => {
    let width = '100px';
    if (isAboutMe) {
      width = '100%';
    }
    if (props.isEmail) {
      width = '200px';
    }
    if (props.isPhone) {
      width = '115px';
    }
    return width;
  };

  const infoNode = React.useMemo(() => {
    if (!props.info || !props.info.length) {
      return null;
    }
    if (props.isPhone || props.isEmail) {
      return (
        <a
          href={`${props.isPhone ? 'tel' : 'mailto'}:${props.info}`}
          className="user-additional__info"
        >
          {props.info}
        </a>
      );
    }
    return (
      <AboutMe info={props.info} />
    );
  }, [props.info, props.isEmail, props.isPhone]);

  return (
    <Box display="flex" flexDirection="column" gap="7px">
      <Typography
        variant="caption"
        className="user-additional__title"
      >
        {props.title}
      </Typography>
      {props.isLoaded ? (
        <div>
          {infoNode}
        </div>
      ) : (
        <Box display="flex" flexDirection="column" gap="6px">
          <Skeleton
            width={getSkeletonWidth()}
          />
          {isAboutMe && (
            <>
              <Skeleton
                width={getSkeletonWidth()}
              />
              <Skeleton
                width={getSkeletonWidth()}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UserAdditionalInfo;
