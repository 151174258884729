import React from 'react';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import CustomDialog from 'src/ui/components/CustomDialog';
import ActionButtons from 'src/ui/components/ActionButtons';

type ConfirmationModalPropsType = {
  isOpen: boolean;
  title: string | React.ReactNode;
  additionalText?: string;
  onClose: () => void;
  onCancel: () => void;
  cancelButtonTitle: string;
  onSubmit: () => void;
  onAnimationEnd?: () => void;
  submitButtonTitle: string;
  submitButtonColor?: 'primary' | 'error';
  children?: React.ReactNode;
  withCloseButton?: boolean;
  submitButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
};

const ConfirmationModal: React.FC<ConfirmationModalPropsType> = (props) => {
  return (
    <StyledConfirmationModal
      isOpen={props.isOpen}
      onAnimationEnd={props.onAnimationEnd}
      title={(
        <Typography className="title__head title__prompt--with-pre">
          {props.title}
        </Typography>
      )}
      withCloseButton={props.withCloseButton}
      handleCancel={props.onClose}
    >
      <div>
        {props.additionalText && (
          <Typography
            variant="h4"
            className="title__prompt"
          >
            {props.additionalText}
          </Typography>
        )}

        {props.children}
      </div>

      <ActionButtons
        cancelButton={{
          handleCancel: props.onCancel,
          cancelButtonTitle: props.cancelButtonTitle,
          disabled: props.cancelButtonDisabled,
        }}
        submitButton={{
          handleSubmit: props.onSubmit,
          submitButtonTitle: props.submitButtonTitle,
          color: props.submitButtonColor,
          disabled: props.submitButtonDisabled,
        }}
      />
    </StyledConfirmationModal>
  );
};

const StyledConfirmationModal = styled(CustomDialog)`
    @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    .dialog__paper {
      margin: 16px;
      width: calc(100% - 32px);
    }
  }
`;

export default ConfirmationModal;
