import styled from 'styled-components';
import Popover from '@mui/material/Popover';

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    border-radius: 12px;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.03);
  }

  em-emoji-picker {
    width: 100%;
    --font-family: 'Mulish', 'Lato', sans-serif;
    --font-size: 14px;
    --rgb-color: ${({ theme }) => theme.palette.emojiPicker.rgbColor};
    --rgb-accent: ${({ theme }) => theme.palette.emojiPicker.rgbAccent};
    --rgb-input: ${({ theme }) => theme.palette.emojiPicker.rgbInput};
    --category-icon-size: 20px;
    --color-border-over: ${({ theme }) => theme.palette.emojiPicker.colorBorderOver};
    --color-border: ${({ theme }) => theme.palette.emojiPicker.colorBorder};
    --border-radius: 12px;
    --rgb-background: ${({ theme }) => theme.palette.emojiPicker.rgbBackground};
  }
`;

export default StyledPopover;
