import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from 'src/App';
import StyledComponentsThemeProvider from 'src/ui/containers/StyledComponentsThemeProvider';
import GlobalStyles from 'src/ui/styles/GlobalStyles';
import ErrorBoundary from 'src/ui/containers/ErrorBoundary';

import store, { persistor } from 'src/store';
import reportWebVitals from './reportWebVitals';

import './i18n';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-calendar-timeline/lib/Timeline.css';
import 'react-datepicker/dist/react-datepicker.css';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ErrorBoundary sendErrorInfo errorBoundaryId="root">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledComponentsThemeProvider>
          <BrowserRouter>
            <App />

            <GlobalStyles />
          </BrowserRouter>
        </StyledComponentsThemeProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
