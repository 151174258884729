import styled from 'styled-components';

import ResizableContainer from 'src/ui/components/ResizableContainer';

export const ChannelsWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 16px;
`;

export default styled(ResizableContainer)`
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.menu.background};

  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 100%;
  z-index: 10;
`;
