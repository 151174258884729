import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItemButton, { type ListItemButtonProps } from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import { ReactComponent as ChatIcon } from 'src/ui/containers/MainSidebar/assets/chat.svg';
import { ReactComponent as LogoutIcon } from 'src/ui/containers/MainSidebar/assets/logout.svg';
import { ReactComponent as AddWorkspaceIcon } from 'src/ui/containers/MainSidebar/assets/addWorkspace.svg';
import Avatar, { type AvatarPropsType } from 'src/ui/components/Avatar';

import { t } from 'src/utils/getTranslation';
import config from 'src/config';
import { ROUTES } from 'src/utils/constants';
import helpers from 'src/utils/helpers';
import { useAppSelector } from 'src/store/store';
import useMenuAnchor, { type MenuAnchorDataType } from 'src/utils/hooks/useMenuAnchor';
import type { ICompany } from 'src/types';
import { toast } from 'react-toastify';
import ToggleSoundButton from './components/ToggleSoundButton';
import Loader from 'src/ui/components/Loader/Loader';

const ListItem = React.forwardRef<HTMLDivElement, ListItemButtonProps>((props, forwardedRef) => {
  return (
    <Box component={ListItemButton} gap="5px" {...props} ref={forwardedRef} />
  );
});

const AVATAR_SIZE: AvatarPropsType['size'] = 'xxxxs';
const ICON_SIZE = 18;

const LinkItem: React.FC<{
  Icon: typeof ChatIcon;
  label: string;
  to?: string;
  href?: string;
  onClick?: () => void;
}> = (props) => {
  return (
    <Link
      to={(props.to || props.href) as string}
      onClick={(ev) => {
        props.onClick?.();

        if (props.href) {
          ev.preventDefault();
          window.open(props.href, '_blank')?.focus();
        }
      }}
    >
      <ListItem>
        <props.Icon width={ICON_SIZE} height={ICON_SIZE} />

        <span>{props.label}</span>
      </ListItem>
    </Link>
  );
};

const WorkspacesMenu: React.FC<{
  anchorData: MenuAnchorDataType;
  selectedId: number;
}> = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const selectedWorkspaces = useAppSelector(({ main }) => main.selectedWorkspaces);
  const availableWorkspaces = useAppSelector(({ main }) => main.availableWorkspaces);

  const changeWorkspace = async (company: ICompany) => {
    try {
      setIsLoading(true);
      await helpers.changeWorkspace(company.companyId, company.subdomen);
    } catch (err) {
      console.error('Failed to change workspace:', err);
      toast.error(t('errors:server.internal.unexpected'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Menu
      onClose={props.anchorData.clearAnchor}
      anchorEl={props.anchorData.anchor}
      open={props.anchorData.isAnchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {isLoading && (
        <Loader isFixed withBackdrop blockPoinerEvents />
      )}

      {selectedWorkspaces.map((companyId) => {
        const company = availableWorkspaces[companyId];
        if (!company) {
          return null;
        }
        const selected = props.selectedId === company.companyId;

        return (
          <ListItem
            key={company.companyId}
            selected={selected}
            disableRipple={selected}
            onClick={() => {
              if (selected) {
                return;
              }
              changeWorkspace(company);
            }}
          >
            <Avatar company={company} size={AVATAR_SIZE} />

            <span>{company.name}</span>
          </ListItem>
        );
      })}

      <Link to={ROUTES.choiceWorkspace.createPath('select')}>
        <ListItem>
          <AddWorkspaceIcon width={ICON_SIZE} height={ICON_SIZE} />

          <span>{t('workspace:menu.addWorkspace')}</span>
        </ListItem>
      </Link>
    </Menu>
  );
};

const DrawerContent: React.FC<{
  onClose?: () => void;
}> = (props) => {
  const currentCompanyRef = React.useRef<HTMLDivElement | null>(null);
  const currentCompany = useAppSelector(({ main }) => main.user?.company as ICompany);

  const menuAnchorData = useMenuAnchor();

  return (
    <StyledDrawerContent>
      <List>
        <ListItem onClick={menuAnchorData.setEventTargetAsAnchor} ref={currentCompanyRef}>
          <Avatar size={AVATAR_SIZE} company={currentCompany} />

          <span>{currentCompany.name}</span>
        </ListItem>

        <WorkspacesMenu
          anchorData={{ ...menuAnchorData, anchor: currentCompanyRef.current }}
          selectedId={currentCompany.companyId}
        />

        <ListItem>
          <ToggleSoundButton />
        </ListItem>

        <LinkItem
          Icon={ChatIcon}
          label={t('general:userMenu.helpDesk')}
          href={config.supportChatUrl}
          onClick={props.onClose}
        />

        <LinkItem
          Icon={LogoutIcon}
          label={t('general:userMenu.logoutAccount')}
          to={ROUTES.signIn.path}
          onClick={() => {
            props.onClose?.();
            helpers.logOut();
          }}
        />
      </List>
    </StyledDrawerContent>
  );
};

const StyledDrawerContent = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;

export default DrawerContent;
