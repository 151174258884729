import { MessageTypeENUM, type UserToChannelsType } from 'src/types/chatTypes';
import type { ChannelDetailsItemType } from '../types';

export default (data: {
  userToChannels?: UserToChannelsType[];
  currentUserId: number;
  channelDetails: ChannelDetailsItemType;
}) => {
  const lastReadMessageTimestamp = data.userToChannels?.find((i) => i.userId === data.currentUserId)?.lastViewedMessageTime;

  const firstUnreadId = lastReadMessageTimestamp ? data.channelDetails.messages
    .find((messageId) => {
      const message = data.channelDetails.channelMessagesObject[messageId];

      if (message.type === MessageTypeENUM.action) {
        return false;
      }

      return message.authorId !== data.currentUserId && +new Date(message.createdAt) > +new Date(lastReadMessageTimestamp);
    }) : null;

  const firstUnreadMessage = firstUnreadId ? data.channelDetails.channelMessagesObject[firstUnreadId] : null;

  return {
    firstUnreadId,
    firstUnreadMessage,
  };
};
