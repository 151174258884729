import type { IUser } from 'src/types';
import socket from './socket';
import { SocketEventsENUM } from './socketEvents';

export class UserUpdateNotifier {
  static onEntityUpdated(callback: (user: IUser) => void) {
    return socket.addEventHandler<{ user: IUser }>(SocketEventsENUM.userUpdate, (response) => {
      callback(response.payload.user);
    });
  }

  static onRemoved(callback: (userId: number) => void) {
    return socket.addEventHandler<unknown, { userId: number }>(SocketEventsENUM.userRemove, (response) => {
      callback(response.meta.userId);
    });
  }

  static onNewAdded(callback: (user: IUser) => void) {
    return socket.addEventHandler<{ user: IUser }>(SocketEventsENUM.userAddNew, (response) => {
      callback(response.payload.user);
    });
  }

  static onRestored(callback: (userId: number) => void) {
    return socket.addEventHandler<unknown, { userId: number }>(SocketEventsENUM.userRestore, (response) => {
      callback(response.meta.userId);
    });
  }
}
