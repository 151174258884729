import styled from 'styled-components';

const StyledArchivalChannelList = styled.div`
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    visibility: hidden;
  }

  .control-label {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    
  }

  .archival-channel-link__title {
    padding-left: 16px;
    display: flex;
    align-items: flex-start;
    gap: 6px;
    max-width: 85%;
  }

  .archival-channel-link__icon {
    width: 14px;
    height: 14px;

    align-self: center;
  }

  .archival-channel-link__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: ${({ theme }) => theme.font.weight.xlg};
  }

  .archival-channel-link__count, .channel-link__mention {
    border-radius: 15px;
    background-color: ${({ theme }) => theme.palette.tertiary.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.6875rem;
  }

  .archival-channel-link__count {
    padding: 2px 4px;
  }

  .archival-channel-link__mention {
    padding: 4px 4px;
  }
`;

export default StyledArchivalChannelList;
