import React from 'react';

import UserMenuAreaSwitcher from './UserMenuAreaSwitcher';
import UserMenuAccount from './UserMenuAccount';
import UserMenuArea from './UserMenuArea';
import UserMenuDownload from './UserMenuDownload';
import UserMenuStyle from './UserMenu.styles';

const UserMenu: React.FC<{
  setAnchor: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  anchor: HTMLDivElement | null;
  clearAnchor: () => void;
  isAnchor: boolean;
}> = (props) => {
  return (
    <UserMenuStyle
      anchorEl={props.anchor}
      open={props.isAnchor}
      onClose={props.clearAnchor}
    >
      <div>
        <div className="menu-container">
          <UserMenuAreaSwitcher setAnchor={props.setAnchor} />

          <div className="menu-controllers">
            <UserMenuAccount setAnchor={props.setAnchor} className="menu-controllers__user-actions" />
            <UserMenuArea setAnchor={props.setAnchor} className="menu-controllers__space-actions" />
          </div>
        </div>
        <div>
          <UserMenuDownload setAnchor={props.setAnchor} />
        </div >
      </div>
    </UserMenuStyle>
  );
};

export default UserMenu;
