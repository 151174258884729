import React from 'react';

type ParamsType = {
  isInitiallyOpened?: boolean;
  handleOpenStateChange?: (isOpen: boolean) => void;
};

export const useChannelsListData = (params?: ParamsType) => {
  const [isOpen, setIsOpen] = React.useState(params?.isInitiallyOpened ?? true);

  const toggleCollapse = () => setIsOpen((prev) => {
    params?.handleOpenStateChange?.(!prev);
    return !prev;
  });

  return {
    isOpen,
    toggleCollapse,
  };
};
