import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import StyledActionButtons from 'src/ui/components/ActionButtons/ActionButtons.styles';
import classnames from 'classnames';

type ActionButtonsPropsType = {
  cancelButton?: {
    handleCancel: () => void;
    cancelButtonTitle?: string;
    disabled?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
  };
  submitButton?: {
    handleSubmit: () => void;
    submitButtonTitle?: string;
    disabled?: boolean;
    color?: 'error' | 'primary';
  };
  buttonWidth?: number;
};

const ActionButtons: React.FC<ActionButtonsPropsType> = (props) => {
  const { t } = useTranslation('general');
  const defaultSubmitButtonTitle = t('confirm.yes');
  const defaultCancelButtonTitle = t('confirm.no');

  return (
    <StyledActionButtons
      buttonWidth={props.buttonWidth}
    >
      {props.cancelButton && (
        <Button
          className="customized-button"
          variant={props.cancelButton.variant || 'outlined'}
          onClick={props.cancelButton.handleCancel}
        >
          {props.cancelButton?.cancelButtonTitle || defaultCancelButtonTitle}
        </Button>
      )}
      {props.submitButton && (
        <Button
          type="submit"
          variant="contained"
          onClick={props.submitButton.handleSubmit}
          disabled={props.submitButton.disabled}
          color={props.submitButton.color || 'primary'}
          className={classnames('customized-button', { 'error-button': props.submitButton.color === 'error' })}
        >
          {props.submitButton?.submitButtonTitle || defaultSubmitButtonTitle}
        </Button>
      )}
    </StyledActionButtons>
  );
};

export default ActionButtons;
