import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MoreButton from './MoreButton';
import StyledResultsCategory from './ResultsCategory.styles';

import type { IMessage } from 'src/types';
import type { QuerySearchParamsType } from 'src/ui/pages/Chat/components/SearchResults';
import useSearchParams from 'src/utils/hooks/useSearchParams';
import { SearchResultsCategoryENUM } from 'src/types/chatTypes';
import getHighlightedChatText from 'src/ui/pages/Chat/utils/getHighlightedChatText';
import { t } from 'src/utils/getTranslation';
import { useMessageNavigate } from '../../Chat.hooks';
import MessageText from '../MessageText';
import { useGetUsersFromCollection } from 'src/utils/hooks/usersCollection/useGetUsersFromCollection';

import LottieAnimation from 'src/ui/components/LottieAnimation';

const MessageResults: React.FC<{
  messagesResults: IMessage[];
  value: string;
  isVisibleMoreButton: boolean;
  handleClose: () => void;
  isLoading?: boolean;
}> = (props) => {
  const [params] = useSearchParams<QuerySearchParamsType>();
  const { getById } = useGetUsersFromCollection();

  const { navigateToMessage } = useMessageNavigate();

  const handleMessageClick = (message: IMessage) => {
    navigateToMessage(message);
    props.handleClose();
  };

  return (
    <StyledResultsCategory>
      <Typography
        variant="h4"
        className="results__title"
      >
        {t('chat:navigation.search.messages.title')}
      </Typography>
      <Box
        padding="10px 0px"
        display="flex"
        flexDirection="column"
        gap="10px"
      >
        {props.isLoading && (
          <LottieAnimation animation="circleLoader" className="button__loader-icon" />
        )}
        {!!props.messagesResults.length && !props.isLoading && props.messagesResults.map((message, i) => {
          const textElement = getHighlightedChatText({
            highlightString: params.search?.trim(),
            messageText: message.messageText?.[0]?.text || '',
          });

          return (
            <div key={i} className="results__message" onClick={() => handleMessageClick(message)}>
              <Typography
                variant="h6"
                className="results__message-title"
              >
                {message.channel?.name || getById(message.authorId)?.fullName}
              </Typography>
              <Typography
                variant="h6"
                className="results__message-text"
              >
                <MessageText className="results__message-item">
                  {textElement}
                </MessageText>
              </Typography>
            </div>
          );
        })}

        {!props.isLoading && !props.messagesResults.length && (
          <Typography className="results__empty-state">
            {t('chat:searchResults.notFound.noResults')}
          </Typography>
        )}
      </Box>
      {props.isVisibleMoreButton && (
        <MoreButton
          text={t('chat:navigation.search.messages.showAll')}
          value={props.value}
          category={SearchResultsCategoryENUM.messages}
          handleClose={props.handleClose}
        />
      )}
    </StyledResultsCategory>
  );
};

export default MessageResults;
