import React from 'react';
import type { TooltipProps } from '@mui/material/Tooltip';

import ArrowTooltip from 'src/ui/pages/Chat/components/ArrowTooltip';

type ToolbarTooltipPropsType = {
  isDisabled?: boolean;
 } & TooltipProps;

const ToolbarTooltip: React.FC<ToolbarTooltipPropsType> = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (props.isDisabled) {
      return;
    }
    setOpen(true);
  };

  return (
    <ArrowTooltip
      {...props}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <span>{props.children}</span>

    </ArrowTooltip>
  );
};

export default ToolbarTooltip;
