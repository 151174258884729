import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export const LinkListSkeleton: React.FC<{ numberOfLinks: number }> = (props) => {
  return (
    <Box display="flex" flexDirection="column" gap="2px" marginTop="5px">
      {new Array(props.numberOfLinks).fill(null).map((i, index) => (
        <LinkSingleSkeleon key={index} />
      ))}
    </Box>
  );
};

export const LinkSingleSkeleon: React.FC = () => {
  return (
    <Skeleton
      variant="rectangular"
      animation="wave"
      width="100%"
      height="40px"
    />
  );
};
