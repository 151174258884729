import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ReactComponent as ArrowLeftImage } from 'src/ui/pages/Chat/assets/icons/arrow-left.svg';
import { ReactComponent as OptionImage } from 'src/ui/pages/Chat/assets/icons/kebab.svg';
import ChannelLink from 'src/ui/pages/Chat/components/Navigation/components/Channels/ChannelLink';
import ChatsSkeleton from 'src/ui/pages/Chat/components/Navigation/components/ChatsSkeleton';
import ChannelConfirm from 'src/ui/pages/Chat/components/ChannelsManagement/ChannelConfirm';
import StyledArchivalChannelList from 'src/ui/pages/Chat/components/Navigation/components/ArchivalChannels/ArchivalChannelList.styles';

import { ROUTES } from 'src/utils/constants';
import errorCodes from 'src/utils/errorCodes';
import chatApi from 'src/api/chatApi';
import { chatSliceActions, selectArchivalChannels } from 'src/ui/pages/Chat/store';
import { useAppDispatch, useAppSelector } from 'src/store';
import type { IChannel } from 'src/types';

type ArchivalChannelListPropType = {
  onBackPress?: () => void;
};

const ArchivalChannelList: React.FC<ArchivalChannelListPropType> = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['chat', 'errors']);
  const navigate = useNavigate();

  const archivalChannels = useAppSelector(selectArchivalChannels);

  const {
    isLoadingChannelsList,
    isShowCreatingChat,
    chatIdsWithMentions,
    currentChatInfo,
    isShowAllArchivalChannelsRestore,
    isShowArchivalChannelsDelete,
    archivalChats,
    archivalChannelsIds,
  } = useAppSelector(({ chatPage }) => chatPage);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    return;
    if (archivalChannelsIds.length) {
      const firstId = archivalChannelsIds[0];
      if (!firstId) return;
      const firstChannel = archivalChats[firstId];
      if (!firstChannel) return;
      navigate(ROUTES.chat.createPath(firstChannel.channelId));
      dispatch(chatSliceActions.setCurrentChatInfo({
        id: firstChannel.channelId,
        type: firstChannel.type,
        name: firstChannel.name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDeleteAll = () => {
    dispatch(chatSliceActions.setIsShowArchivalChannelsDelete(true));
    handleCloseMenu();
  };

  const handleOpenRestoreAll = () => {
    dispatch(chatSliceActions.setIsShowAllArchivalChannelsRestore(true));
    handleCloseMenu();
  };

  const handleCloseCreatingChat = () => {
    if (isShowCreatingChat) {
      dispatch(chatSliceActions.setIsShowCreatingChat(false));
    }
  };

  const handleClickLink = (channel: IChannel) => {
    dispatch(chatSliceActions.setMessagesLoadingStatusesIsNull());

    dispatch(chatSliceActions.setEditingChatMessage(null));
    dispatch(chatSliceActions.setReplyingChatMessage(null));
    dispatch(chatSliceActions.setChatName(channel.name));
    dispatch(chatSliceActions.setCurrentChatInfo({
      id: channel.channelId,
      type: channel.type,
      name: channel.name,
    }));
    handleCloseCreatingChat();
  };

  const setChannel = () => {
    dispatch(chatSliceActions.setEditingChatMessage(null));
    dispatch(chatSliceActions.setReplyingChatMessage(null));
    navigate(ROUTES.chat.createPath());
    dispatch(chatSliceActions.setCurrentChatInfo(null));
    dispatch(chatSliceActions.setIsVisibleArchiveChannels(false));
  };

  const handleSubmitAllArchivalChannelsRestore = async () => {
    try {
      await chatApi.changeAllChannelArchiveStatus();
      setChannel();
      archivalChannels.forEach((channel) => {
        dispatch(chatSliceActions.addChannel({ ...channel, isArchived: false }));
        dispatch(chatSliceActions.deleteArchivalChannel(channel.channelId));
      });

      handleCancelAllArchivalChannelsRestore();
      toast.success(t('chat:channels.changesSaved'));
    } catch (err) {
      toast.error(t(errorCodes.server.internalUnexpected));
    }
  };

  const handleCancelAllArchivalChannelsRestore = async () => {
    dispatch(chatSliceActions.setIsShowAllArchivalChannelsRestore(false));
  };

  const handleSubmitAllArchivalChannelsDelete = async () => {
    try {
      await chatApi.removeAllArchiveChannel();
      setChannel();
      archivalChannels.forEach((channel) => {
        dispatch(chatSliceActions.deleteArchivalChannel(channel.channelId));
      });

      handleCancelAllArchivalChannelsDelete();
      toast.success(t('chat:channels.changesSaved'));
    } catch (err) {
      toast.error(t(errorCodes.server.internalUnexpected));
    }
  };

  const handleCancelAllArchivalChannelsDelete = async () => {
    dispatch(chatSliceActions.setIsShowArchivalChannelsDelete(false));
  };

  // eslint-disable-next-line no-constant-condition
  if (true) {
    return (
      <>
        <ChannelConfirm
          isOpen={isShowAllArchivalChannelsRestore}
          submitButton={{
            handleSubmit: handleSubmitAllArchivalChannelsRestore,
            submitButtonTitle: t('chat:channels.editingChannel.buttons.restoreChannel'),
            color: 'primary',
          }}
          cancelButton={{
            handleCancel: handleCancelAllArchivalChannelsRestore,
            cancelButtonTitle: t('chat:channels.actionButtons.cancel'),
          }}
          title={(
            <div>
              <Typography
                variant="h3"
                className="title__head"
              >
                {t('chat:channels.restoreFromArchive.allArchiveTitle')}
              </Typography>

              <Typography
                variant="h5"
                className="title__prompt title__prompt_next-line"
              >
                {t('chat:channels.restoreFromArchive.allArchiveDescription')}
              </Typography>
            </div>
          )}
        />
        <ChannelConfirm
          isOpen={isShowArchivalChannelsDelete}
          submitButton={{
            handleSubmit: handleSubmitAllArchivalChannelsDelete,
            submitButtonTitle: t('chat:channels.editingChannel.buttons.delete'),
            color: 'primary',
          }}
          cancelButton={{
            handleCancel: handleCancelAllArchivalChannelsDelete,
            cancelButtonTitle: t('chat:channels.actionButtons.cancel'),
          }}
          title={(
            <div>
              <Typography
                variant="h3"
                className="title__head"
              >
                {t('chat:channels.restoreFromArchive.allArchiveDeleteTitle')}
              </Typography>

              <Typography
                variant="h5"
                className="title__prompt title__prompt_next-line"
              >
                {t('chat:channels.restoreFromArchive.allArchiveDeleteDescription')}
              </Typography>
            </div>
          )}
        />
      </>
    );
  }

  return (
    <StyledArchivalChannelList>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="8px 16px 8px 0px"
      >
        <NavLink
          onClick={props.onBackPress}
          to={ROUTES.chat.createPath()}
          className="control-label"
        >
          <ArrowLeftImage />

          <Typography
            variant="h4"
            className="archival-channel-link__text"
          >
            {t('chat:navigation.archivalChannels')}
          </Typography>
        </NavLink>

        <IconButton className="channels__option-button" onClick={handleClickAdd}>
          <OptionImage width="10px" height="10px" />
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleOpenRestoreAll}>

            {t('chat:channels.menu.restoreAll')}
          </MenuItem>

          <MenuItem onClick={handleOpenDeleteAll}>

            {t('chat:channels.menu.deleteAll')}
          </MenuItem>
        </Menu>
      </Box>

      {isLoadingChannelsList
        ? <ChatsSkeleton count={3} />
        : (
          <div>
            {archivalChannels.map((channel) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const withMention = chatIdsWithMentions.includes(channel.channelId);
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const withCount = Boolean(channel.unreadMessagesCount);
              return (
                <NavLink
                  key={channel.channelId}
                  to={ROUTES.chat.createPath(channel.channelId)}
                  onClick={() => handleClickLink(channel)}
                  className={({ isActive }) => (classnames('channels-list__link', {
                    'channels-list__link--active': isActive || currentChatInfo?.id === channel.channelId,
                    // 'channels-list__link--with-badge': withMention || withCount,
                  }))}
                >
                  <ChannelLink
                    // unreadMessagesCount={null}
                    // withMention={withMention}
                    channel={channel}
                  />
                </NavLink>
              );
            })}
          </div>
        )}
    </StyledArchivalChannelList>
  );
};

export default ArchivalChannelList;
