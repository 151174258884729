import styled from 'styled-components';

type StyledAboutMePropsType = {
  isReadMore: boolean;
};

const StyledAboutMe = styled.div<StyledAboutMePropsType>`
  .about-me__container {
    max-height: ${({ isReadMore }) => (isReadMore ? 'fit-content' : '55px')};
    overflow: hidden;
  }

  .about-me__text {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.sm};
    word-break: break-word;
    height: fit-content;
  }

  .about-me__button {
    min-width: fit-content;
    padding: 0;
  }
`;

export default StyledAboutMe;
