import type { IWorkspacesMetaStore } from './workspacesMeta';

export const updateStateOnRemove = (
  store: IWorkspacesMetaStore['unreadMessagesByCompany'][number],
  options: {
    messageId: number;
    parentMessageId?: number;
  },
) => {
  if (!options.parentMessageId) {
    store.primaryMessages = store.primaryMessages?.filter((m) => m !== options.messageId);
    return;
  }

  if (!store.threadMessages?.[options.parentMessageId]) {
    return;
  }

  store.threadMessages[options.parentMessageId] = store.threadMessages[options.parentMessageId]!.filter(
    (m) => m !== options.messageId,
  );

  if (!store.threadMessages[options.parentMessageId]?.length) {
    delete store.threadMessages[options.parentMessageId];
  }
};
