import type { useFormik } from 'formik';
import axios from 'axios';
import type { AxiosError } from 'axios';

export const checkIsAxiosError = (err: Error | unknown): err is AxiosError => {
  return axios.isAxiosError(err);
};

export const checkIsValidationError = (err: Error | unknown) => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 400;
};

export type ValidationErrorType = {
  code: 'server.validation.failed';
  data: Array<{
    path: string;
    key: string;
    code: string;
    message: string;
  }>;
  message: 'Invalid request data';
  statusCode: 400;
  type: 'validation';
};

const handleValidationError = <V extends object>(
  err: Error | unknown,
  setFieldError: ReturnType<typeof useFormik<V>>['setFieldError'],
) => {
  (err as AxiosError<ValidationErrorType>).response?.data.data.forEach((error) => {
    setFieldError(error.key, `errors:${error.code}`);
  });
};

export default handleValidationError;
