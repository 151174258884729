import styled from 'styled-components';

const StyledNavHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10.5px 16px;
  height: 57px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  white-space: nowrap;
`;

export default StyledNavHeader;
