import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { ExtranThunkArgument } from '../types';
import type { AppStateType } from '../store';
import type { ICompanyUnreadMessagesMeta } from 'src/types/workspaces';

import { updateStateOnRemove } from './workspacesMeta.helpers';

export interface IWorkspacesMetaStore {
  unreadMessagesByCompany: ICompanyUnreadMessagesMeta;
}

const initialState: IWorkspacesMetaStore = {
  unreadMessagesByCompany: {},
};

const getUnreadMessagesCount = createAsyncThunk<
  ICompanyUnreadMessagesMeta,
  undefined,
  { extra: ExtranThunkArgument; state: AppStateType }
>(
  'workspacesMeta/getUnreadMessagesCount',
  async (_, { extra, getState }) => {
    const selectedWorkspaces = getState().main.selectedWorkspaces;
    const unreadMessagesCount = await extra.api.companiesApi.getUnreadMessagesCountByCompanies({ ids: selectedWorkspaces });
    return unreadMessagesCount.data.payload;
  },
);

const workspacesMetaSlice = createSlice({
  name: 'workspacesMeta',
  initialState,
  reducers: {
    addUnreadMessages: (store, { payload }: PayloadAction<{
      companyId: number;
      messageId: number;
      parentMessageId?: number;
    }>) => {
      const currentState = store.unreadMessagesByCompany[payload.companyId];
      if (!currentState.primaryMessages) {
        currentState.primaryMessages = [];
      }
      if (!currentState.threadMessages) {
        currentState.threadMessages = {};
      }

      if (payload.parentMessageId) {
        const currentParentMessages = currentState.threadMessages[payload.parentMessageId];
        currentState.threadMessages[payload.parentMessageId] = Array.isArray(currentParentMessages)
          ? [...currentParentMessages!, payload.messageId]
          : [payload.messageId];
        return;
      }

      if (currentState.primaryMessages.includes(payload.messageId)) {
        return;
      }
      currentState.primaryMessages.push(payload.messageId);
    },
    removeUnreadMessages: (store, { payload }: PayloadAction<{
      companyId: number;
      messageId: number;
      parentMessageId?: number;
    }>) => {
      const currentState = store.unreadMessagesByCompany[payload.companyId];
      updateStateOnRemove(currentState, { messageId: payload.messageId, parentMessageId: payload.parentMessageId });
    },

    batchRemoveUnreadMessages: (store, { payload }: PayloadAction<{
      companyId: number;
      messages: {
        messageId: number;
        parentMessageId?: number;
      }[];
    }>) => {
      const currentState = store.unreadMessagesByCompany[payload.companyId];

      payload.messages.forEach(({ messageId, parentMessageId }) => {
        updateStateOnRemove(currentState, { messageId, parentMessageId });
      });
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUnreadMessagesCount.fulfilled, (store, { payload }) => {
        store.unreadMessagesByCompany = payload;
      });
  },
});

export const workspacesMetaActions = workspacesMetaSlice.actions;
export const workspacesMetaThunks = { getUnreadMessagesCount };
export default workspacesMetaSlice.reducer;
