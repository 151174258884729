import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Loupe } from 'src/ui/pages/Chat/assets/icons/loupe.svg';

import { useAppDispatch } from 'src/store';
import { chatSliceActions } from '../../../../store';
import StyledNavHeader from './NavHeader.styles';
import { ElectronAPI } from 'src/utils/electronAPI';

const NavHeader: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(chatSliceActions.setIsActiveSearchInput(true));
  };

  const { t } = useTranslation('chat');
  return (
    <StyledNavHeader>
      <Typography
        variant="h1"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {t('navigation.title')}
      </Typography>
      {!ElectronAPI.isElectronApp && (
        <IconButton onClick={handleClick}>
          <Loupe />
        </IconButton>
      )}
    </StyledNavHeader>
  );
};

export default NavHeader;
