import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from 'src/ui/assets/images/close.svg';

import ChannelNameInfo from '../ChannelNameInfo';
import { useAppDispatch } from '../../../../../store/store';
import chatThunksV2 from '../../storeV2/chatThunksV2';
import { ROUTES } from '../../../../../utils/constants';
import { useCurrentChannel } from '../../Chat.hooks';

const SidebarActionItem: React.FC<{
  title: React.ReactNode;
  navigateTo: string;
  icon: string | React.ReactNode;

  actionBadge?: React.ReactNode;
  hasBadge?: boolean;
  asPrivateAction?: boolean;
  preventActionOnActive?: boolean;

  className?: string;
  linkItemClassName?: string;
  linkItemActiveClassName?: string;

  channelId?: number;
  showCloseButton?: boolean;

  onAction?: (ev: React.MouseEvent) => void;
}> = (props) => {
  const { preventActionOnActive = true } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentChannelId = useCurrentChannel()?.channelId;
  const onCloseClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    dispatch(chatThunksV2.closeDmChannel({ channelId: props.channelId! }));
    if (currentChannelId === props.channelId) {
      navigate(ROUTES.chat.createPath());
    }
  };
  return (
    <StyledlLink
      to={props.navigateTo}
      onClick={props.onAction}
      hasBadge={!!props.hasBadge}
      className={props.className}
    >
      {({ isActive }) => (
        <ListItemButton
          className={classNames(
            'channel-container',
            props.linkItemClassName,
            isActive && props.linkItemActiveClassName,
            {
              'current-address': isActive,
            },
          )}
          onClick={(ev) => {
            if (isActive && preventActionOnActive) {
              ev.preventDefault();
            }
          }}
        >
          <ChannelNameInfo
            icon={props.icon}
            name={props.title}
            isPrivate={props.asPrivateAction}
            className="channel-data-container"
          />
          {props.actionBadge}
          {props.showCloseButton &&
            (
            <IconButton
              onClick={onCloseClick}
              className="channel-container__close-button"
              size="small"
            >
              <CloseIcon width={16} height={16} />
            </IconButton>
            )}
        </ListItemButton>
      )}
    </StyledlLink>
  );
};

export const StyledlLink = styled(NavLink) <{ hasBadge: boolean }>`
  .channel-container {
    padding: 0 16px;
    height: 38px;
    font-size: ${({ theme }) => theme.font.size.sm};
    display: flex;
    justify-content: space-between;
    gap: 12px;
    background-color: ${({ theme, hasBadge }) => (!hasBadge ? 'unset' : theme.palette.background.default)};
    color: ${({ theme, hasBadge }) => (hasBadge ? theme.palette.text.primary : theme.palette.secondary.light)};
    font-weight: ${({ theme, hasBadge }) => (hasBadge ? theme.font.weight.lg : theme.font.weight.md)};

    @media (hover: hover) {
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.default};
        .channel-container__close-button {
          display: flex;
        }
      }
    }
   
  }

  .current-address {
    background-color: ${({ theme }) => theme.palette.primary[100]};
    color: ${({ theme }) => theme.palette.primary[500]};
    font-weight: ${({ theme }) => theme.font.weight.xlg};
    border-left: 2px solid ${({ theme }) => theme.palette.primary[500]};
    padding-left: 14px;

    .group-icon__more-users-count {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .user-avatar {

  }

  .notes-icon {
    color: ${({ theme }) => theme.palette.primary.main};
    height: 26px;
  }

  .channel-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .lock-icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  .badge {
    margin: -1px 0;
  }

  .channel-data-container {
    overflow: hidden;
  }


  .channel-container__close-button {
    width: 20px;
    height: 20px;
    padding: 0;
    display: none;
  }
`;

export default SidebarActionItem;
