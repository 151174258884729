import React from 'react';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ReactComponent as CloseIcon } from 'src/ui/pages/Chat/assets/icons/close.svg';

import { t } from 'src/utils/getTranslation';

const RightBarContainer: React.FC<React.PropsWithChildren & {
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  return (
    <div className="right-container right-container--profile">
      <div className="right__header">
        <Typography
          className="right-container__title right-container--profile-title"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {t('chat:userProfile.title')}
        </Typography>
        <IconButton
          onClick={props.onClose}
          className="right-container__close-button"
        >
          <CloseIcon width="24px" height="24px" />
        </IconButton>
      </div>
      {props.children}
    </div>
  );
};

export default RightBarContainer;
