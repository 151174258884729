import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';

import { ReactComponent as LockIcon } from 'src/ui/pages/Chat/assets/icons/lock.svg';
import { DEFAULT_ICON_TEXT } from '../components/ChannelsManagement/constants';
import TextWithOptionalTooltip from 'src/ui/components/TextWithOptionalTooltip/TextWithOptionalTooltip';

type PropsType = {
  name: string | React.ReactNode;

  isPrivate?: boolean;
  icon?: string | React.ReactNode;
  skipIcon?: boolean;
  className?: string;
  iconClassName?: string;
};

const ChannelNameInfo: React.FC<PropsType> = (props) => {
  return (
    <StyledWrapper className={props.className}>
      {!props.skipIcon && (
        <div className={cn('channel-data__icon', props.iconClassName)}>
          {props.icon || DEFAULT_ICON_TEXT}
        </div>
      )}
      <TextWithOptionalTooltip
        className="channel-name"
        tooltipProps={{ placement: 'right', arrow: true }}
      >
        {props.name}
      </TextWithOptionalTooltip>
      {props.isPrivate && <LockIcon className="lock-icon" />}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .channel-data__icon {
      font-size: ${({ theme }) => theme.font.size.md};
    }

  .channel-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .lock-icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
`;

export default ChannelNameInfo;
