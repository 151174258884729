import React from 'react';
import { useTranslation } from 'react-i18next';
import HTMLParser from 'html-react-parser';

import Typography from '@mui/material/Typography';
import Avatar from 'src/ui/components/Avatar';
import StatusBadge from 'src/legacy/ui/components/StatusBadge';
import JobPosition from 'src/ui/pages/Chat/components/JobPosition';
import StyledUsersListItem from 'src/ui/pages/Chat/components/CustomMultiSelect/UserListItem/UsersListItem.styles';

import type { IUser } from 'src/types';
import useTheme from 'src/utils/hooks/useTheme';
import highlighted from 'src/utils/highlighted';
import { useUserOnlineStatus } from 'src/utils/hooks/general';
import getFullName from 'src/utils/getFullName';
import MessageText from '../../../componentsV2/MessageText';

type UserListItemPropsType = {
  user: IUser;
  handleClickListItem: (id: number) => void;
  size?: 'small' | 'middle';
  selected?: number | null;
  inChannel?: boolean;
  handleSelect?: (id: number) => void;
  searchResult?: string;
  className?: string;
};

const UserListItem: React.FC<UserListItemPropsType> = (props) => {
  const { t } = useTranslation(['chat', 'hrmEmployees']);
  const theme = useTheme();

  const isOnline = useUserOnlineStatus(props.user.userId);

  const highLightedText = React.useMemo(() => {
    const fullname = `${props.user.firstName || ''} ${props.user.lastName || ''}`;
    return props.searchResult
      ? HTMLParser(highlighted(fullname, props.searchResult)) as JSX.Element
      : undefined;
  }, [props.searchResult, props.user.firstName, props.user.lastName]);

  const jobPosition = React.useMemo(() => {
    return props.user.jobPosition?.name ||
      t('hrmEmployees:userTable.actionTools.dataNotFound');
  }, [props.user.jobPosition?.name, t]);

  return (
    <StyledUsersListItem
      id={props.user.userId.toString()}
      isSelected={!(props.selected === props.user.userId)}
      size={props.size || 'middle'}
      role="option"
      aria-selected={!(props.selected === props.user.userId)}
      tabIndex={-1}
      onClick={() => {
        props.handleClickListItem(props.user.userId);
      }}
      disabled={props.inChannel}
      isSearch={Boolean(props.searchResult)}
      className={props.className}
    >
      <Avatar
        user={props.user}
        size="sx"
        pointerCursor
        className="list-item__avatar"
      />

      {props.searchResult
        ? (
          <div className="message-item__message-text">
            <MessageText ellipsis>
              {highLightedText}
            </MessageText>
          </div>
        ) : (
          <Typography className="list-item__name">
            {getFullName(props.user)}
          </Typography>
        )}

      <StatusBadge
        isOnline={isOnline}
        customColor={{
          success: theme.palette.badge.success,
          secondary: theme.palette.badge.secondary,
        }}
      />

      <JobPosition name={jobPosition} />

      {props.inChannel && (
        <Typography className="list-item__in-channel">
          {t('chat:channels.addingUser.alreadyHere')}
        </Typography>
      )}
    </StyledUsersListItem>
  );
};

export default UserListItem;
