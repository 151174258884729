import type { IUser, IUserRole, IUserPermission, FindAndCountMetaType, UserContactsType, IProfile } from 'src/types';
import type { ResponseType } from './http';
import http from './http';
import type { GetParamsType } from './http/http.types';
import type { ChannelNotificationStatusENUM, ChannelNotificationStatusType } from 'src/types/userTypes';

const path = '/users';

export type GetUsersListParamsType = GetParamsType & {
  roles?: string;
  statuses?: string;
  sortBy?: string;
  sortDirection?: string;
};

export type CreateUserType = {
  firstName: string;
  lastName: string;
  patronymic: string;
  email?: string | null;
  isSendInvitationEmail: boolean;
  phone: string;
  sex: string;
  dateOfBirth: Date | string | null;
  jobPositionId: number;
  departmentId: number | null;
  statusInCompany: string;
  typeOfContract?: string;
  typeOfWorkingDay: string;
  workingFrom: Date | null;
  probationExpireAt?: string | null;
  managerId?: number | null;
};

export type UpdateUserDataType = {
  firstName?: string;
  lastName?: string;
  patronymic?: string | null;
  email?: string | null;
  info?: string;
  status?: string;
  dateOfBirth?: string | null;
  isSuperAdmin?: boolean;
  workingFrom?: Date | null;
  phone?: string;
  sex?: string;
  description?: string | null;
  // permissionsId?: number[];
  rolesId?: number[];
  userId?: number;
  managerId?: number | null;
  jobPositionId?: number;
  departmentId?: number | null;
  avatarMediaItemId?: number;
};

export type ChangePasswordDataType = {
  oldPassword: string;
  password: string;
};

const getList = (params?: GetUsersListParamsType) => {
  return http.get<ResponseType<IUser[], FindAndCountMetaType>>(path, { params });
};

const getRoles = (params?: GetParamsType) => {
  return http.get<ResponseType<IUserRole[]>>(`${path}/roles`, { params });
};

const getPermissions = () => {
  return http.get<ResponseType<IUserPermission[]>>(`${path}/permissions`);
};

const deleteUser = (userId: number) => {
  return http.delete<ResponseType>(`${path}/${userId}`);
};

const restoreUser = (userId: number) => {
  return http.patch<ResponseType>(`${path}/restore/${userId}`);
};

const getUser = (userId: number) => {
  return http.get<ResponseType<IUser>>(`${path}/${userId}`);
};

const getUserProfile = (userId: number) => {
  return http.get<ResponseType<IProfile>>(`${path}/profile/${userId}`);
};

const updateUser = (userId: number, data: UpdateUserDataType) => {
  return http.patch<ResponseType<IUser>>(`${path}/update/${userId}`, data);
};

const changePassword = (userId: number, data: ChangePasswordDataType) => {
  return http.patch<ResponseType>(`${path}/${userId}/change-password`, data);
};

const getContacts = (params?: GetParamsType) => {
  return http.get<ResponseType<UserContactsType[], FindAndCountMetaType>>(`${path}/contacts`, { params });
};

const createUser = (data: CreateUserType) => {
  return http.post<ResponseType<IUser>>(path, data);
};

const sendInvite = (data: { userId: number; email: string }) => {
  return http.post(`${path}/send-invite`, data);
};

const setUserToken = (data: { pushToken: string; deviceType: string }) => {
  return http.post<ResponseType>(`${path}/push-token`, data);
};

const deleteUserToken = () => {
  return http.delete<ResponseType>(`${path}/push-token`);
};

const getChannelNotificationStatuses = () => {
  return http.get<ResponseType<ChannelNotificationStatusType[]>>(`${path}/channel-notification-status`);
};

const updateChannelNotificationStatus = (data: {
  channelId: number;
  notificationStatus: ChannelNotificationStatusENUM;
}) => {
  return http.patch<ResponseType<ChannelNotificationStatusType>>(`${path}/channel-notification-status`, data);
};

export default {
  getList,
  getRoles,
  getPermissions,
  deleteUser,
  getUser,
  getUserProfile,
  updateUser,
  changePassword,
  getContacts,
  createUser,
  restoreUser,
  sendInvite,
  setUserToken,
  deleteUserToken,
  getChannelNotificationStatuses,
  updateChannelNotificationStatus,
};
