import styled from 'styled-components';

const StyledRightSideBar = styled.div`
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};

  .right-container {
    display: flex;
    overflow: hidden; 
    flex-direction: column;
    white-space: break-spaces;
    word-break: break-all;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.background.default};
  }
  
  .right__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 16px;
    background-color: ${({ theme }) => theme.palette.background.paper};
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }

  .right-container--profile {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  .right-container__title {
    font-size: 0.88888889rem;
    line-height: 18.5px;
    font-weight: ${({ theme }) => theme.font.weight.lg};
  }

  .right-container--profile-title {
    font-size: ${({ theme }) => theme.font.size.lg};
  }

  .right-container__close-button {
    padding: 0;
  }

  .user-profile__job-position {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .user-profile__fullname, .user-profile__job-position {
    max-width: 300px;
    word-break: normal;
    text-align: center;
  }

  .user-additional__title {
    color: ${({ theme }) => theme.palette.text.disabled};
  }

  .user-additional__info {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.sm};
    word-break: break-word;
  }

  .user-additional__info--scrollable {
    max-height: 70px;
    overflow: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

      &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${({ theme }) => theme.palette.divider};
    }
  }

  .user-profile__user-icon path {
    stroke: ${({ theme }) => theme.palette.primary[500]};
  }

  .user-profile__user-icon > .plus {
    stroke: ${({ theme }) => theme.palette.background.paper};
  }

  @media (max-width: ${({ theme }) => theme.screen.lg}px) {
    width: 95vw;
    max-width: 450px;
  }
`;

export default StyledRightSideBar;
