import React from 'react';
import styled from 'styled-components';

const ChatBadge: React.FC<{
  content: string | number;
  isMentionBadge?: boolean;
  className?: string;
  size?: 'sm' | 'md';
}> = (props) => {
  const badgeContent = React.useMemo(() => {
    if (props.isMentionBadge) {
      return '@';
    }
    if (typeof props.content === 'string') {
      return props.content;
    }

    if (typeof props.content === 'number') {
      return props.content <= 99 ? String(props.content) : '99+';
    }

    return props.content;
  }, [props.content, props.isMentionBadge]);

  if (!props.content && !props.isMentionBadge) {
    return null;
  }

  const size = props.size ?? 'md';

  return (
    <StyledChatBadge
      isMentionBadge={props.isMentionBadge ?? false}
      badgeLength={badgeContent.length}
      className={props.className}
      size={size}
    >
      {badgeContent}
    </StyledChatBadge>
  );
};

const sizes = {
  sm: 16,
  md: 18,
};

const fallbackFontSizes = {
  sm: '50%',
  md: '60%',
};

const StyledChatBadge = styled.div<{
  badgeLength: number;
  isMentionBadge: boolean;
  size: 'sm' | 'md';
}>`
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.tertiary.primary};
  color: ${({ theme }) => theme.palette.tertiary.contrastText};
  line-height: 20px;
  font-size: ${({ theme, badgeLength, size }) => (badgeLength > 2 ? fallbackFontSizes[size] : theme.font.size.xxs)};
  font-weight: ${({ theme }) => theme.font.weight.lg};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => sizes[size]}px;
  min-width: ${({ size }) => sizes[size]}px;
  height: ${({ size }) => sizes[size]}px;
  min-height: ${({ size }) => sizes[size]}px;
  box-sizing: border-box;
  padding-bottom: ${({ isMentionBadge }) => (isMentionBadge ? '2px' : '')};
`;

export default ChatBadge;
