import React from 'react';
import { useAppSelector } from 'src/store/store';
import { unreadMessagesCountSelector } from 'src/store/workspacesMeta/workspacesMeta.selectors';

export const useCrossWorkspacesUnreadsCount = () => {
  const user = useAppSelector(({ main }) => main.user);
  const unreadsByWorkspace = useAppSelector(unreadMessagesCountSelector);

  const haveUnreadsInWorkspaces = React.useMemo(() => {
    return Object.entries(unreadsByWorkspace).some(([id, unread]) => {
      if (+id === user!.company?.companyId) {
        return false;
      }
      return unread > 0;
    });
  }, [unreadsByWorkspace]);

  const unreadsByCompanyId = React.useMemo(() => {
    return new Map(Object.entries(unreadsByWorkspace));
  }, [unreadsByWorkspace]);

  return {
    haveUnreadsInWorkspaces,
    unreadsByCompanyId,
  };
};
