import React from 'react';

import StyledLottie from 'src/ui/components/LottieAnimation/LottieAnimation.styles';

import fusionLoader from 'src/ui/assets/lottieAnimations/fusionLoader.json';
import circleLoader from 'src/ui/assets/lottieAnimations/circleLoader.json';
import inDevelopmentPlaceholder from 'src/ui/assets/lottieAnimations/inDevelopmentPlaceholder.json';
import notFound from 'src/ui/assets/lottieAnimations/notFound.json';
import unexpectedError from 'src/ui/assets/lottieAnimations/unexpectedError.json';

const ANIMATIONS = {
  fusionLoader,
  circleLoader,
  inDevelopmentPlaceholder,
  notFound,
  unexpectedError,
};

const LottieAnimation: React.FC<{
  animation: keyof typeof ANIMATIONS;
  className?: string;
  style?: React.CSSProperties;
}> = (props) => {
  return (
    <StyledLottie
      animationData={ANIMATIONS[props.animation]}
      className={props.className}
      style={props.style}
    />
  );
};

export default LottieAnimation;
