import styled from 'styled-components';

const StyledChannelLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: ${({ theme }) => theme.palette.background.paper};

  .channel__icon {
    font-size: 0.9rem;
    align-self: center;
    line-height: 0px;
  }

  .channel-link__title {
    padding-left: 16px;
    display: flex;
    align-items: flex-start;
    gap: 6px;
    max-width: 85%;
  }

  .channel-link__icon {
    min-width: 14px;
    min-height: 14px;

    align-self: center;
  }

  .channel-link__text {
    padding-top: 2px;
    padding-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .channel-link__count, .channel-link__mention {
    border-radius: 24px;
    min-width: 10px;
    background-color: ${({ theme }) => theme.palette.tertiary.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.6875rem;
  }

  .channel-link__count {
    padding: 2px 4px;
  }

  .channel-link__mention {
    padding: 4px 4px;
  }
`;

export default StyledChannelLink;
