import React from 'react';
import styled from 'styled-components';

import Dialog, { type DialogProps } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { ReactComponent as CloseIcon } from 'src/ui/assets/images/close.svg';

export * from './GenericDialog.hooks';

type PickedPropsType = Pick<DialogProps,
  'children' | 'onAnimationEnd' | 'scroll' | 'maxWidth' | 'fullWidth'
>;

const GenericDialog: React.FC<PickedPropsType & {
  isOpen: boolean;
  title?: string;
  text?: string;
  noCloseButton?: boolean;
  onClose?: () => void;
  actionButtons?: React.ReactNode;
}> = (props) => {
  return (
    <StyledGenericDialog
      open={props.isOpen}
      onClose={props.onClose}
      scroll={props.scroll ?? 'body'}
      maxWidth={props.maxWidth ?? 'sm'}
      fullWidth={props.fullWidth ?? true}
      onAnimationEnd={props.onAnimationEnd}
    >
      <DialogTitle>
        <p className="dialog__title-text">
          {props.title}
        </p>

        {!props.noCloseButton && (
          <IconButton
            onClick={props.onClose}
            className="dialog__close-icon-button"
            size="small"
          >
            <CloseIcon width={20} height={20} />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent>
        {props.text && (
          <DialogContentText>
            {props.text}
          </DialogContentText>
        )}

        {props.children}
      </DialogContent>

      {props.actionButtons && (
        <DialogActions>
          {props.actionButtons}
        </DialogActions>
      )}
    </StyledGenericDialog>
  );
};

const StyledGenericDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background: var(--bg-mask, rgba(0, 0, 0, 0.35));
    backdrop-filter: blur(2px);
  }

  .MuiPaper-root {
    border-radius: 12px;
    border: 1px solid #E8EBEE;
    background: #FFF;
    box-shadow: none;
    padding: 32px 24px;
  }

  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 8px;
  }

  .dialog__title-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.5px;
    min-height: 1.2em;
  }

  .dialog__close-icon-button {
    margin: -13px -5px 0 0;
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .MuiDialogContentText-root {
    margin-bottom: 20px;
    color: #586581;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
  }

  .MuiDialogActions-root {
    margin-top: 20px;

    & > :not(:first-of-type) {
      margin-left: 16px;
    }
  }

  @media (max-width: ${({ theme }) => theme.screen.lg}px) {
    .MuiPaper-root {
      margin-inline: 0;
    }
  }
`;

export default GenericDialog;
