import styled, { createGlobalStyle } from 'styled-components';

export const SelectDisabler = createGlobalStyle`
  * {
    user-select: none;
  }
`;

export default styled.div<{
  togglerSize: number;
  minSize?: number;
  maxSize?: number;
  size: number;
  disabled: boolean;
}>`
  position: relative;
  z-index: 1;
  min-width: ${({ size }) => size}px;
  width: ${({ size }) => size}px;

  .resize-toggler {
    position: absolute;
    width: ${({ togglerSize }) => togglerSize}px;
    top: 0;
    bottom: 0;
    z-index: 1001;
    cursor: ${({ disabled }) => (disabled ? 'unset' : 'col-resize')};

    &--left {
      left: ${({ togglerSize }) => -(togglerSize / 2)}px;
    }

    &--right {
      right: ${({ togglerSize }) => -(togglerSize / 2)}px;
    }
  }
`;
