import React from 'react';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ResizableContainer from 'src/ui/components/ResizableContainer';
import StyledRightSideBar from './components/RightSideBar.styles';
import UserProfile from './components/UserProfile';

import { useUserProfileDrawerData, closeDrawer } from './UserProfileDrawer.hooks';
import { useTabletCheck } from 'src/utils/hooks/useMediaQueryCheck';

export {
  openDrawer as openProfileDrawer,
  closeDrawer as closeProfileDrawer,
} from './UserProfileDrawer.hooks';

const UserProfileDrawer: React.FC<{
  onOpen?: (userId: number) => void;
  onClose?: () => void;
}> = (props) => {
  const {
    userId,
  } = useUserProfileDrawerData(props);

  const isTablet = useTabletCheck();

  if (isTablet) {
    return (
      <SwipeableDrawer
        open={Boolean(userId)}
        onOpen={() => null}
        onClose={closeDrawer}
        swipeAreaWidth={0}
        anchor="right"
      >
        <StyledRightSideBar>
          {userId && (
            <UserProfile
              userId={userId}
              onClose={closeDrawer}
            />
          )}
        </StyledRightSideBar>
      </SwipeableDrawer>
    );
  }

  if (!userId) {
    return null;
  }

  return (
    <ResizableContainer
      size={312}
      minSize={312}
      variant="left"
      limitByParentScroll
    >
      <StyledRightSideBar>
        <UserProfile
          userId={userId}
          onClose={closeDrawer}
        />
      </StyledRightSideBar>
    </ResizableContainer>
  );
};

export default UserProfileDrawer;
