import styled from 'styled-components';

import Button from '@mui/material/Button';

export default styled(Button)`
  border-radius: 6px;
  height: 34px;
  padding: 10px 16px;
  font-size: 14px;

  .button__loader-icon {
    height: 1em;
    width: 1em;
  }
`;
