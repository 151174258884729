import React from 'react';
import styled from 'styled-components';

import MuiIconButton, { type IconButtonProps } from '@mui/material/IconButton';

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps & {
  /** Default value - `"permanent"` */
  variant?: IconButtonVariantType;
}>((props, ref) => {
  return (
    <StyledIconButton
      {...props}
      variant={props.variant || 'permanent'}
      ref={ref}
    >
      {props.children}
    </StyledIconButton>
  );
});

export type IconButtonVariantType = 'permanent' | 'hoverable';

export const StyledIconButton = styled(MuiIconButton) <{ variant: IconButtonVariantType }>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 5px;
  background-color: ${({ variant, theme }) => (variant === 'hoverable' ? 'transparent' : theme.palette.white.A2)};
`;

export default IconButton;
