import React from 'react';

import RequestNotificationPermissionModal from './components/RequestNotificationPermissionModal';

import { usePushNotificationsData } from './PushNotifications.hooks';
import Loader from 'src/ui/components/Loader/Loader';

const RemoteServiceNotifications: React.FC = () => {
  const {
    isRequestModalOpen,
    handleAcceptNotifications,
    handleRejectNotifications,
    isActivating,
  } = usePushNotificationsData();

  return (
    <>
      {isActivating && (
        <Loader
          isFixed
          blockPoinerEvents
          withBackdrop
        />
      )}

      <RequestNotificationPermissionModal
        isOpen={isRequestModalOpen}
        onAccept={handleAcceptNotifications}
        onClose={handleRejectNotifications}
      />
    </>
  );
};

export default RemoteServiceNotifications;
