import React from 'react';

import { historyChangeEvent } from './extendHistoryChanges';

type LocationHistoryRecordType = {
  path: string;
  pathWithSearchParams: string;
  key: string | null;
};

export interface HistoryItemType extends Record<string, unknown> {
  key: string | null;
}

const getCurrentLocation = (): LocationHistoryRecordType => {
  const path = window.location.pathname;
  return {
    path,
    pathWithSearchParams: `${path}${window.location.search}`,
    key: window.history.state?.key ?? null,
  };
};

const getInitialLocations = () => [getCurrentLocation()];
let history = getInitialLocations();

historyChangeEvent.subscribe((ev) => {
  const newHistory = [...history];

  // eslint-disable-next-line default-case
  switch (ev.eventType) {
    case 'push':
      newHistory.push(getCurrentLocation());
      break;

    case 'replace':
      newHistory.splice(newHistory.length - 1, 1, getCurrentLocation());
      break;
  }

  history = newHistory;
});

const useLocationHistory = () => {
  const [historyState, setHistoryState] = React.useState(history);

  React.useEffect(() => {
    return historyChangeEvent.subscribe(() => {
      setHistoryState([...history]);
    });
  }, []);

  console.info('history:', historyState);

  return historyState;
};

export default useLocationHistory;
