import type { SerializedError } from '@reduxjs/toolkit';
import type { ChannelMetaDataType, IChannel, IMessage, UserToChannelsType, MediaItemType } from 'src/types';

import {
  type PendingMessagesType,
} from './chatSliceUtils';

import type { UserToThreadType } from 'src/types/chatTypes';
import type { ChannelNotificationStatusType } from 'src/types/userTypes';

export enum LoadingSatusENUM {
  idle = 'IDLE',
  pending = 'PENDING',
  success = 'SUCCESS',
  error = 'ERROR',
}

export type MediaStoreInnerItemKeyType = 'channelInput' | 'threadInput' | number;
export type MediaStoreType = {
  [channelId: number]: {
    [key in MediaStoreInnerItemKeyType]: {
      channelId: number;
      parentMessageId: number | null;
      messageId?: number;
      files: MediaItemType[];
    };
  };
};

export interface IChildMessagesShortDetails {
  messageId: number;
  createdAt: string | Date;
  authorId?: number;
}

export interface IChannelMessagesObjType extends Omit<IMessage, 'childMessages'> {
  childMessages?: IChildMessagesShortDetails[];
}

export type ChannelDetailsItemType = {
  channelId: number;
  usersToChannel: Omit<UserToChannelsType, 'channel'>[];
  messages: number[];
  pendingMessages: PendingMessagesType;
  channelMessagesObject: Record<string, IMessage>;
  /** Array of message IDs */
  pinnedMessages: IMessage[];
  pinnedMessagesIds: number[];
  pinnedMessagesLoadingStatus: LoadingSatusENUM;

  hasMoreUp: boolean;
  isLoadingUp: boolean;
  hasMoreDown: boolean | null;
  isLoadingDown: boolean;
  firstUnreadMessageId: number | null;
  lastReadMessageTimestamp: Date | string | null;
  isPartiallyLoaded?: boolean;
  markedAsDirty?: boolean;
  status?: 'pending_init' | 'initialized';
};

export type ChannelOpenedThreadType = {
  channelId: number | null;
  parentMessageId: number | null;
  isLoading: boolean;
};

export type ChannelThreadItemType = {
  parentMessageId: number;
  channelId: number;
  userToThreads: UserToThreadType[];
  messages: number[];
  messagesObject: Record<string, IMessage>;
  pendingMessages: PendingMessagesType;
  firstUnreadMessageId: number | null;
};

export interface IChatStore {
  channelsObject: Record<string, IChannel>;
  channels: number[];
  dmChannels: number[];
  archivedChannels: number[];
  channelsMeta: Record<string, ChannelMetaDataType>;
  isChannelsLoading: boolean;
  getMyChannelsError: SerializedError | null;

  openedThread: ChannelOpenedThreadType;

  mediaItems: MediaStoreType;
  downloadingMediaItemIdsObj: Record<number, boolean>;

  channelDetailsObject: {
    [channelId: number]: ChannelDetailsItemType;
  };

  threads: {
    [parentMessageId: number]: ChannelThreadItemType;
  };
  threadsChannelMessages: { messageId: number; channelId: number }[];
  threadsUnreadCount: number;
  newThreadsMessagesInQueue: number[];

  channelNotificationStatuses: Record<number, ChannelNotificationStatusType>;

  selectedChannelId: number | null;
}
