import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StyledAboutMe from 'src/ui/pages/Chat/components/UserProfile/AboutMe.styles';
import useElementHeight from 'src/utils/hooks/useElementHeight';

type AboutMePropsType = {
  info: string;
};

const MIN_HEIGHT = 55;

const AboutMe: React.FC<AboutMePropsType> = (props) => {
  const { t } = useTranslation('chat');
  const [isReadMore, setIsReadMore] = React.useState(false);
  const textElementRef = React.useRef<HTMLDivElement | null>(null);
  const textHeight = useElementHeight(textElementRef);
  const needButton = textHeight > MIN_HEIGHT;
  const toggleShowInfo = () => {
    setIsReadMore(!isReadMore);
  };

  const buttonTitle = isReadMore ? 'userProfile.collapse' : 'userProfile.more';

  return (
    <StyledAboutMe isReadMore={isReadMore}>
      <div className="about-me__container">
        <Typography className="about-me__text" ref={textElementRef}>
          {props.info}
        </Typography>
      </div>
      {needButton && (
        <Button
          className="about-me__button"
          onClick={toggleShowInfo}
        >
          {t(buttonTitle)}
        </Button>
      )}
    </StyledAboutMe>
  );
};

export default AboutMe;
