import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import type { HistoryItemType } from '../Router/useLocationHistory';
import useLocationHistory from '../Router/useLocationHistory';
import { ReactComponent as LeftChevron } from 'src/ui/assets/images/left-chevron.svg';

type Props = {
  className?: string;
};

const TitleBarNavigation: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const history = useLocationHistory();
  const { palette } = useTheme();

  const compareHistoryStates = (historyA?: HistoryItemType, historyB?: HistoryItemType) => {
    if (!historyA?.key || !historyB?.key) {
      return false;
    }
    return historyA.key === historyB.key;
  };

  const cantGoBack = compareHistoryStates(history[0], window.history.state);
  const cantGoForward = compareHistoryStates(window.history.state, history[history.length - 1]);

  const onNextPage = () => {
    if (cantGoForward) {
      return;
    }
    navigate(1);
  };

  const onPrevPage = () => {
    if (cantGoBack) {
      return;
    }
    navigate(-1);
  };

  return (
    <StyledWrapper className={props.className}>
      <LeftChevron
        stroke={cantGoBack ? palette.grey[400] : palette.secondary.light}
        className="arrow-icon"
        onClick={onPrevPage}
      />
      <LeftChevron
        stroke={cantGoForward ? palette.grey[400] : palette.secondary.light}
        className="arrow-icon rotate-180"
        onClick={onNextPage}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .rotate-180 {
    transform: rotate(180deg);
  }

  .arrow-icon {
    cursor: pointer;
    scale: 0.8;
    padding: 3px;
    :hover {
      background-color: ${(p) => p.theme.palette.primary[200]};
      border-radius: 4px;
    }
  }
`;

export default TitleBarNavigation;
