import React from 'react';
import { crossWorkspaceWs } from 'src/api/ws/crossWorkspaceWs';
import getStore from 'src/store/getStore';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { workspacesMetaActions } from 'src/store/workspacesMeta/workspacesMeta';

export const useCrossWorkspaceWsEvents = () => {
  const isSocketConnected = useAppSelector((state) => state.main.socketData.isConnected);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!isSocketConnected) {
      return;
    }
    const unSubscribeFns = [
      crossWorkspaceWs.subscribeOnNewMessage((data) => {
        const activeCompany = getStore().main.user?.company?.companyId;
        if (activeCompany === data.companyId) {
          return;
        }
        dispatch(workspacesMetaActions.addUnreadMessages({
          companyId: data.companyId,
          messageId: data.messageId,
          parentMessageId: data.parentMessageId,
        }));
      }),

      crossWorkspaceWs.subscribeOnDeleteMessage((data) => {
        const activeCompany = getStore().main.user?.company?.companyId;
        if (activeCompany === data.companyId) {
          return;
        }
        dispatch(workspacesMetaActions.removeUnreadMessages({
          companyId: data.companyId,
          messageId: data.messageId,
          parentMessageId: data.parentMessageId,
        }));
      }),
    ];

    return () => {
      unSubscribeFns.forEach((fn) => fn());
    };
  }, [isSocketConnected]);
};
