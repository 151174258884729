import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import StyledCustomDialog from 'src/ui/components/CustomDialog/CustomDialog.styles';
import images from 'src/ui/assets/images';

type CustomDialogPropsType = {
  isOpen: boolean;
  handleCancel: () => void;
  withCloseButton?: boolean;
  className?: string;
  title?: React.ReactNode;
  scroll?: 'body' | 'paper';
  onAnimationEnd?: React.AnimationEventHandler<HTMLDivElement>;
} & React.PropsWithChildren;

const CustomDialog: React.FC<CustomDialogPropsType> = (props) => {
  const { t } = useTranslation('general');
  const defaultTitle = t('confirm.title');

  const { withCloseButton = true } = props;

  return (
    <StyledCustomDialog
      fullWidth
      open={props.isOpen}
      onClose={props.handleCancel}
      scroll={props.scroll || 'body'}
      classes={{
        paper: 'dialog__paper',
      }}
      className={props.className}
      onAnimationEnd={props.onAnimationEnd}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box maxWidth="100%">
          {props.title || defaultTitle}
        </Box>

        {withCloseButton && (
          <IconButton
            size="small"
            onClick={props.handleCancel}
            className="dialog__close-icon"
          >
            <images.CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>

      <DialogContent
        classes={{
          root: 'dialog__content',
        }}
      >
        {props.children}
      </DialogContent>
    </StyledCustomDialog>
  );
};

export default CustomDialog;
