import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUser } from './general';
import chatRouter from '../../ui/pages/Chat/utils/router.utils';
import { ElectronAPI } from '../electronAPI';

const useNotificationClick = () => {
  const location = useLocation();
  const user = useUser();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (ElectronAPI.isElectronApp) {
      return;
    }
    const onNotificationClick = (event: MessageEvent<{
      type: string;
      channelId: string;
      messageId?: string;
      parentMessageId?: string;
    }>) => {
      if (event.data.type === 'NOTIFICATION_CLICKED') {
        // eslint-disable-next-line no-console
        console.log('NOTIFICATION LOG -------> CLICK HANDLER DATA', event.data);

        const chatUrl = chatRouter.getChatUrl({
          channelId: event.data.channelId,
          messageId: event.data.messageId,
          parentMessageId: event.data.parentMessageId,
        });

        if (user && !chatRouter.isSamePath(location.pathname, chatUrl)) {
          navigate(chatUrl, { replace: true });
        }
      }
    };
    navigator.serviceWorker?.addEventListener('message', onNotificationClick);

    return () => {
      navigator.serviceWorker?.removeEventListener('message', onNotificationClick);
    };
  }, [location, user]);
};

export default useNotificationClick;
