import React from 'react';
import { ElectronAPI } from '../electronAPI';
import { useAppDispatch } from 'src/store/store';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';

const TIMEOUT_INTERVAL = 60_000;
const APPROXIMATE_DELAY_TIME = 4_000;

/* NOTE: This hook is needed to catch the sleep mode on the device
and restart the app at the first focus after exiting the sleep mode */
const useWindowReloadAfterSleep = () => {
  const lastWakeTime = React.useRef(Date.now());
  const dispatch = useAppDispatch();
  const handleCheckIsLastWakeTimeOutOfEdge = () => {
    const currentTime = new Date().getTime();
    return currentTime > (lastWakeTime.current + TIMEOUT_INTERVAL + APPROXIMATE_DELAY_TIME);
  };

  const refreshHanlder = () => {
    dispatch(mainSliceActions.revalidateInitialAppData());
  };
  const handleRefreshOnLostResources = () => {
    if (handleCheckIsLastWakeTimeOutOfEdge()) {
      refreshHanlder();
    }
  };

  React.useEffect(() => {
    if (ElectronAPI.isElectronApp) {
      return ElectronAPI.events.onAppResume(refreshHanlder);
    }
    window.addEventListener('focus', handleRefreshOnLostResources);
    return () => {
      window.removeEventListener('focus', handleRefreshOnLostResources);
    };
  }, []);

  React.useEffect(() => {
    if (ElectronAPI.isElectronApp) {
      return;
    }
    const intervalID = setInterval(() => {
      if (!handleCheckIsLastWakeTimeOutOfEdge()) {
        lastWakeTime.current = Date.now();
      }
    }, TIMEOUT_INTERVAL);

    return () => clearInterval(intervalID);
  }, []);
};

export default useWindowReloadAfterSleep;
