import { captureException } from 'src/ui/containers/ErrorBoundary';
import * as firebaseApp from '@firebase/app';
import * as firebaseMessaging from '@firebase/messaging';
import userApi from 'src/api/userApi';
import axios from 'axios';
import config from 'src/config';

export class NotificationController {
  private initialiazationPromise: Promise<void> | undefined;
  private isActive = false;

  constructor(private serviceName: string) {
    this.init();
  }

  get isNotificationEnabled() {
    return 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
  }

  init() {
    if (!this.isNotificationEnabled) {
      return;
    }
    if (this.initialiazationPromise !== undefined) {
      return this.initialiazationPromise;
    }
    this.initialiazationPromise = (async () => {
      if (Notification.permission === 'denied' || this.isActive) {
        return;
      }
      try {
        const firebaseConfig = await fetch('/firebaseConfig.json').then((res) => res.json());
        firebaseApp.initializeApp(firebaseConfig);
        this.isActive = true;
      } catch (err) {
        if (/JSON.parse:/.test((err as Error)?.message)) {
          return console.warn('Invalid Firebase config');
        }
        console.error('Failed to initialize firebase:', err);
        captureException({
          error: err as Error,
          tags: {
            location: 'firebase initialization',
          },
        });
      }
    })();
    return this.initialiazationPromise;
  }

  async setUserNotificationsToken() {
    try {
      await this.initialiazationPromise;

      if (!this.isActive) {
        return;
      }

      const messaging = firebaseMessaging.getMessaging();
      const token = await firebaseMessaging.getToken(messaging, { vapidKey: config.messagingVapidKey });

      await userApi.setUserToken({ pushToken: token, deviceType: 'browser' });
    } catch (err) {
      if (
        (axios.isAxiosError(err) && err.response?.status === 401) ||
        (err as Error).name === 'FirebaseError'
      ) {
        return;
      }

      console.error('Failed to set user token:', err);
      captureException({
        error: err as Error,
        tags: {
          location: 'set user token',
        },
      });
    }
  }

  async subscribe(callback: (payload: firebaseMessaging.MessagePayload) => unknown) {
    await this.initialiazationPromise;

    if (!this.isActive) {
      return;
    }
    const unsubscribe = firebaseMessaging.onMessage(firebaseMessaging.getMessaging(), callback);

    return unsubscribe;
  }

  sendMessageToSW(type: string, payload: Record<string, unknown>) {
    navigator.serviceWorker.getRegistrations()
      .then((registrations) => registrations.find((sw) => sw.scope.includes(this.serviceName)))
      .then((serviceWorker) => {
        serviceWorker?.active?.postMessage(JSON.stringify({ type, data: payload }));
      })
      .catch(() => {
        console.warn('Failed to send message to SW');
      });
  }
}
