import React from 'react';
import type { IUser } from 'src/types/userTypes';
import Avatar from 'src/ui/components/Avatar/Avatar';
import styled from 'styled-components';

type PropsType = {
  count: number;
  userToShowAsPreview?: IUser;
};

const GroupItemIcon: React.FC<PropsType> = (props) => {
  return (
    <StyledGroupAvatar>
      <Avatar
        user={props.userToShowAsPreview}
        size="xsx"
        className="user-avatar"
      />

      <div className="group-icon__more-users-count">
        {props.count}
      </div>
    </StyledGroupAvatar>
  );
};

const StyledGroupAvatar = styled.div`
  display: flex;
  position: relative;

  .group-icon__more-users-count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    --size: 14px;
    width: var(--size);
    height: var(--size);
    box-sizing: border-box;

    white-space: nowrap;
    background-color: #586581;
    border: 1px solid #FFFFFF;
    border-radius: 100%;

    color: #FFF;
    font-size: 10px;
    font-weight: 800;
    line-height: 10px;
  }
`;

export default GroupItemIcon;
