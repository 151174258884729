import { useEffect, useRef } from 'react';

export const useDidUpdate = <C extends () => void, D extends unknown[]>(cb: C, dependecy: D) => {
  const isInitial = useRef(true);

  useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false;
      return;
    }
    return cb();
  }, dependecy);
};
