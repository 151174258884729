import React from 'react';
import { useAppSelector } from 'src/store/store';
import type { IUser } from 'src/types';

export const useGetUsersFromCollection = () => {
  const { users, status } = useAppSelector((state) => state.usersCollection);

  const getUserListByIds = React.useCallback(<A extends { userId: number }>(arrayWithIds: A[]) => {
    return arrayWithIds.map((user) => users.entities[user?.userId]).filter((user) => !!user) as IUser[];
  }, [users.entities]);

  const getById = React.useCallback((id?: number) => {
    if (!id) { return; }
    return users.entities[id];
  }, [users.entities]);

  return {
    status,
    getUserListByIds,
    getById,
  };
};
