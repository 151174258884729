import { t } from 'src/utils/getTranslation';
import { useSoundController } from 'src/utils/hooks/useSoundController';
import useMenuAnchor from 'src/utils/hooks/useMenuAnchor';

import soundOn from '../assets/soundOn.svg';
import soundOff from '../assets/soundOff.svg';

import MenuItem from './MenuItem';

const ToggleSoundButton = () => {
  const { toggleSoundSetting, isSoundSettingOn } = useSoundController();
  const { setAnchor } = useMenuAnchor<HTMLDivElement>();

  return (
    <MenuItem
      setAnchor={setAnchor}
      onClick={toggleSoundSetting}
      menuPunktIcon={isSoundSettingOn ? soundOn : soundOff}
      menuPunktTitle={isSoundSettingOn
        ? t('general:settings.soundOff')
        : t('general:settings.soundOn')
      }
    />
  );
};

export default ToggleSoundButton;
