import type { DefaultRootState } from 'react-redux';

import storeChangeEvent from './storeChangeEvent';

let cachedStore: DefaultRootState;

storeChangeEvent.subscribe((store) => {
  cachedStore = store;
});

export const getStore = () => cachedStore;

export default getStore;
