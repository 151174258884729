import CustomEventCreator from 'src/utils/CustomEventCreator';

export type HistoryChangeDateType = {
  eventType: 'back' | 'push' | 'replace' | 'popstate';
};
export const historyChangeEvent = new CustomEventCreator<HistoryChangeDateType>({ eventName: 'CUSTOM_HISTORY_CHANGE' });

window.history.back = new Proxy(window.history.back, {
  apply(target, thisArg) {
    target.apply(thisArg);

    historyChangeEvent.dispatch({ eventType: 'back' });
  },
});

window.history.pushState = new Proxy(window.history.pushState, {
  apply(target, thisArg, argList: Parameters<typeof window.history.pushState>) {
    target.apply(thisArg, argList);

    historyChangeEvent.dispatch({ eventType: 'push' });
  },
});

window.history.replaceState = new Proxy(window.history.replaceState, {
  apply(target, thisArg, argList: Parameters<typeof window.history.replaceState>) {
    target.apply(thisArg, argList);

    historyChangeEvent.dispatch({ eventType: 'replace' });
  },
});

window.addEventListener('popstate', () => {
  historyChangeEvent.dispatch({ eventType: 'popstate' });
});
