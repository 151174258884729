import React from 'react';

import { chatDrafts } from 'src/ui/pages/Chat/Chat.utils';
import { useUser } from './general';

const useIsDraftInChannel = (channelId: number) => {
  const currentUserId = useUser().userId;
  const chatDraftsData = chatDrafts.channel.get(channelId, currentUserId);
  const text = chatDraftsData?.text || '';
  const mediaItems = chatDraftsData?.mediaItems;

  return React.useMemo(() => {
    const hasTextDraft = text.replace(/<.*?>/g, '').length > 0;
    const hasMediaDraft = mediaItems ? mediaItems.length > 0 : false;

    return hasTextDraft || hasMediaDraft;
  }, [text, mediaItems]);
};

export default useIsDraftInChannel;
