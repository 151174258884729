import type { IMessage, UserToThreadType } from 'src/types';
import type { SpecialMentionTypeENUM } from '../../types/quillTypes';
import dayjs from 'dayjs';

export const prepareTreadUsersByIds = (
  ids: (number | SpecialMentionTypeENUM)[],
  message: IMessage,
  me?: boolean,
): UserToThreadType[] => {
  return ids
    .filter((id): id is number => typeof id === 'number')
    .map((id): UserToThreadType => ({
      lastViewedMessageTime: me
        ? dayjs(message.createdAt).add(1, 'millisecond').toISOString()
        : dayjs(message.createdAt).subtract(1, 'millisecond').toISOString(),
      rootMessageId: message.parentMessage?.messageId ? message.parentMessage.messageId : 0,
      userId: id,
      userThreadId: message.messageId,
    }))
    .filter((user) => Boolean(user.rootMessageId));
};
