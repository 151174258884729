import { useNetworkOnlineInfo } from 'src/utils/hooks/useSocketConnect';
import React from 'react';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import generalWs from 'src/api/ws/generalWs';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';
import { useUser } from './general';

const useUsersOnlineUpdate = () => {
  const dispatch = useAppDispatch();
  const isSocketConnected = useAppSelector((state) => state.main.socketData.isConnected);
  const isOnline = useNetworkOnlineInfo();
  const user = useUser();

  React.useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      try {
        const onlineUsersList = await generalWs.getOnlineUsers();

        dispatch(mainSliceActions.setOnlineUsers(onlineUsersList));
      } catch (err) {
        console.error('Failed to get online users:', err);
        // Show toast
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isOnline]);

  React.useEffect(() => {
    return generalWs.subscribeOnNewOnlineUsers((userId) => {
      dispatch(mainSliceActions.addOnlineUser(userId));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocketConnected]);

  React.useEffect(() => {
    return generalWs.subscribeOnNewOfflineUsers((userId) => {
      dispatch(mainSliceActions.removeOnlineUser(userId));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocketConnected]);
};

export default useUsersOnlineUpdate;
