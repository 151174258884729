import HTMLParser from 'html-react-parser';

import highlighted from 'src/utils/highlighted';

const getHighlightedChatText = (options: {
  highlightString: string;
  messageText: string;
}) => {
  return HTMLParser(highlighted(options.messageText, options.highlightString)) as JSX.Element;
};

export default getHighlightedChatText;
