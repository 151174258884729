import React from 'react';

import NavHeader from '../../components/Navigation/components/NavHeader'; // Replace later
import Search from '../Search'; // Replace later

import { useAppSelector } from 'src/store';
import { ElectronAPI } from 'src/utils/electronAPI';

const ChatSidebarHeader: React.FC = () => {
  // This component should be fully refactored
  const isActiveSearchInput = useAppSelector(({ chatPage }) => chatPage.isActiveSearchInput);

  if (isActiveSearchInput && !ElectronAPI.isElectronApp) {
    return <Search />;
  }

  return <NavHeader />;
};

export default ChatSidebarHeader;
