// import firebaseConfigJSON from '../firebaseConfig.json';

import { ElectronAPI } from './utils/electronAPI';

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const firebaseConfigJSON = require('../firebaseConfig.json');

export enum EnvTypeENUM {
  /** Local development */
  development = 'development',
  /** Dev server */
  dev = 'dev',
  staging = 'staging',
  demo = 'demo',
  production = 'production',
}

export const envType = process.env.REACT_APP_ENV || process.env.NODE_ENV || EnvTypeENUM.development;
export const isDev = envType === EnvTypeENUM.development || envType === EnvTypeENUM.dev;
export const isStage = envType === EnvTypeENUM.staging;
export const isDemo = envType === EnvTypeENUM.demo;
export const isProd = envType === EnvTypeENUM.production;

export const isPWA = window.matchMedia('(display-mode: standalone)').matches || ElectronAPI.isElectronApp;
export const isLocalPWA = isPWA && envType === EnvTypeENUM.development;

const config = {
  apiUrl: 'http://api.localhost.com/api/v1',
  socketUrl: 'ws://api.localhost.com',
  maxRequestNumberOfAttempts: 100,
  protocol: 'http://',
  domain: 'localhost.com',
  cookieDomain: 'localhost.com',
  supportChatUrl: 'https://t.me/sphera_support_bot',
  sentryDsn: 'https://5e4368b46fdf6d89f7ada109d8d5b4ca@o1272510.ingest.sentry.io/4506383123677184',
  shouldAddEnvNameToStorageKey: true,
  isSubdomainsEnabled: !window.matchMedia('(display-mode: standalone)').matches && !ElectronAPI.isElectronApp,
  messagingVapidKey: 'BBPSQcP0-lcA__U5Tfbf2APhdnW2QicVf_yhpJMkQO7zhPr6W4N8CCQzAF47LZFJqmjUqmWQlXaRebNbN1dT6Hg',
};

const NGROK_TEST = false;
if (NGROK_TEST) {
  const webAppSubdomain = '';
  const apiSubdomain = '';
  config.apiUrl = `https://${apiSubdomain}.ngrok-free.app/api/v1`;
  config.socketUrl = `wss://${apiSubdomain}.ngrok-free.app`;
  config.protocol = 'https://';
  config.domain = `${webAppSubdomain}.ngrok-free.app`;
  config.cookieDomain = `${webAppSubdomain}.ngrok-free.app`;
  config.isSubdomainsEnabled = false;
}

if (isLocalPWA) {
  config.apiUrl = 'http://localhost:4000/api/v1';
  config.socketUrl = 'ws://localhost:4000';
  config.isSubdomainsEnabled = false;
  config.domain = 'localhost:3000';
  config.cookieDomain = 'localhost:3000';
}

// eslint-disable-next-line default-case
switch (envType) {
  case EnvTypeENUM.dev:
    config.apiUrl = 'https://api.dev.sphera.work/api/v1';
    config.socketUrl = 'wss://api.dev.sphera.work';
    config.protocol = 'https://';
    config.domain = 'dev.sphera.work';
    config.cookieDomain = 'dev.sphera.work';
    break;

  case EnvTypeENUM.staging:
    config.apiUrl = 'https://api.staging.sphera.work/api/v1';
    config.socketUrl = 'wss://api.staging.sphera.work';
    config.protocol = 'https://';
    config.domain = 'staging.sphera.work';
    config.cookieDomain = 'staging.sphera.work';
    break;

  case EnvTypeENUM.demo:
    config.apiUrl = 'https://api.demo.sphera.work/api/v1';
    config.socketUrl = 'wss://api.demo.sphera.work';
    config.protocol = 'https://';
    config.domain = 'demo.sphera.work';
    config.cookieDomain = 'demo.sphera.work';
    break;

  case EnvTypeENUM.production:
    config.apiUrl = 'https://api.sphera.work/api/v1';
    config.socketUrl = 'wss://api.sphera.work';
    config.protocol = 'https://';
    config.domain = 'sphera.work';
    config.cookieDomain = 'sphera.work';
    config.shouldAddEnvNameToStorageKey = false;
    break;
}

export default config;
