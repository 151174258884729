import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type SavedFilesStateType = {
  localPathsByChannel: Record<number | string, Record<number | string, string>>;
};

export const getInitialSavedPathsState = (): SavedFilesStateType => ({
  localPathsByChannel: {},
} as SavedFilesStateType);

const savedPathsSlice = createSlice({
  name: 'savedPaths',
  initialState: getInitialSavedPathsState,
  reducers: {
    addPath(store, { payload }: PayloadAction<{ channelId: number; mediaItemId: number | string; path: string }>) {
      if (!store.localPathsByChannel[payload.channelId]) {
        store.localPathsByChannel[payload.channelId] = {};
      }
      store.localPathsByChannel[payload.channelId][payload.mediaItemId] = payload.path;
    },
  },
});

export const savedPathsActions = savedPathsSlice.actions;
export const savedPathsReducer = savedPathsSlice.reducer;
