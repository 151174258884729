import React from 'react';

import type { TooltipProps } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import StyledArrowTooltip from './ArrowTooltip.styles';

import { useTabletCheck } from 'src/utils/hooks/useMediaQueryCheck';

const ArrowTooltip: React.FC<TooltipProps> = (props) => {
  const isTablet = useTabletCheck();

  const [isTooltipOpened, setTooltipOpened] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpened(false);
  };

  const handleTooltipToggle = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    /*
     * Check if "show tooltip" is controlled from the parent component,
     * then skip inner tooltip logic.
     * pay attention on "open" prop from parent element.
     */
    if (props.open !== undefined) {
      return;
    }

    setTooltipOpened(!isTooltipOpened);

    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <StyledArrowTooltip
          arrow
          disableFocusListener={isTablet}
          disableHoverListener={isTablet}
          disableTouchListener={isTablet}
          open={isTablet ? isTooltipOpened : undefined}
          placement="top"
          onClose={handleTooltipClose}
          {...props}
        >
          <span onClick={handleTooltipToggle}>{props.children}</span>
        </StyledArrowTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ArrowTooltip;
