import type { IMessage } from 'src/types';
import type { IChildMessagesShortDetails } from '../types';

export default (message: IMessage): IChildMessagesShortDetails => {
  return {
    messageId: message.messageId,
    createdAt: message.createdAt,
    authorId: message.authorId,
  };
};
