import styled from 'styled-components';

export default styled.div`
  padding: 12px 8px;
  border-right: 1px solid ${({ theme }) => theme.palette.white.A1};;
  background-color: ${({ theme }) => theme.palette.background.default}; 
  border-top-left-radius: 16px;
  display: flex;
  flex-direction: column;
  .workspace-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1px;
    
    ::-webkit-scrollbar {
      width: 5px; 
      height: 0;
    }
  }

  .add-workspace {
    align-self: center;
    cursor: pointer;
  }

  .add-workspace-icon {
    width: 36px;
    height: 36px;
  }
`;
