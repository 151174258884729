import React from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import Avatar, { type AvatarPropsType } from 'src/ui/components/Avatar';
import WorkspaceItemStyle from './WorkspaceItem.styles';

import type { IWorkspace } from 'src/types';
import { checkIsAxiosError, checkIsValidationError } from 'src/utils/handleValidationError';
import helpers from 'src/utils/helpers';
import { useWorkspacesList } from 'src/utils/hooks/general';
import { t } from 'src/utils/getTranslation';
import { useAppSelector } from 'src/store/store';
import ChatBadge from 'src/ui/pages/Chat/componentsV2/ChatBadge';

const WorkspaceItem: React.FC<{
  workspaceItem: IWorkspace;
  setAnchor: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  className?: string;
  size?: AvatarPropsType['size'];
  unreadsCount?: number;
}> = (props) => {
  const { availableWorkspaces } = useWorkspacesList();
  const currentCompanyId = useAppSelector(({ main }) => main.user?.company?.companyId);
  const isActive = currentCompanyId === props.workspaceItem.companyId;

  const choosingWorkspace = async () => {
    try {
      if (isActive) {
        return;
      }

      await helpers.changeWorkspace(props.workspaceItem.companyId, props.workspaceItem.subdomen);
    } catch (err) {
      if (checkIsAxiosError(err) && checkIsValidationError(err)) {
        toast.error(t('errors:server.validation.failed'));
      }
      console.error('src/ui/container/MainSidebar/components/WorkspaceItem.tsx', err);
    }
  };

  return (
    <WorkspaceItemStyle>
      <div
        className={classNames({
          'active-workspace': isActive,
          'cursor-pointer': !isActive,
        })}
      >
        <Avatar
          company={availableWorkspaces[props.workspaceItem.companyId]}
          onClick={choosingWorkspace}
          size={props.size || 'sm'}
        />
      </div>
      {!!props.unreadsCount && (
        <ChatBadge
          className={classNames('workspace-badge', { 'workspace-badge--auto-width': props.unreadsCount > 9 })}
          content={props.unreadsCount}
        />
      )}
    </WorkspaceItemStyle>
  );
};

export default WorkspaceItem;
