import React from 'react';

import Button from '@mui/material/Button';
import GenericDialog from 'src/ui/containers/GenericDialog';

import { t } from 'src/utils/getTranslation';
import { ElectronAPI } from 'src/utils/electronAPI';

const NotificationRequestPermissionModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}> = (props) => {
  if (ElectronAPI.isElectronApp) {
    return null;
  }
  return (
    <GenericDialog
      isOpen={props.isOpen}
      noCloseButton
      title={t('general:requestNotificationModal.title')}
      text={t('general:requestNotificationModal.text')}
      actionButtons={(
        <>
          <Button
            variant="outlined"
            onClick={props.onClose}
          >
            {t('general:requestNotificationModal.cancelText')}
          </Button>

          <Button
            variant="contained"
            onClick={props.onAccept}
          >
            {t('general:requestNotificationModal.sendText')}
          </Button>
        </>
      )}
    />
  );
};

export default NotificationRequestPermissionModal;
