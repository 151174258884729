import styled from 'styled-components';

export default styled.div`
  cursor: pointer;
  .user-avatar {
    margin-top: -12px;
  }
  
  .avatar-group {
    position: relative;
  }

  .avatar-group__badge {
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 2px;
  }
`;
