import type { IChatStore } from '../types';

export default () => ({
  channelsObject: {},
  channels: [],
  dmChannels: [],
  archivedChannels: [],
  channelsMeta: {},
  isChannelsLoading: true,
  getMyChannelsError: null,

  usersObject: {},

  openedThread: {
    channelId: null,
    parentMessageId: null,
    isLoading: false,
  },

  mediaItems: {},
  downloadingMediaItemIdsObj: {},

  channelDetailsObject: {},

  threads: {},
  threadsChannelMessages: [],
  newThreadsMessagesInQueue: [],
  threadsUnreadCount: 0,
  channelNotificationStatuses: {},

  selectedChannelId: null,
} as IChatStore);
