import type { IMessaging, MessagingEventNameType, MessagingPayloadType } from './messagingTypes';
import CustomEventCreator from '../CustomEventCreator';
import { isPWA } from 'src/config';
import { ElectronAPI } from '../electronAPI';

// eslint-disable-next-line prefer-const
let serviceWorkerRegistration: ServiceWorkerRegistration | null = null;
const serviceWorkerEvent = new CustomEventCreator<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
const EVENT_NAME_PREFIX = 'SERVICE_WORKER__';

if (!ElectronAPI.isElectronApp) {
  navigator.serviceWorker?.register('/sw.js').then((registration) => {
    serviceWorkerRegistration = registration;
    console.info('SW registration captured');
  });

  navigator.serviceWorker?.addEventListener('message', (event) => {
    const eventName: string = event.data?.eventName || '';
    const payload: object = event.data?.payload || {};

    if (!eventName.includes(EVENT_NAME_PREFIX)) {
      return;
    }

    serviceWorkerEvent.dispatch(payload, { eventName });
  });
}

class ServiceWorkerMessaging implements IMessaging {
  private isActive: boolean;

  constructor() {
    this.isActive = isPWA;
  }

  // eslint-disable-next-line class-methods-use-this
  private logWarning = (actionType: 'subscribe' | 'postMessage', eventName: string) => {
    console.warn(`Failed to ${actionType} to service worker event "${eventName}". No active SW registration`);
  };

  // eslint-disable-next-line class-methods-use-this
  private formatEventName = (eventName: string) => `${EVENT_NAME_PREFIX}${eventName}`;

  subscribe = <T_EventName extends MessagingEventNameType>(
    eventName: T_EventName,
    handler: (data: MessagingPayloadType[T_EventName]) => void,
  ) => {
    if (!this.isActive) {
      this.logWarning('subscribe', eventName);
      return () => null;
    }

    return serviceWorkerEvent.subscribe(handler, { eventName: this.formatEventName(eventName) });
  };

  postMessage = <T_EventName extends MessagingEventNameType>(
    eventName: T_EventName,
    data: MessagingPayloadType[T_EventName],
  ) => {
    if (!this.isActive) {
      this.logWarning('postMessage', eventName);
      return;
    }

    serviceWorkerRegistration?.active?.postMessage({
      eventName: this.formatEventName(eventName),
      payload: data,
    });
  };
}

export default ServiceWorkerMessaging;
