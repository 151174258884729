import React from 'react';

import Box from '@mui/material/Box';
import EmojiPicker from '@emoji-mart/react';
import emojiData from '@emoji-mart/data';
import StyledPopover from 'src/ui/pages/Chat/components/EmojiPicker/CustomPopover.styles';

import type { PopoverOrigin } from '@mui/material/Popover';
import type { IEmoji } from 'src/types';
import { useAppSelector } from 'src/store/store';

type EmojiPickerPropsType = {
  anchor?: HTMLElement | null;
  onEmojiSelect?: (emoji: IEmoji) => void;
  onClose?: () => void;
  popoverAnchorOrigin?: PopoverOrigin;
  popoverTransformOrigin?: PopoverOrigin;
};

const CustomEmojiPicker: React.FC<EmojiPickerPropsType> = (props) => {
  const theme = useAppSelector(({ main }) => main.theme);
  const locale = useAppSelector(({ main }) => main.language);

  const handleEmojiSelect = (emoji: IEmoji) => {
    handleClose();
    props.onEmojiSelect?.(emoji);
  };

  const handleClose = () => {
    props.onClose?.();
  };

  return (
    <StyledPopover
      open={Boolean(props.anchor)}
      anchorEl={props.anchor}
      anchorOrigin={{
        vertical: props.popoverAnchorOrigin?.vertical || 'top',
        horizontal: props.popoverAnchorOrigin?.horizontal || 'left',
      }}
      transformOrigin={{
        vertical: props.popoverTransformOrigin?.vertical || 'bottom',
        horizontal: props.popoverTransformOrigin?.horizontal || 'left',
      }}
      onClose={handleClose}
    >
      <Box minWidth="345px">
        <EmojiPicker
          data={emojiData}
          theme={theme}
          locale={locale}
          skinTonePosition="none"
          skin={1}
          onEmojiSelect={handleEmojiSelect}
          previewPosition="none"
        />
      </Box>
    </StyledPopover>
  );
};

export default CustomEmojiPicker;
