import styled from 'styled-components';

type StyledMessageTextPropsType = {
  ellipsis?: boolean;
};

const StyledMessageText = styled.div<StyledMessageTextPropsType>`
  height: 100%;
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'inherit')};
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'auto')};

  mark {
    background-color: ${({ theme }) => theme.palette.primary[200]};
  }
  blockquote, pre, code {
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 5px 10px;
    border-radius: 3px;
  }

  blockquote {
    padding: 0px 12px 2px 12px;
    margin: 10px 0px;
    border-radius: 0px;
    border-left: 2px solid ${({ theme }) => theme.palette.primary[500]};
    color: ${({ theme }) => theme.palette.text.secondary};
    background: ${({ theme }) => theme.palette.background.default};
    &:not(:first-of-type):not(:last-of-type) {
      margin: 0px;
    }

    &:first-of-type {
      margin-top: 10px;
      margin-bottom: 0px;
      border-radius: 2px 0px;
    }
    &:last-of-type {
      border-radius: 0px 2px;
      margin-bottom: 10px;
    }
  }

  a {
    color: ${({ theme }) => theme.palette.info.dark};
  }

  p {
    word-wrap: break-word;
    margin: 0;
  }

  code {
    color: ${({ theme }) => theme.palette.primary[500]};
    background-color: ${({ theme }) => theme.palette.white.A1};
    border-radius: 4px;
    font-family: 'IBM Plex Mono', monospace;
    font-size: ${({ theme }) => theme.font.size.sm};
    line-height: 20.8px;
    padding: 0px 4px 2px 4px;
    border: 1px solid ${({ theme }) => theme.palette.grey[600]};
  }

  code > mark,  pre > mark {
    color: ${({ theme }) => theme.palette.info.dark};
    background-color: ${({ theme }) => theme.palette.white.A3};
  }

  pre {
    padding: 6px, 6px, 8px, 6px;
    margin: 10px 0;
    background-color: ${({ theme }) => theme.palette.grey[50]};
    color: ${({ theme }) => theme.palette.white.A3};
    overflow: visible;
    background-color: ${({ theme }) => theme.palette.white.A1};
    color: ${({ theme }) => theme.palette.text.secondary};
    border: 1px solid ${({ theme }) => theme.palette.grey[600]};
    border-radius: 4px;
    font-family: 'IBM Plex Mono', monospace;
    line-height: 20.8px;
  }

  ol li {
    margin-left: 1.5em;
    list-style-type: decimal;
  }

  ul li {
    margin-left: 1.5em;
    list-style-type: disc;
  }

  .mention {
    color: ${({ theme }) => theme.palette.primary[500]};
    background: inherit;
    cursor: pointer;
  }
`;

export default StyledMessageText;
