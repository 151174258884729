import React from 'react';

import type { DefaultTheme } from 'styled-components';
import { ThemeProvider } from 'styled-components';

import useTheme from 'src/utils/hooks/useTheme';

// Disable useless errors
const cashedLog = console.error;
console.error = (...args) => {
  const message = args[0];
  if (/Warning: React does not recognize the `.+` prop on a DOM element\./.test(message)) {
    return;
  }

  if (/^Warning: Received `.+` for a non-\w+ attribute `.+`/.test(message)) {
    return;
  }

  cashedLog(...args);
};

const StyledComponentsThemeProvider: React.FC<React.PropsWithChildren> = (props) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme as unknown as DefaultTheme}>
      {props.children}
    </ThemeProvider>
  );
};

export default StyledComponentsThemeProvider;
