import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { workspacesMetaThunks } from 'src/store/workspacesMeta/workspacesMeta';

export const useListenUnreadMessages = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((store) => store.main.user?.company?.companyId);

  React.useEffect(() => {
    if (!companyId) {
      return;
    }
    dispatch(workspacesMetaThunks.getUnreadMessagesCount());
  }, [companyId]);
};
