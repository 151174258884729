import { LoadingSatusENUM, type ChannelDetailsItemType } from '../types';

export default class ChannelDefaultData implements ChannelDetailsItemType {
  messages = [];
  channelMessagesObject = {};
  usersToChannel = [];
  firstUnreadMessageId = null;
  pendingMessages = {
    errored: [],
    sending: [],
  };

  pinnedMessagesLoadingStatus = LoadingSatusENUM.idle;
  pinnedMessagesIds = [];

  pinnedMessages = [];
  hasMoreDown = null;
  hasMoreUp = false;
  isLoadingDown = false;
  isLoadingUp = false;
  lastReadMessageTimestamp = null;

  // eslint-disable-next-line no-useless-constructor
  constructor(public channelId: number, public isPartiallyLoaded?: boolean) {}
}
