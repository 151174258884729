import socket from './socket';
import { SocketEventsENUM } from './socketEvents';
import type { IMediaItem } from 'src/types/userTypes';

type CompanyImageUpdatedResponseType = {
  companyId: number;
  mediaItem: IMediaItem;
};

const subscribeOnCompanyImageUpdated = (callback: (payload: CompanyImageUpdatedResponseType) => void) => {
  return socket.addOldEventHandler<{ data: CompanyImageUpdatedResponseType }>(SocketEventsENUM.company_imageUpdated, (response) => {
    callback(response.data);
  });
};

export default {
  subscribeOnCompanyImageUpdated,
};
