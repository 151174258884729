import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import useTheme from 'src/utils/hooks/useTheme';

type BreakpointNameType = keyof Theme['breakpoints']['values'];

const useMediaQueryCheck = (breakpointKey: BreakpointNameType) => {
  const theme = useTheme();
  const breakpointValue = theme.breakpoints?.values?.[breakpointKey];

  const isMatch = useMediaQuery(`(max-width: ${breakpointValue}px)`);

  return isMatch;
};

type ExtendedWindowType = typeof window & {
  opera: string;
  MSStream: string;
};
const getDeviceOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as ExtendedWindowType).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as ExtendedWindowType).MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const isPhoneOrTabletOS = getDeviceOS() !== 'unknown';

export const useMobileCheck = () => {
  const isMobile = useMediaQueryCheck('sm');

  return isMobile || isPhoneOrTabletOS;
};
export const useTabletCheck = () => {
  const isTablet = useMediaQueryCheck('md');

  return isTablet || isPhoneOrTabletOS;
};
export const useLaptopCheck = () => useMediaQueryCheck('lg');

export default useMediaQueryCheck;
