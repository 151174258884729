import React from 'react';
import axios, { type AxiosError } from 'axios';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import styled, { createGlobalStyle } from 'styled-components';

import LottieAnimation from '../components/LottieAnimation/LottieAnimation';

import config, { isDev, envType } from 'src/config';
import store from 'src/store';

const getAxiosContext = (error: Error | AxiosError) => {
  const axiosContext: Record<string, unknown> = {};

  if (axios.isAxiosError(error)) {
    axiosContext.axiosErrorResponse = error.response;
    axiosContext.axiosRequest = error.config;
  }

  return axiosContext;
};

if (!isDev) {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: envType,
    tracesSampleRate: isDev ? 1.0 : 0.2,
    beforeSend: (event, hint) => {
      const formattedEvent = event;
      if (!formattedEvent.contexts) {
        formattedEvent.contexts = { context: {} };
      }

      formattedEvent.contexts.context = {
        ...formattedEvent.contexts.context,
        ...getAxiosContext(hint.originalException as Error),
      };

      return formattedEvent;
    },
  });
}

const getStoreData = () => {
  let companyId: number | null = null;
  let companySubdomain: string | null = null;
  let userId: number | null = null;

  try {
    const { main } = store.getState();
    userId = main.user?.userId || null;
    companyId = main.user?.company?.companyId || null;
    companySubdomain = main.user?.company?.subdomen || null;
  } catch (err) {
    console.error('Failed to get store data', err);
  }
  return { companyId, companySubdomain, userId };
};

setTimeout(() => {
  store.subscribe(() => {
    Sentry.setTags(getStoreData());
  });
}, 1000);

export const captureException = (data: { error: Error; context?: Record<string, unknown>; tags?: Record<string, unknown> }) => {
  const storeData = getStoreData();

  Sentry.captureException(data.error, {
    tags: {
      ...storeData,
      ...data.tags,
      capturedAt: dayjs().format('DD.MM.YYYY HH:mm'),
      href: window.location.href,
    },
    contexts: {
      context: stringifyObjectFields({
        ...data.context,
        ...getAxiosContext(data.error),
      }),
    },
  });
};

window.addEventListener('unhandledrejection', (event) => {
  captureException({
    error: event.reason,
    tags: {
      type: 'unhandled rejection',
    },
    context: {
      promise: event.promise,
    },
  });
  console.error('Unhandled rejection:', event.reason);
});

const stringifyObjectFields = (obj: Record<string, unknown>): Record<string, string> => {
  return Object.entries((obj || {})).reduce((acc, [key, value]) => {
    if (typeof value === 'string') {
      acc[key] = value;
    } else {
      acc[key] = JSON.stringify(value);
    }
    return acc;
  }, {} as Record<string, string>);
};

class ErrorBoundary extends React.Component<
  React.PropsWithChildren & {
    errorBoundaryId: string;
    fallback?: React.ReactNode;
    sendErrorInfo?: boolean;
    additionalContext?: Record<string, unknown>;
  },
  { error: Error | null }
> {
  state = { error: null };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });

    captureException({
      error,
      context: {
        ...this.props.additionalContext,
        reactErrorInfo: errorInfo,
      },
    });
  }

  render() {
    if (this.state.error) {
      return this.props.fallback || (
        <StyledErrorBoundary>
          <GlobalStyles />

          <h1 className="error-title">
            Что-то пошло не так. Пожалуйста, перезагрузите страницу.
          </h1>

          <h2 className="error-subtitle">
            Мы уже работаем над решением проблемы.
          </h2>

          <LottieAnimation animation="unexpectedError" className="error-animation" />
        </StyledErrorBoundary>
      );
    }

    return this.props.children;
  }
}

const StyledErrorBoundary = styled.main`
  padding: 50px 30px 0;
  box-sizing: border-box;

  .error-title {
    text-align: center;
    margin-bottom: 30px;
  }

  .error-subtitle {
    text-align: center;
  }

  .error-animation {
    max-width: 800px;
    margin-inline: auto;
    
    svg {
      margin-block: -12%;
    }
  }
`;

const GlobalStyles = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
  }

  html, body, #root {
    min-height: 100%;
    height: 1px;
  }

  html {
    font-family: 'Mulish', 'Lato', sans-serif;
    color: #FFFFFF;
    background-color: #FF7A50;
  }
`;

export default ErrorBoundary;
