import styled, { css } from 'styled-components';

type PropsType = {
  buttonWidth?: number;
};

const StyledActionButtons = styled.div<PropsType>`
  display: flex;
  justify-content: right;
  gap: 16px;

  .customized-button {
    ${(props) => props.buttonWidth && css`
      width: ${props.buttonWidth}px;
    `}
  }

  .error-button {
    background: ${({ theme }) => theme.palette.error.dark};
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    flex-direction: column;
  }
`;

export default StyledActionButtons;
