import React from 'react';
import _omit from 'lodash/omit';

import type { ButtonProps } from '@mui/material/Button';
import LottieAnimation from 'src/ui/components/LottieAnimation';
import StyledButton from './Button.styles';

const Button: React.FC<{
  isLoading?: boolean;
} & ButtonProps> = (props) => {
  return (
    <StyledButton
      {..._omit(props, 'isLoading')}
    >
      {props.isLoading
        ? <LottieAnimation animation="circleLoader" className="button__loader-icon" />
        : props.children
      }
    </StyledButton>
  );
};

export default Button;
