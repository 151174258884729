import React from 'react';

import useDebounce from './useDebounce';

const useDebouncedState = <V>(
  initialValue: V | (() => V),
  onDebounce: (value: V) => void,
  debounceTime = 300,
) => {
  const [value, setValue] = React.useState(initialValue);
  const [debouncedValue, setDebouncedValue] = React.useState(initialValue);

  const onDebounceRef = React.useRef(onDebounce);
  React.useEffect(() => {
    onDebounceRef.current = onDebounce;
  }, [onDebounce]);

  const debounceSetValue = useDebounce((newValue: V) => {
    setDebouncedValue(newValue);
    onDebounceRef.current(newValue);
  }, debounceTime);

  const set = (newValue: V) => {
    setValue(newValue);
    debounceSetValue(newValue);
  };

  return { debouncedValue, value, set };
};

export default useDebouncedState;
