/* eslint-disable object-property-newline */
import _escapeRegExp from 'lodash/escapeRegExp';

const ruEngTransl: Record<string, string> = {
  а: 'a', б: 'b', в: 'v', г: 'g', д: 'd',
  е: 'e', ё: 'e', ж: 'zh', з: 'z', и: 'i',
  й: 'j', к: 'k', л: 'l', м: 'm', н: 'n',
  о: 'o', п: 'p', р: 'r', с: 's', т: 't',
  у: 'u', ф: 'f', х: 'h', ц: 'c', ч: 'ch',
  ш: 'sh', щ: 'shch', ъ: '', ы: 'y', ь: '',
  э: 'e', ю: 'yu', я: 'ya',
};

const engRuTransl: Record<string, string> = {
  a: 'а', b: 'б', v: 'в', g: 'г', d: 'д',
  e: 'е', zh: 'ж', z: 'з', i: 'и',
  j: 'й', k: 'к', l: 'л', m: 'м', n: 'н',
  o: 'о', p: 'п', r: 'р', s: 'с', t: 'т',
  u: 'у', f: 'ф', h: 'х', c: 'ц', ch: 'ч',
  sh: 'ш', shch: 'щ', y: 'ы', yu: 'ю', ya: 'я',
};

const ruWord = Object.keys(ruEngTransl);

export const transliterate = (source: string) => {
  const splitSource = source.toLowerCase().split('');

  let ruResult = '';
  let engResult = '';
  for (let i = 0; i < splitSource.length; i++) {
    const currentChar = splitSource[i];
    const currentEngChar = ruEngTransl[splitSource[i]];
    const currentRuChar = engRuTransl[splitSource[i]];

    engResult += currentEngChar || currentChar;
    ruResult += currentRuChar || currentChar;
  }

  return { source, ruResult, engResult };
};

const highlighted = (
  text: string,
  highlight = '',
) => {
  if (!highlight.trim() || !text) {
    return text;
  }

  let searchStr = highlight;
  const highlightArr = highlight.split('');
  const textArr = text.split('');

  const hasSameLocale = highlightArr.find((searchItem) => textArr.includes(searchItem));

  if (!hasSameLocale) {
    const isRu = ruWord.findIndex(
      (item) => item.includes(textArr[0].toLowerCase()) || item.includes(textArr[0].toUpperCase()),
    );
    const { ruResult, engResult } = transliterate(highlight);
    if (isRu !== -1) {
      searchStr = ruResult;
    } else {
      searchStr = engResult;
    }
  }

  const regexHighlight = new RegExp(`(${_escapeRegExp(searchStr)})`, 'gi');
  const tagsAndStringRegex = /(<[^>]+>)/gi;

  const tagsAndText = text.split(tagsAndStringRegex);
  const tagsAndHighlightedText: string[] = [];

  for (const str of tagsAndText) {
    if (str[0] === '<') {
      tagsAndHighlightedText.push(str);
    } else {
      const parts = str.split(regexHighlight);
      tagsAndHighlightedText.push(...parts);
    }
  }

  const strings: string[] = [];

  tagsAndHighlightedText.filter((part: string) => part).forEach((part) => (
    part.match(regexHighlight)
      ? strings.push(`<mark>${part}</mark>`)
      : strings.push(`${part}`)
  ));

  return strings.join('');
};

export default highlighted;
