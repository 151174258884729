import React from 'react';

export type VariantType = 'right' | 'left' | 'both';

export const useTogglersVisibility = (params: { variant?: VariantType; disabled?: boolean }) => {
  const togglersVisibility = React.useMemo(() => {
    if (params.disabled) {
      return {
        isLeftToglerVisible: false,
        isRightToglerVisible: false,
      };
    }

    if (params.variant === 'both') {
      return {
        isLeftToglerVisible: true,
        isRightToglerVisible: true,
      };
    }

    return {
      isLeftToglerVisible: params.variant === 'left',
      isRightToglerVisible: params.variant !== 'left',
    };
  }, [params.variant, params.disabled]);

  return togglersVisibility;
};

export type SizeDataPropsType = {
  /** Default value - `false` */
  disabled?: boolean;
  size: number;
  minSize?: number;
  maxSize?: number;
  minTrashold?: number;
  maxTrashold?: number;
  limitByParentScroll?: boolean;
  onMinTrashold?: (data: { minTrashold: number; width: number }) => void;
  onMaxTrashold?: (data: { maxTrashold: number; width: number }) => void;
  onSizeChange?: (size: number) => void;
};
export const useSizeData = (props: SizeDataPropsType) => {
  const [size, setSize] = React.useState(props.size);
  const [isDragging, setIsDragging] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const allowSizeGrow = React.useRef(true);
  const propsRef = React.useRef(props);
  propsRef.current = props;

  React.useEffect(() => {
    setSize(props.size);
  }, [props.size]);

  React.useEffect(() => {
    if (!props.limitByParentScroll) {
      return;
    }

    const container = containerRef.current;
    const parent = containerRef.current?.parentElement;

    if (!container || !parent) {
      return console.error('Draggble container parent not found!');
    }

    const resizeHandler = () => {
      (async () => {
        const { clientWidth, scrollWidth } = parent;

        if (scrollWidth === clientWidth) {
          return;
        }

        // Add await here to move all heavy logic to microtask to avoid "ResizeObserver loop completed with undelivered notifications." error
        // eslint-disable-next-line @typescript-eslint/await-thenable
        allowSizeGrow.current = await false;
        const diff = scrollWidth - clientWidth;

        setSize((prev) => {
          const newSize = prev - diff;

          if (propsRef.current.minSize && newSize < propsRef.current.minSize) {
            propsRef.current.onMinTrashold?.({
              minTrashold: propsRef.current.minSize,
              width: newSize,
            });
            return prev;
          }

          if (newSize <= 0) {
            return 1;
          }

          return newSize;
        });
      })();
    };

    const parentObserver = new ResizeObserver(resizeHandler);
    parentObserver.observe(parent);

    const containerObserver = new ResizeObserver(resizeHandler);
    containerObserver.observe(container);

    return () => {
      parentObserver.disconnect();
      containerObserver.disconnect();
    };
  }, [props.limitByParentScroll]);

  const handleDownEvent = (ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>, side: 'left' | 'right') => {
    allowSizeGrow.current = true;

    const initialSize = size;
    const triggerX = ev.currentTarget.getBoundingClientRect().x + (ev.currentTarget.clientWidth / 2); // eslint-disable-line max-len
    const initialPosition = triggerX;
    setIsDragging(true);

    let currentSize = initialSize;
    let temporarySize = initialSize;

    const updateSize = (clientX: number) => {
      const diff = (clientX - initialPosition) * (side === 'right' ? 1 : -1);

      const newSize = initialSize + diff;

      temporarySize = newSize;
      if (newSize < 0) {
        return;
      }
      if (props.maxSize && newSize > props.maxSize) {
        return;
      }
      if (props.minSize && newSize < props.minSize) {
        return;
      }
      currentSize = newSize;

      setSize((prev) => {
        if (!allowSizeGrow.current && prev < newSize) {
          return prev;
        }
        allowSizeGrow.current = true;
        return newSize;
      });
    };

    const handleMouseMove = (ev: MouseEvent) => {
      updateSize(ev.clientX);
    };
    const handleTouchMove = (ev: TouchEvent) => {
      updateSize(ev.targetTouches[0].clientX);
    };

    const handleUpEvent = () => {
      props.onSizeChange?.(currentSize);
      if (props.minTrashold && temporarySize < props.minTrashold) {
        props.onMinTrashold?.({ minTrashold: props.minTrashold, width: currentSize });
      }
      if (props.maxTrashold && temporarySize > props.maxTrashold) {
        props.onMaxTrashold?.({ maxTrashold: props.maxTrashold, width: currentSize });
      }

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('mouseup', handleUpEvent);
      document.removeEventListener('touchend', handleUpEvent);

      allowSizeGrow.current = true;
      setIsDragging(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('mouseup', handleUpEvent);
    document.addEventListener('touchend', handleUpEvent);
  };

  return {
    size,
    handleDownEvent,
    isDragging,
    containerRef,
  };
};
