import type {
  IRequest,
  IStatusRequest,
  IGetUserRequestsListParams,
  IGetAllRequestsListParams,
} from 'src/types';

import http from './http';

const path = '/requests';

const create = (data: IRequest) => {
  return http.post(path, data);
};

const edit = (data: IRequest, requestId: number) => {
  return http.patch(`${path}/${requestId}`, data);
};

const changeStatus = (data: IStatusRequest, requestId: number) => {
  return http.patch(`${path}/${requestId}/status`, data);
};

const getList = (params: IGetAllRequestsListParams) => {
  return http.get(path, { params });
};

const getUserList = (params: IGetUserRequestsListParams) => {
  return http.get(`${path}/my`, { params });
};

const getHistoryList = (requestId: number) => {
  return http.get(`${path}/${requestId}`);
};

const deleteOne = (requestId: number) => {
  return http.delete(`${path}/${requestId}`);
};

export default {
  create,
  edit,
  changeStatus,
  getList,
  getUserList,
  getHistoryList,
  deleteOne,
};
