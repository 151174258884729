import React from 'react';
import styled from 'styled-components';
import chatRouter from 'src/ui/pages/Chat/utils/router.utils';
import Search from 'src/ui/pages/Chat/componentsV2/Search/Search';
import { getIsApple } from 'src/ui/pages/Chat/utils/getIsAppleDevice';
import TitleBarNavigation from './TitleBarNavigation';
import { useAppSelector } from 'src/store/store';
import { useLocation } from 'react-router-dom';

type PropsType = {
  className?: string;
};

const TitleBar: React.FC<PropsType> = (props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const user = useAppSelector(({ main }) => main.user);
  const location = useLocation();
  const contentCanBeShown = user && chatRouter.isChatPage(location.pathname);

  React.useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      const isControllOrMetaKey = getIsApple() ? ev.metaKey : ev.ctrlKey;
      if (isControllOrMetaKey && ev.key === 'k') {
        ev.preventDefault();
        inputRef.current?.focus();
      }

      if (ev.key === 'Escape') {
        inputRef.current?.blur();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <StyledWrapper className={props.className}>
      {contentCanBeShown && (
        <div className="title-bar__non-dragable-content">
          <TitleBarNavigation className="title-bar__navigation" />
          <Search
            className="title-bar__search-area"
            inputClassName="title-bar__search-input"
            noneAutoFocus
            inputRef={inputRef}
            searchResults={{
              className: 'title-bar__search-results',
              resultsWrapperClassName: 'title-bar__search-results-wrapper',
            }}
          />
        </div>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 40px;
  background-color: ${(p) => p.theme.palette.primary[100]};
  border-bottom: 1px solid ${(p) => p.theme.palette.white.A1};
  padding-left: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-app-region: drag;    



  .title-bar__non-dragable-content {
    -webkit-app-region: no-drag;
    margin-left: 45px;
    display: flex;
    align-items: center;
    flex-basis: 50%;
    gap: 15px;
  }

  .title-bar__navigation {
    flex-basis: 70px;
  }

  .title-bar__search-area {
      padding: 0px;
      border: none;
      flex-grow: 1;
  }

  .title-bar__search-input {
    padding: 6px 16px;
  }

  .title-bar__search-results {
    top: 40px;
    left: 0px;
    width: 99%;
  }

  .title-bar__search-results-wrapper {
    width: 100%;
    min-width: 310px;
  }

`;

export default TitleBar;
