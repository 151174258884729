import React from 'react';
import cn from 'classnames';
import type { IUser } from 'src/types/userTypes';
import Avatar from 'src/ui/components/Avatar/Avatar';
import AvatarOnlineBadge from 'src/ui/components/AvatarOnlineBadge';

type PropsType = {
  directUser: IUser;
  iconClassName?: string;
};

const DirectItemIcon: React.FC<PropsType> = (props) => {
  return (
    <AvatarOnlineBadge userId={props.directUser.userId}>
      <Avatar
        size="xsx"
        user={props.directUser}
        className={cn('user-avatar', props.iconClassName)}
      />
    </AvatarOnlineBadge>
  );
};

export default DirectItemIcon;
