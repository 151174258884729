import React from 'react';

import { isPWA } from 'src/config';
import ServiceWorkerMessaging from './ServiceWorkerMessaging';
import type { IMessaging } from './messagingTypes';
import { ElectronAPI } from '../electronAPI';

let messagingInterface: IMessaging | null = null;

if (isPWA && !ElectronAPI.isElectronApp) {
  messagingInterface = new ServiceWorkerMessaging();
}
// Just add some more interface implementations to add Electron and webview support

export const useMessagingInterface = () => {
  React.useEffect(() => {
    if (!messagingInterface) {
      return;
    }

    const unsubscribers: Array<() => void> = [
      messagingInterface.subscribe('SET_APP_BADGE', ({ badgeValue }) => {
        window.navigator.setAppBadge?.(badgeValue);
      }),
    ];

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, []);
};

export const setAppBadge = (badgeValue: number) => {
  messagingInterface?.postMessage('SET_APP_BADGE', { badgeValue });
};
