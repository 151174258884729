import React from 'react';

import type { IUser, UserPermissionsENUM } from 'src/types';
import { useAppSelector } from 'src/store';

type WithAuthPropsType = {
  isAuthRequired?: true;
  permissions?: UserPermissionsENUM[];
  hide?: boolean;
  alwaysAvailable?: boolean;
};

type NoAuthPropsType = {
  isAuthRequired: false;
};

export type AuthPropsType = WithAuthPropsType | NoAuthPropsType;
export type RouteDataType = {
  path: string;
  auth?: AuthPropsType;
};

type PropsType = AuthPropsType & {
  fallbackNode?: React.ReactNode;
  getFallbackNode?: (id?: number) => React.ReactNode;
};

export const validateUserPermissions = (user: IUser, permissions: UserPermissionsENUM[]) => {
  const userPermissions = {} as Record<string, true>;
  user.permissions?.forEach(({ value }) => {
    userPermissions[value] = true;
  });

  for (let i = 0; i < permissions.length; i++) {
    const requiredPermission = permissions[i];
    if (!userPermissions[requiredPermission]) {
      return false;
    }
  }

  return true;
};

export const validateUserAuth = (user: IUser | null, auth: AuthPropsType) => {
  // The order of IF conditions is important here
  if (!user) {
    return !auth.isAuthRequired;
  }

  if ((auth as WithAuthPropsType).hide) {
    return false;
  }

  if ((auth as WithAuthPropsType).alwaysAvailable) {
    return true;
  }

  if (user && !auth.isAuthRequired) {
    return false;
  }

  if (user.isSuperAdmin) {
    return true;
  }

  if ((auth as WithAuthPropsType).permissions?.length) {
    return validateUserPermissions(
      user,
      (auth as WithAuthPropsType).permissions || [],
    );
  }

  return true;
};

export const useIsAvailable = (props: AuthPropsType = {}) => {
  const user = useAppSelector(({ main }) => main.user);
  const requiredPermissionsRef = React.useRef([] as UserPermissionsENUM[]);
  const isAuthRequiredRef = React.useRef(false);
  const hideRef = React.useRef(false);
  const alwaysAvailableRef = React.useRef(false);

  if (props.isAuthRequired !== false) {
    isAuthRequiredRef.current = true;
    requiredPermissionsRef.current = props.permissions || [];
    hideRef.current = props.hide ?? false;
    alwaysAvailableRef.current = props.alwaysAvailable ?? false;
  }

  const isAvailable = React.useMemo<boolean>(() => {
    return validateUserAuth(user, {
      isAuthRequired: isAuthRequiredRef.current,
      permissions: requiredPermissionsRef.current,
      hide: hideRef.current,
      alwaysAvailable: alwaysAvailableRef.current,
    });
  }, [user]);

  return isAvailable;
};

const Protector: React.FC<React.PropsWithChildren & PropsType> = (props) => {
  const isAvailable = useIsAvailable(props);
  const user = useAppSelector(({ main }) => main.user);

  if (!isAvailable) {
    return props.fallbackNode as React.ReactElement ||
      props.getFallbackNode?.(user?.company?.mainChannelId) ||
      null;
  }

  return props.children as React.ReactElement;
};

export default Protector;
