import React from 'react';
import companyWs from 'src/api/ws/companyWs';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';
import { useAppDispatch, useAppSelector } from 'src/store/store';

const useCompanyUpdating = () => {
  const dispatch = useAppDispatch();

  const isSocketConnected = useAppSelector((state) => state.main.socketData.isConnected);

  React.useEffect(() => {
    return companyWs.subscribeOnCompanyImageUpdated((response) => {
      dispatch(mainSliceActions.updateCompanyImage({
        companyId: response.companyId,
        image: response.mediaItem,
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocketConnected]);
};

export default useCompanyUpdating;
