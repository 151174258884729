import i18n from 'i18next';
import type { TFunction } from 'react-i18next';
import {
  type ResourcesType,
  type AVAILABLE_LANGUAGES,
} from 'src/i18n';

export const t = i18n.t as TFunction<(keyof ResourcesType)[], undefined>;
export const language = (i18n.language || 'ru') as keyof typeof AVAILABLE_LANGUAGES;

const getTranslation = () => {
  return {
    t,
    language: i18n.language,
  };
};

export default getTranslation;
