import http from 'src/api/http';

import type { ResponseType } from 'src/api/http';
import type { IUser } from 'src/types';

const path = 'messages';

const getUsersForMentions = async (channelId: number, data: { keyword: string }) => {
  return http.post<ResponseType<IUser[]>>(`${path}/find-users-for-mention/${channelId}`, data);
};

export default {
  getUsersForMentions,
};
