import React from 'react';
import { useSearchParams as routerUseSearchParams } from 'react-router-dom';

const useSearchParams = <D extends object>() => {
  const [URLSearchParams, SetURLSearchParams] = routerUseSearchParams();

  const URLSearchParamsRef = React.useRef(URLSearchParams);
  React.useEffect(() => {
    URLSearchParamsRef.current = URLSearchParams;
  }, [URLSearchParams]);

  const SetURLSearchParamsRef = React.useRef(SetURLSearchParams);
  React.useEffect(() => {
    SetURLSearchParamsRef.current = SetURLSearchParams;
  }, [SetURLSearchParams]);

  const params = React.useMemo(() => {
    const data = {} as D;
    const keys = Array.from(URLSearchParams.keys()) as (keyof D)[];
    keys.forEach(<K extends keyof D>(key: K) => {
      const value = URLSearchParams.get(key as string);
      if (!value) {
        return;
      }
      data[key] = value as D[K];
    });

    return data;
  }, [URLSearchParams]);

  const setParam = React.useCallback(<K extends keyof D>(
    key: K,
    value: D[K] | null,
    params?: { replace?: boolean },
  ) => {
    if (!value) {
      URLSearchParamsRef.current.delete(key as string);
    } else {
      URLSearchParamsRef.current.set(key as string, value as string);
    }

    SetURLSearchParamsRef.current(
      URLSearchParamsRef.current,
      { replace: params?.replace ?? false },
    );
  }, []);

  return [params, setParam] as const;
};

export default useSearchParams;
