import styled from 'styled-components';

export default styled.div`
position: relative;

.workspace-container & .active-workspace {
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.palette.tabs.selected};
}

.workspace-badge {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
  line-height: 16px;
  box-shadow: 0px 0px 3px rgba(255, 117, 115, 0.7);
  font-size: 8px;
  line-height: 12px;
  font-weight: 500;

  &--auto-width {
    width: max-content;
    padding: 0px 2px;
    height: max-content;
    border-radius: 10px;
  }
}

`;
