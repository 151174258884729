import React from 'react';

import Tooltip, { type TooltipProps } from '@mui/material/Tooltip';
import StyledTextWithOptionalTooltip from './TextWithOptionalTooltip.styles';

type TextWithOptionalTooltipPropsType = SpanTagPropsType & {
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
};

const TextWithOptionalTooltip = React.forwardRef<HTMLSpanElement, TextWithOptionalTooltipPropsType>((
  props,
  outerRef,
) => {
  const [isInEllipsis, setIsInEllipsis] = React.useState(false);

  const innerRef = React.useRef<HTMLSpanElement | null>(null);
  if (outerRef) {
    innerRef.current = (outerRef as typeof innerRef).current;
  }

  React.useEffect(() => {
    if (!innerRef.current) {
      return;
    }

    const element = innerRef.current;
    const nameObserver = new ResizeObserver(() => {
      setIsInEllipsis(element.scrollWidth > element.clientWidth);
    });

    nameObserver.observe(element);

    return () => {
      nameObserver.disconnect();
    };
  }, []);

  return (
    <Tooltip {...props.tooltipProps} title={isInEllipsis ? props.children : ''}>
      <StyledTextWithOptionalTooltip
        {...props}
        ref={(ref) => {
          if (outerRef) {
            (outerRef as typeof innerRef).current = ref; // eslint-disable-line no-param-reassign
          }
          innerRef.current = ref;
        }}
      >
        {props.children}
      </StyledTextWithOptionalTooltip>
    </Tooltip>
  );
});

export default TextWithOptionalTooltip;
