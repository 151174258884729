import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import UserMenuDownloadStyle from './UserMenuDownload.styles';

import androidIcon from '../assets/android.svg';
import iosIcon from '../assets/ios.svg';
import { t } from 'src/utils/getTranslation';

const UserMenuDownload: React.FC<{
  setAnchor: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
}> = () => {
  return (
    <UserMenuDownloadStyle>
      <p className="item-title">
        {t('general:userMenu.download')}
      </p>
      <div className="apps-icons">
        <Tooltip title={t('general:userMenu.comingSoon')} arrow>
          <div
            // onClick={() => props.setAnchor(null)}
            className="cursor-pointer"
          >
            <img src={androidIcon} alt={t('general:userMenu.android')} />
          </div>
        </Tooltip>

        <Tooltip title={t('general:userMenu.comingSoon')} arrow>
          <div
            // onClick={() => props.setAnchor(null)}
            className="cursor-pointer"
          >
            <img src={iosIcon} alt={t('general:userMenu.ios')} />
          </div>
        </Tooltip>
      </div>
    </UserMenuDownloadStyle >
  );
};

export default UserMenuDownload;
