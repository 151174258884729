import { createSelector } from '@reduxjs/toolkit';
import type { AppStateType } from '../store';

export const unreadMessagesCountSelector = createSelector(
  ({ chatPageV2 }: AppStateType) => chatPageV2.channelsMeta,
  ({ workspacesMeta }: AppStateType) => workspacesMeta.unreadMessagesByCompany,
  ({ main }: AppStateType) => main.user?.company?.companyId,
  (channelsMeta, unreadMessagesByCompany, companyId): Record<number, number> => {
    const activeCompanyUnreadsCount = Object.values(channelsMeta).reduce((acc, { unreadMessagesCount }) => {
      return acc + unreadMessagesCount;
    }, 0);

    const unreadByOtherCompanies = Object.entries(unreadMessagesByCompany).reduce((acc, curr) => {
      const key = Number(curr[0]);
      if (key === companyId) {
        return acc;
      }
      const {
        primaryMessages = [],
        threadMessages = {},
      } = curr[1];

      const count = (primaryMessages?.length ?? 0) + (Object.keys(threadMessages)?.length ?? 0);

      return {
        ...acc,
        [key]: count,
      };
    }, {} as Record<number, number>);

    return {
      [companyId!]: activeCompanyUnreadsCount,
      ...unreadByOtherCompanies,
    };
  },
);
